import { GET_ALL_LEAD } from "./leadTypes";

const INITIAL_STATE = {
  leadList: [],
};

const leadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LEAD:
      return {
        leadList: action.payload,
      };

    default:
      return state;
  }
};

export default leadReducer;
