import Container from '@mui/material/Container';
import ContactsHome from '../../components/contacts/ContactsHome';
import { Box } from '@mui/system';

const ContactsPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 12, p: 4 }}>
        <ContactsHome />
      </Box>
    </Container>
  );
};

export default ContactsPage;
