import { GET_ALL_CASEDOCUMENT, ADD_CASEDOCUMENT } from './caseDocumentTypes';

const INITIAL_STATE = {
  caseDocumentList: [],
  addCaseDocument: {},
};

const caseDocumentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CASEDOCUMENT:
      return {
        ...state,
        addCaseDocument: action.payload,
      };
    case GET_ALL_CASEDOCUMENT:
      return {
        ...state,
        caseDocumentList: action.payload,
      };

    default:
      return state;
  }
};

export default caseDocumentReducer;
