import {
  GET_ALL_CONTACTS,
  REMOVE_CONTACT,
  GET_ALL_CONTACTLIST,
} from './contactsTypes';

const INITIAL_STATE = {
  contactList: [],
  contactListData: [],
};

const contactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CONTACTS:
      return {
        ...state,
        contactList: [...state.contactList, action.payload],
      };
    case GET_ALL_CONTACTLIST:
      return {
        ...state,
        contactListData: action.payload,
      };
    case REMOVE_CONTACT:
      return {
        contactList: {},
      };
    default:
      return state;
  }
};

export default contactReducer;
