import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  darken,
  lighten,
  styled,
} from '@mui/material';

import AddNewContact from './AddNewContact';
import CompanyAndClientsTable from './CompanyAndClientsTable';
import Loader from '../loader/Loader';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

const ClientsAndContacts = ({ formik }) => {
  const [addNewContact, setAddNewContact] = useState(false);
  const [list, setList] = useState([]);
  const user = useSelector((state) => state.auth.user.user);
  const contactData = useSelector((state) => state?.contact?.contactList);

  const dispatch = useDispatch();

  const getClientOrCompnay = async (firmID) => {
    try {
      const response = await api.get(`/cases/clients/companies/${firmID}`);
      setList([
        ...response.data.data.sortedClient,
        ...response.data.data.sortedCompany,
      ]);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const dataPullhandler = () => {
    setAddNewContact(false);
    getClientOrCompnay(user.firm);
  };

  useEffect(() => {
    getClientOrCompnay(user.firm);
  }, [user.firm]);
  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 13 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <Button
                onClick={() => setAddNewContact(true)}
                sx={{ height: 50 }}
                fullWidth
                size="large"
                variant="outlined"
              >
                Add New Contact
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography varient="h5" textAlign="center" mt={4}>
                Or
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={5}>
              <Autocomplete
                multiple
                id="clientOrCompany"
                name="clientOrCompany"
                options={list || []}
                getOptionLabel={(option) => option.label}
                value={formik.values.clientOrCompany}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('clientOrCompany', newValue);
                }}
                renderOption={(props, option: AcOption) => (
                  <Box key={option._id}>
                    <Typography variant="h4" {...props}>
                      {option.label}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ mt: -2, fontSize: '12px' }}
                      {...props}
                    >
                      {option.type === 'client' ? 'Client' : 'Company'}
                    </Typography>
                  </Box>

                  // <List
                  //   key={option.value}
                  //   sx={{
                  //     width: '100%',
                  //     maxWidth: 360,
                  //     bgcolor: 'background.paper',
                  //     mb: -6,
                  //   }}
                  // >
                  //   <ListItem alignItems="flex-start">
                  //     <ListItemText
                  //       primaryTypographyProps={{
                  //         fontSize: '15px',
                  //         fontWeight: 'bold',
                  //         cursor: 'pointer',
                  //       }}
                  //       primary={option.label}
                  //       secondary={
                  //         <>
                  //           <Typography
                  //             sx={{ display: 'inline' }}
                  //             component="p"
                  //             variant="p"
                  //             color="text.primary"
                  //           >
                  //             {option.type === 'client' ? 'Client' : 'Company'}
                  //           </Typography>
                  //         </>
                  //       }
                  //     />
                  //   </ListItem>
                  // </List>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Client or Company"
                    error={
                      formik.touched.clientOrCompany &&
                      Boolean(formik.errors.clientOrCompany)
                    }
                    helperText={
                      formik.touched.clientOrCompany &&
                      formik.errors.clientOrCompany
                    }
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} md={5}>
              <Autocomplete
                multiple
                id="clientOrCompany"
                name="clientOrCompany"
                options={list || []}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option.label}
                value={formik.values.clientOrCompany}
                onChange={(event, newValue) => {
                  formik.setFieldValue('clientOrCompany', newValue);
                }}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Client or Company"
                    error={
                      formik.touched.clientOrCompany &&
                      Boolean(formik.errors.clientOrCompany)
                    }
                    helperText={
                      formik.touched.clientOrCompany &&
                      formik.errors.clientOrCompany
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          {formik.values.clientOrCompany !== null && (
            <CompanyAndClientsTable
              contactData={formik.values.clientOrCompany}
              formik={formik}
            />
          )}
        </Box>
        <Box sx={{ textAlign: 'center', mt: 20 }}>
          <Typography variant="h2">
            Start creating your case by adding a new or existing contact.
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Typography variant="p">
              All cases need at least one client to bill.
            </Typography>
          </Box>
          {/* <Link sx={{ mt: 2 }}>
            Learn more about adding a case and a contact at the same time.
          </Link> */}
        </Box>
        {addNewContact && (
          <AddNewContact
            open={addNewContact}
            onClose={() => dataPullhandler()}
          />
        )}
      </form>
    </>
  );
};

export default ClientsAndContacts;
