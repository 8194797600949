import { GET_ALL_BILLDOCUMENT, ADD_BILLDOCUMENT } from './billType';

export const loadBillDocumentListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_BILLDOCUMENT,
    payload: data,
  });
};

export const loadBillAddDocumentAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_BILLDOCUMENT,
    payload: data,
  });
};
