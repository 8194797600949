import { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import SwitchComp from '../../utility/switch/Switch';
import api from '../../utility/api';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux/es';
import { loadAddCaseAction } from '../../redux/cases/caseActions';

const AddCase = ({ formik }) => {
  const [practiceAreaList, setPracticeAreaList] = useState([]);
  const [showPracticeArea, setShowPracticeArea] = useState(false);

  const getPracticeArea = async () => {
    try {
      const response = await api.get(`cases/practicearea`);
      setPracticeAreaList(response.data.data.allPracticeArea);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getPracticeArea();
  }, []);

  return (
    <Box sx={{ mt: 13 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Case Name*
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            id="caseName"
            name="caseName"
            label="Case Name"
            variant="outlined"
            placeholder="John Smith"
            value={formik.values.caseName}
            onChange={formik.handleChange}
            error={formik.touched.caseName && Boolean(formik.errors.caseName)}
            helperText={formik.touched.caseName && formik.errors.caseName}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Case Number
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            id="caseNumber"
            name="caseNumber"
            label="Case Number"
            variant="outlined"
            placeholder="John Smith"
            value={formik.values.caseNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.caseNumber && Boolean(formik.errors.caseNumber)
            }
            helperText={formik.touched.caseNumber && formik.errors.caseNumber}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 4 }} variant="h4">
            Practice Area
          </Typography>
        </Grid>
        <Grid item xs={12} md={4.5}>
          {!showPracticeArea ? (
            <Autocomplete
              value={formik.values?.practiceArea}
              id="practiceArea"
              name="practiceArea"
              options={practiceAreaList}
              // inputValue={formik.values?.practiceArea}
              getOptionLabel={(option) => option.name || ''}
              onChange={(event, newValue) => {
                formik.setFieldValue('practiceArea', newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Practice Area"
                  error={
                    formik.touched.practiceArea &&
                    Boolean(formik.errors.practiceArea)
                  }
                  helperText={
                    formik.touched.practiceArea && formik.errors.practiceArea
                  }
                />
              )}
            />
          ) : (
            <TextField
              fullWidth
              id="practiceAreaInput"
              name="practiceAreaInput"
              variant="outlined"
              value={formik.values.practiceAreaInput}
              onChange={formik.handleChange}
              error={
                formik.touched.practiceAreaInput &&
                Boolean(formik.errors.practiceAreaInput)
              }
              helperText={
                formik.touched.practiceAreaInput &&
                formik.errors.practiceAreaInput
              }
            />
          )}
        </Grid>
        <Grid sx={{ mt: 2, ml: 2 }} item xs={12} md={4}>
          <Button
            onClick={() => setShowPracticeArea(!showPracticeArea)}
            variant="text"
          >
            {showPracticeArea ? 'cancel' : 'Add new practice area'}
          </Button>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Case Stage
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <FormControl fullWidth>
            <Select
              id="caseStage"
              name="caseStage"
              options={['discovery', 'on trial', 'on hold']}
              value={formik.values.caseStage || ''}
              onChange={formik.handleChange}
            >
              <MenuItem value="discovery">Discovery</MenuItem>
              <MenuItem value="on trial">On Trial</MenuItem>
              <MenuItem value="on hold">On Hold</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Date Opened
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(value) => formik.setFieldValue('dateOpened', value)}
              value={dayjs(formik.values.dateOpened)}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              renderInput={() => {
                <TextField
                  fullWidth
                  variant="outlined"
                  id="dateOpened"
                  name="dateOpened"
                />;
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Office
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Office</InputLabel>
            <Select
              labelId="office"
              id="office"
              name="office"
              label="office"
              options={['office']}
              value={formik.values.office || ''}
              onChange={formik.handleChange}
            >
              <MenuItem value="primary">Primary</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Description
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            variant="outlined"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Statute of Limitations
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(formik.values.statuteOfLimitation)}
              onChange={(value) =>
                formik.setFieldValue('statuteOfLimitation', value, true)
              }
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              renderInput={() => {
                <TextField
                  fullWidth
                  variant="outlined"
                  name="statuteOfLimitation"
                  id="statuteOfLimitation"
                />;
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 4 }} variant="h4">
            Conflict Check
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ ml: 4 }}>
            <FormControl>
              {formik.values.conflictCheck === true && (
                <FormControlLabel
                  control={
                    <Switch
                      name="conflictCheck"
                      defaultChecked
                      onChange={formik.handleChange}
                      value={formik.values.conflictCheck}
                    />
                  }
                />
              )}
              {formik.values.conflictCheck === false && (
                <FormControlLabel
                  control={
                    <Switch
                      name="conflictCheck"
                      onChange={formik.handleChange}
                      value={formik.values.conflictCheck}
                    />
                  }
                />
              )}
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography sx={{ mt: 3 }} variant="h4">
            Conflict check note
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            id="conflictCheckNote"
            name="conflictCheckNote"
            variant="outlined"
            value={formik.values.conflictCheckNote}
            onChange={formik.handleChange}
            error={
              formik.touched.con && Boolean(formik.errors.conflictCheckNote)
            }
            helperText={
              formik.touched.conflictCheckNote &&
              formik.errors.conflictCheckNote
            }
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCase;
