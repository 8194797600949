import { GET_ALL_FIRMMEMBER, ADD_FIRMMEMBER } from './FirmMemberTypes';

export const loadFirmMemberListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_FIRMMEMBER,
    payload: data,
  });
};

export const loadAddFirmMemberAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_FIRMMEMBER,
    payload: data,
  });
};
