import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import theme from '../../utility/theme';
import { useNavigate, useLocation } from 'react-router-dom';
import ExpandCard from './ExpandCard';
import AddItem from './AddItem';
import NumberCard from './NumberCrad';
import {
  CreditCard,
  House,
  LocalHospital,
  Note,
  People,
  PermCameraMic,
  Store,
  Vaccines,
} from '@mui/icons-material';
import CasesTable from '../case/CasesTable';
import HomeCaseTable from './HomeCaseTable';
import DataUseChart from './DataUsageChart';
import api from '../../utility/api';
import { useDispatch } from 'react-redux';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { useSelector } from 'react-redux';
const drawerActiveListBackgroundColor = 'rgba(255,255,255, 0.08)';

function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();

  let [countData, setCountData] = useState({});

  const getAllCount = async (firmID) => {
    try {
      const response = await api.get(`/common/firms/${firmID}/counts`);
      setCountData(response.data.data.countsData);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCount(user.firm);
  }, [user.firm]);
  return (
    <>
      <Box mt={6} mb={6}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={2.4} sx={{ height: 150 }}>
            <NumberCard
              header="Companies"
              num={countData?.companies || 0}
              icon={
                <Store sx={{ height: 30, width: 30, color: 'success.main' }} />
              }
              path="/contacts/companies"
            />
          </Grid>
          <Grid item xs={12} sm={2.4} sx={{ height: 150 }}>
            <NumberCard
              header="Contacts"
              num={countData?.contacts || 0}
              icon={<People sx={{ height: 30, width: 30 }} color="warning" />}
              path="/contacts"
            />
          </Grid>
          <Grid item xs={12} sm={2.4} sx={{ height: 150 }}>
            <NumberCard
              header="Firms"
              num={countData?.firmMembers || 0}
              icon={<House sx={{ height: 30, width: 30 }} color="error" />}
              path="/firm-members"
            />
          </Grid>
          <Grid item xs={12} sm={2.4} sx={{ height: 150 }}>
            <NumberCard
              header="Billing"
              num={countData?.billings || 0}
              icon={
                <CreditCard sx={{ height: 30, width: 30, color: '#9c27b0' }} />
              }
              path="/billing"
            />
          </Grid>
          <Grid item xs={12} sm={2.4} sx={{ height: 150 }}>
            <NumberCard
              header="Notes"
              num={countData?.notes || 0}
              icon={<Note sx={{ height: 30, width: 30 }} color="primary" />}
              path="/notes"
            />
          </Grid>

          {/* <Grid item xs={12} sm={6} sx={{ height: 416 }}>
            <HomeCaseTable />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: 416 }}>
            <DataUseChart />
          </Grid> */}
        </Grid>
        <ExpandCard counts={countData} />
        <Grid
          container
          sx={{ mt: 4 }}
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={6} sx={{ height: 416 }}>
            <HomeCaseTable />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: 416 }}>
            <DataUseChart />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Home;
