import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MaterialReactTable from 'material-react-table';
import { Box, Button, MenuItem, Typography, darken } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import AddContact from './AddContacts';
import AddPerson from './AddPerson';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import {
  loadAllContactListAction,
  loadContactListAction,
} from '../../redux/contacts/contactsActions';
import dayjs from 'dayjs';
import AddNewContact from '../case/AddNewContact';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ClientsTable = () => {
  const [showContact, setShowContact] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState('');

  const user = useSelector((state) => state.auth.user?.user);
  const contactList = useSelector((state) => state.contact?.contactListData);

  const dispatch = useDispatch();

  const getContacts = async (firmID) => {
    try {
      const response = await api.get(`clients/firms/${firmID}`);

      if (response) {
        dispatch(loadAllContactListAction(response.data.data.clients));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  const getEditData = async (ID) => {
    let editData = contactList?.find((contact) => contact._id === ID);

    setEditData(editData);
  };

  const deleteContact = async (id) => {
    try {
      const response = await api.delete(`/clients/${id}/firms/${user.firm}`);
      dispatch(loadAllContactListAction(response.data.data.clients));

      dispatch(showSnackbarAction(response.data.message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  // let data =
  //   contactList?.length > 0 &&
  //   contactList?.map((item) => {
  //     let Obj = {
  //       firstName: item?.firstName,
  //       lastName: item?.lastName,
  //       email: item?.email,
  //       caseName: 'murder case',
  //       assignedDate: dayjs(item.dateAdded).format('LL'),
  //       lastUpdated: '20 apr 2023',
  //       _id: item._id,
  //     };
  //     return Obj;
  //   });

  let clientsColumn = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName', //normal accessorKey
        header: 'Last Name',
      },

      {
        accessorKey: 'user.email',
        header: 'Email',
      },
      {
        accessorKey: 'user.mobile',
        header: 'Mobile',
      },
      {
        accessorFn: (row) => new Date(row.dateAdded),
        header: 'Assigned date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getContacts(user.firm);
  }, [user.firm]);

  return (
    <>
      <Box sx={{ marginTop: 10 }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={clientsColumn}
          data={contactList || []}
          // muiTableBodyProps={{
          //   sx: (theme) => ({
          //     '& tr:nth-of-type(odd)': {
          //       backgroundColor: darken(theme.palette.background.default, 0.1),
          //     },
          //   }),
          // }}
          editingMode="modal"
          enableColumnOrdering
          enableRowActions
          positionActionsColumn="last"
          renderTopToolbarCustomActions={() => (
            <Button onClick={() => setShowContact(true)} variant="contained">
              Add Client
            </Button>
          )}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              getEditData(row.original._id);
              setShowEdit(!showEdit);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                getEditData(row.original._id);
                setShowEdit(!showEdit);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={3}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteContact(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={3}>Delete</Typography>
            </MenuItem>,
          ]}
        />
      </Box>
      {showContact && (
        <AddContact open={showContact} setShowContact={setShowContact} />
      )}
      {showEdit && editData && (
        <AddNewContact
          onClose={() => setShowEdit(!showEdit)}
          showEdit={showEdit}
          editData={editData}
        />
      )}
    </>
  );
};

export default ClientsTable;
