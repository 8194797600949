import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  UploadFile,
  Close,
  ContentCopy,
  PostAdd,
  Inbox,
} from '@mui/icons-material';

import AddDocument from './AddDocument';

import theme from '../../utility/theme';
import { useDispatch, useSelector } from 'react-redux/es';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { loadFirmFolderListAction } from '../../redux/firmFolder/firmFolderAction';
import UploadFirmDocument from './UploadFirmDocument';

export default function FirmPopover({ id, anchorEl, onClose }) {
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showUploadDialog2, setShowUploadDialog2] = useState(false);
  const [addDocument, setShowAddDocument] = useState(false);

  return (
    <Box>
      <Popover
        id={id}
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setShowAddDocument(true)}>
              <ListItemIcon>
                <Inbox />
              </ListItemIcon>
              <ListItemText primary="Document" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setShowUploadDialog(true)}>
              <ListItemIcon>
                <PostAdd />
              </ListItemIcon>
              <ListItemText primary="Folder" />
            </ListItemButton>
          </ListItem>
        </List>
        <CreateNewFolder
          edit={false}
          editData={{}}
          open={showUploadDialog}
          onClose={() => setShowUploadDialog(false)}
        />
      </Popover>
      <UploadFirmDocument
        open={addDocument}
        onClose={() => setShowAddDocument(false)}
      />
    </Box>
  );
}

export const CreateNewAccountModal = ({ open, onClose, onSubmit }) => {
  const [showSingle, setShowSingle] = useState(false);
  const [showMulti, setShowMulti] = useState(false);

  return (
    <Dialog sx={{ padding: 6 }} open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: 'theme.typography.h3' }}>
          <Typography variant="h4"> Add File</Typography>
        </DialogTitle>
        <DialogTitle onClick={() => onClose()}>
          <Close />
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3">
            How would you like to add your file?
          </Typography>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box
            sx={{
              border: '1px solid #bdbdbd',
              minWidth: 250,
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => setShowSingle(true)}
              sx={{ color: 'primary.main' }}
            >
              <UploadFile sx={{ fontSize: '80px' }} />
            </IconButton>
            <Typography variant="h6"> Upload one file</Typography>
          </Box>
          <Box
            sx={{
              border: '1px solid #bdbdbd',
              minWidth: 250,
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => setShowMulti(true)}
              sx={{ color: 'primary.main' }}
            >
              <ContentCopy sx={{ fontSize: '80px' }} />
            </IconButton>
            <Typography variant="h6"> Upload multiple file</Typography>
          </Box>
        </Stack>
        {(showSingle || showMulti) && (
          <AddDocument
            open={showSingle || showMulti}
            multi={showMulti}
            setShowMultiUploadDialog={setShowMulti}
            setUploadDialog={setShowSingle}
            onClose={onClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const CreateNewFolder = ({ edit, editData, open, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user.user);
  const onSubmit = async (values, actions) => {
    let formData = {
      type: 'firm',
      name: values?.folder,
    };
    async function apiCall() {
      try {
        let response;

        if (edit) {
          response = await api.patch(
            `cases/firms/${user.firm}/firmFolders/${editData._id}`,
            formData
          );
        } else {
          response = await api.post(
            `cases/firms/${user.firm}/firmFolders`,
            formData
          );
        }

        if (response) {
          dispatch(loadFirmFolderListAction(response.data.data.folders));
          onClose();
        }
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, 'error'));
      }
    }

    apiCall();
  };

  const initialValues = {
    folder: edit ? editData.name : '',
  };

  const folderValidationSchema = yup.object().shape({
    folder: yup.string('Enter folder name').required('Required'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: folderValidationSchema,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{
        padding: 6,
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: theme.typography.h3 }}>
          {edit ? 'Update Folder' : 'Add Folder'}
        </DialogTitle>
        <DialogTitle onClick={() => onClose()}>
          <Close />
        </DialogTitle>
      </Box>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            labelId="folder"
            id="folder"
            fullWidth
            name="folder"
            value={formik.values?.folder}
            label="Folder"
            onChange={formik.handleChange}
            error={formik.touched.folder && Boolean(formik.errors.folder)}
            helperText={formik.touched.folder && formik.errors.folder}
          />
          <Box sx={{ textAlign: 'end', mt: 4 }}>
            <Button type="submit" variant="contained">
              {!edit ? 'Add Folder' : 'Update Folder'}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
