import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MaterialReactTable from 'material-react-table';
import { Box, Button, MenuItem, Typography } from '@mui/material';

import AddNote from './AddNote';
import Loader from '../loader/Loader';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import { loadNotesListAction } from '../../redux/Notes/NotesActions';
import { loadCaseListAction } from '../../redux/cases/caseActions';

import api from '../../utility/api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const Notes = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user.user);
  const notes = useSelector((state) => state.note?.noteList);

  const dispatch = useDispatch();

  const getAllCases = async (firmId) => {
    try {
      const response = await api.get(`/cases/firms/${firmId}?clients=true`);
      dispatch(loadCaseListAction(response.data.data));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getAllNotes = async (firmId) => {
    try {
      const response = await api.get(`/notes/firms/${firmId}`);
      console.log({ response });
      dispatch(loadNotesListAction(response.data.data.notes));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const deleteNote = async (noteID) => {
    try {
      const response = await api.delete(
        `/notes/firms/${user.firm}/notes/${noteID}`
      );
      if (response) {
        dispatch(loadNotesListAction(response.data.data.notes));
        dispatch(showSnackbarAction('Note Deleted Successfully', 'success'));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getEditData = async (id) => {
    try {
      const response = await api.get(`notes/firms/${user.firm}/notes/${id}`);
      if (response) {
        setEditData(response.data.data.note);
        setIsLoading(false);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCases(user.firm);
    getAllNotes(user.firm);
  }, [user.firm]);

  let columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          return `${row.case} ${row.contact}`;
        },
        header: 'Case or Client',
        Cell: ({ renderedCellValue, row }) => {
          return row.original?.contact ? (
            <Typography variant="p">
              {`${row.original.contact.firstName} ${row.original.contact.lastName} (Client)`}
            </Typography>
          ) : (
            <Typography variant="p">
              {`${row.original?.case?.caseName} (Case)`}
            </Typography>
          );
        },
        filterFn: 'caseOrLeadFilter',
      },

      {
        accessorKey: 'subject',
        header: 'Subject',
      },
      {
        accessorFn: (row) => new Date(row.date),
        header: 'Assigned date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <Box>
      <Typography variant="h2" mb={3}>
        Note Detail
      </Typography>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        filterFns={{
          caseOrLeadFilter: (row, id, filterValue) => {
            if (row?.original?.case?.caseName) {
              return row.original?.case?.caseName
                .toLowerCase()
                .includes(filterValue.toLowerCase());
            } else {
              return row.original?.contact?.firstName
                .toLowerCase()
                .includes(filterValue.toLowerCase());
            }
          },
        }}
        data={notes}
        enableColumnOrdering
        enableRowActions
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            cursor: 'pointer',
          },
        })}
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key="edit"
            onClick={() => {
              setIsLoading(true);
              getEditData(row.original._id);
              setShowEdit(!showEdit);
              closeMenu();
            }}
          >
            <EditIcon />
            <Typography ml={2}>Edit</Typography>
          </MenuItem>,
          <MenuItem
            key="delete"
            onClick={() => {
              deleteNote(row.original._id);
              closeMenu();
            }}
          >
            <DeleteIcon />
            <Typography ml={2}>Delete</Typography>
          </MenuItem>,
        ]}
        renderTopToolbarCustomActions={() => (
          <>
            <Button variant="contained" onClick={() => setShowAdd(!showAdd)}>
              Add
            </Button>
          </>
        )}
        renderDetailPanel={({ row }) => (
          <>
            <Box
              sx={{
                width: '50%',
              }}
            >
              <Typography variant="h4">{row.original?.note}</Typography>
            </Box>
          </>
        )}
      />

      {showAdd && <AddNote open={showAdd} setShowNote={setShowAdd} />}
      {isLoading ? (
        <Loader open={isLoading} />
      ) : (
        showEdit && (
          <AddNote
            showEdit={showEdit}
            setShowNote={setShowEdit}
            editData={editData}
          />
        )
      )}
    </Box>
  );
};

export default Notes;
