import { Box, Container } from '@mui/system';
import LeadTable from '../../components/lead/LeadTable';
import { useMemo } from 'react';

const LeadPage = () => {
  let leadData = [
    {
      fullName: 'Wasim Reza',
      email: 'reza@gmail.com',
      caseDate: '20 apr 2023',
      status: 'Contacted',
      potentialValue: 5000,
    },
    {
      fullName: 'Wasim Reza',
      email: 'reza@gmail.com',
      caseDate: '20 apr 2023',
      status: 'Contacted',
      potentialValue: 5000,
    },
  ];

  let leadColumn = useMemo(
    () => [
      {
        accessorKey: 'fullName', //access nested data with dot notation
        header: 'Name',
      },
      {
        accessorKey: 'email', //normal accessorKey
        header: 'Email',
      },

      {
        accessorKey: 'caseDate',
        header: 'Case Date',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        accessorKey: 'potentialValue',
        header: 'Potential Value',
      },
    ],
    []
  );
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <LeadTable columns={leadColumn} rows={leadData} />
      </Box>
    </Container>
  );
};

export default LeadPage;
