import { GET_ALL_NOTES } from "./NotesTypes";

const INITIAL_STATE = {
  noteList: [],
};

const noteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_NOTES:
      return {
        noteList: action.payload,
      };

    default:
      return state;
  }
};

export default noteReducer;
