import React from "react";
import { Box, Typography } from "@mui/material";
import ChangePasswordForm from "./ChangePasswordForm";

import { Fonts } from "../../../utility/theme";

const ChangePassword = () => {
  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: 550,
      }}
    >
      <Typography
        component="h3"
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 3, lg: 5 },
        }}
      >
        Change Password
      </Typography>

      <ChangePasswordForm />
    </Box>
  );
};

export default ChangePassword;
