import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinearProgress from '@mui/material/LinearProgress';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import {
  UploadFile,
  Close,
  ContentCopy,
  PostAdd,
  Inbox,
} from '@mui/icons-material';

import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BalanceIcon from '@mui/icons-material/Balance';
import CloseIcon from '@mui/icons-material/Close';

import Loader from '../loader/Loader';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import axios from 'axios';
import { loadCaseListAction } from '../../redux/cases/caseActions';
import CaseDetails from '../case/case-details/CaseDetails';
import { loadDocumentListAction } from '../../redux/document/documentAction';
import { loadCaseDocumentListAction } from '../../redux/caseDocument/caseDocumentAction';
import { loadCaseBillDocumentListAction } from '../../redux/caseBill/caseBillAction';
import { loadFirmDocumentListAction } from '../../redux/firmDocuments/firmDocumentAction';
import { loadBillDocumentListAction } from '../../redux/bill/billAction';

const AddOneDocument = ({
  editData,
  editMode,
  setUploadDialog,
  caseDetails,
  type,
}) => {
  const [value, setValue] = useState(0);
  const [folderName, setFolderName] = useState('');
  const [progress, setProgress] = useState(false);
  const [caseFolder, setCaseFolder] = useState([]);
  const [clients, setClients] = useState([]);
  const [caseData, setCaseData] = useState({});
  const [checked, setChecked] = useState([1]);

  const user = useSelector((state) => state.auth.user.user);
  const caseList = useSelector((state) => state.case?.caseList);
  const dispatch = useDispatch();

  const documentValidationSchema = yup.object().shape({
    ...(!caseDetails && {
      caseLink: yup.object().shape({
        value: !caseDetails && yup.string().required('Required'),
        label: !caseDetails && yup.string('Required'),
      }),
    }),
    docName: yup.string('Enter doc name').trim().required('Required'),
    // document: yup
    //   .mixed()
    //   .test('required', 'You need to provide a file', (form) => {
    //     if (formik?.values?.document?.length > 0) return true;
    //     return false;
    //   }),

    document: yup.array().min(1).required('Required'),

    // folder: yup.mixed().test('required', 'Required', (form) => {
    //   console.log('folder', formik.values.folder, formik.values.folderName);
    //   if (
    //     formik.values.folder?.length > 0 ||
    //     formik.values.folderName != '' ||
    //     !formik.values.folder
    //   ) {
    //     return true;
    //   }

    //   return false;
    // }),
    ...(caseFolder?.length > 0 && {
      folder: yup.string().required('Required'),
    }),
  });

  const getAllCases = async (firmId) => {
    try {
      const response = await api.get(`/cases/firms/${firmId}`);
      dispatch(loadCaseListAction(response.data.data.case));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const initialValues = {
    caseLink: null,
    createNewFolder: '',
    folder: '',
    docName: '',
    assignedDate: '',
    tag: '',
    tags: [],
    description: '',
    uploadedBy: '',
    firmUserAccess: '',
    type: '',
    document: [],
  };
  const onSubmit = async (values, actions) => {
    const formData = {
      caseId: caseDetails?._id ? caseDetails?._id : values.caseLink.value,
      createNewFolder: caseFolder?.length > 0 ? false : true,
      folder: caseFolder?.length > 0 ? values.folder : folderName,
      docName: values.docName,
      document: values.document,
      assignedDate: values.assignedDate,
      tags: values.tags,
      clients: checked.slice(1),
      description: values.description,
      uploadedBy: user._id,
      firmUserAccess: [
        { _id: user._id },
        ...caseData?.leadAttornies,
        ...caseData?.originatingAttornies,
      ],
      type: type ? type : 'case',
    };

    setProgress(true);

    async function apiCall() {
      try {
        let response = await api.post(
          `cases/documents/single/firms/${user.firm}`,
          formData
        );
        setProgress(false);
        dispatch(loadBillDocumentListAction(response.data.data.allDocuments));
        dispatch(loadCaseDocumentListAction(response.data.data.caseDocuments));
        dispatch(
          loadCaseBillDocumentListAction(response.data.data.caseDocuments)
        );
        dispatch(loadDocumentListAction(response.data.data.allDocuments));
        dispatch(showSnackbarAction(response.data.message, 'success'));
        dispatch(
          loadCaseBillDocumentListAction(response.data.data.caseDocuments)
        );
        setUploadDialog();
      } catch (err) {
        setProgress(false);
        dispatch(showSnackbarAction(err.response.data.message, 'error'));
      }
    }
    if (formik.values.document?.length > 0) {
      let newFiles = [];
      formik.values.document?.map((file) =>
        file.key ? null : newFiles.push(file)
      );
      if (newFiles?.length > 0) {
        const fileDataObj = new FormData();
        newFiles.forEach((file) => fileDataObj.append('file', file));

        if (fileDataObj.has('file')) {
          try {
            const fileResponse = await axios.post(
              'https://file.starlawyer.in/api/afsgdfgsdf56754hgfjhgj',
              fileDataObj
            );
            if (fileResponse) {
              formData.document = fileResponse.data.data;
              formData.document[0].name = formik.values.document[0]?.name;
            }
          } catch (err) {
            setProgress(false);
            dispatch(showSnackbarAction(err.response.data.message, 'error'));
            return;
          }
        }
      }
      await apiCall();
    } else {
      await apiCall();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: documentValidationSchema,
    enableReinitialize: true,
  });

  const myDropZone = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      formik.setFieldValue('document', acceptedFiles);
    },
  });

  const { getRootProps, getInputProps } = myDropZone;

  const baseStyle = {
    overflow: 'hidden',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      //  ...(isFocused ? focusedStyle : {}),
      // ...(isDragAccept ? acceptStyle : {}),
      //  ...(isDragReject ? rejectStyle : {}),
    }),
    []
  );

  const addTags = () => {
    if (!formik.values.tag?.length > 0) {
      formik.setFieldError('tag', 'Required');
      formik.setFieldTouched('tag', true, true);
      return;
    }
    const match = formik.values.tags.includes(formik.values.tag);

    if ((formik.values.tag?.length > 0) & !match) {
      formik.setFieldValue('tags', [...formik.values.tags, formik.values.tag]);
      formik.setFieldValue('tag', '');
      formik.setFieldValue('openTagsModal', false);
    }
  };

  let caseListOptions =
    caseList?.length > 0
      ? caseList?.map((item) => {
          let Obj = {
            label: item.caseName,
            value: item._id,
          };
          return Obj;
        })
      : '';

  const getFolders = async (data) => {
    try {
      const resp = await api.get(`cases/${data.value}/folders`);
      console.log('folders', resp?.data?.data?.folders);
      if (resp?.data?.data?.folders?.length === 0) {
        setCaseFolder([]);

        setFolderName(data.label ? data.label : caseDetails?.caseName);
      } else {
        setFolderName('');
        setCaseFolder(resp.data.data.folders);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getCaseDetails = async (data) => {
    let caseData = caseList.find((cases) => cases._id === data.value);
    setCaseData(caseData);
    setClients(caseData.clients);
  };

  useEffect(() => {
    getAllCases(user.firm);
    if (caseDetails) {
      getFolders({ value: caseDetails._id });
      getCaseDetails({ value: caseDetails?._id });
    }
  }, [user.firm]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container item pt={6}>
          <Grid
            item
            xs={12}
            sm={9}
            container
            spacing={6}
            sx={{
              borderRight: '1px dotted #d3c3c4!important',
            }}
          >
            {!caseDetails?._id && (
              <Grid item xs={12} md={2} mt={4}>
                <Typography variant="h4">Case Link*</Typography>
              </Grid>
            )}
            {!caseDetails?._id && (
              <Grid item xs={12} md={9}>
                <Stack>
                  <Autocomplete
                    id="caseLink"
                    name="caseLink"
                    disableClearable
                    options={caseListOptions?.length > 0 ? caseListOptions : []}
                    getOptionLabel={(option) => option.label || ''}
                    value={formik.values?.caseLink}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue('caseLink', newValue);
                      if (newValue) {
                        getCaseDetails(newValue);
                        getFolders(newValue);
                      } else {
                        setClients([]);
                        setCaseData({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Case Link"
                        error={
                          formik.touched.caseLink &&
                          Boolean(formik.errors.caseLink)
                        }
                        helperText={
                          formik.touched.caseLink && formik.errors.caseLink
                        }
                      />
                    )}
                  />
                  {/* <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="This document is not linked to a case"
                  />
                </FormGroup> */}
                </Stack>
              </Grid>
            )}
            {/* <Grid item xs={12} md={2} mt={4}>
              <Typography color="#00695c" variant="h4">
                Browse All
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={2} alignSelf="center">
              <Typography variant="h4">Folder Name*</Typography>
            </Grid>
            <Grid item xs={12} md={9} sx={{ bgcolor: 'gray' }}>
              {folderName || caseFolder?.length > 0 ? (
                <Typography variant="h4" color="#00695c">
                  {folderName ? (
                    folderName
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Folder
                      </InputLabel>
                      <Select
                        labelId="folder"
                        id="folder"
                        name="folder"
                        options={caseFolder || []}
                        value={formik.values?.folder}
                        label="Folder"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.folder && Boolean(formik.errors.folder)
                        }
                        helperText={
                          formik.touched.folder && formik.errors.folder
                        }
                      >
                        {caseFolder.map((folder) => {
                          return (
                            <MenuItem
                              value={
                                folder._id === undefined ||
                                folder._id === null ||
                                caseFolder?.length === 0
                                  ? ''
                                  : folder._id
                              }
                            >
                              {folder.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {formik.touched.folder && formik.errors.folder ? (
                        <FormHelperText
                          sx={{
                            color: '#bf3333',
                            marginLeft: '16px !important',
                          }}
                        >
                          {formik.touched.folder && formik.errors.folder}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                </Typography>
              ) : (
                <Box
                  sx={{
                    height: 52,
                    bgcolor: '#CCD9E3',
                    border: '1px dashed grey',
                    borderRadius: '10px',
                    px: 6,
                  }}
                >
                  <Typography mt={4}>No Case Seleted</Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={2} alignSelf="center">
              <Typography variant="h4">Doc Name*</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                id="docName"
                name="docName"
                label="Doc Name"
                variant="outlined"
                sx={{ bgcolor: 'gray' }}
                value={formik.values.docName}
                onChange={formik.handleChange}
                error={formik.touched.docName && Boolean(formik.errors.docName)}
                helperText={formik.touched.docName && formik.errors.docName}
              />
            </Grid>
            <Grid item xs={12} md={2} alignSelf="center">
              <Typography variant="h4">Assigned Date</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="assignedDate"
                  name="assignedDate"
                  onChange={(value) =>
                    formik.setFieldValue('assignedDate', value, true)
                  }
                  renderInput={() => {
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={formik.values.assignedDate}
                    />;
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={2} alignSelf="center">
              <Typography variant="h4">Source*</Typography>
            </Grid>
            <Grid item sm={9}>
              {formik.values.document?.length > 0 ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: 100,
                      border: '1px dashed grey',
                      borderRadius: 5,
                      px: 5,
                      bgcolor: '#f5f5f5',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        overflow: 'hidden',
                        width: '100%',
                        mr: '2.5rem',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() => {
                        if (formik.values.document[0]?.key) {
                          formik.setFieldValue(
                            'deteledCertificate',
                            formik.values.document[0]?.key
                          );
                        }
                        formik.setFieldValue('document', []);
                      }}
                    >
                      <Typography variant="h4" mr={3}>
                        {formik.values.document[0]?.name || 'document'}
                      </Typography>
                    </Box>

                    <CloseIcon
                      sx={{
                        position: 'absolute',
                        right: '1.4rem',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                        transformOrigin: 'left top',
                      }}
                      onClick={() => {
                        if (formik.values.document[0]?.key) {
                          formik.setFieldValue(
                            'deteledCertificate',
                            formik.values.document[0]?.key
                          );
                        }
                        formik.setFieldValue('document', []);
                      }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: 100,
                      border:
                        formik.touched.document && formik.errors.document
                          ? '1px dashed red'
                          : '1px dashed grey',
                      borderRadius: 5,
                      px: 5,
                      bgcolor: '#f5f5f5',
                    }}
                  >
                    <div {...getRootProps({ style })}>
                      <div>
                        <Typography sx={{ cursor: 'pointer' }} variant="h5">
                          Click & browse to upload document.
                        </Typography>
                      </div>
                      <input {...getInputProps()} />
                    </div>
                  </Box>
                  <Box>
                    {formik.touched.document && formik.errors.document ? (
                      <FormHelperText
                        sx={{
                          color: '#bf3333',
                          marginLeft: '16px !important',
                        }}
                      >
                        {formik.touched.document && formik.errors.document}
                      </FormHelperText>
                    ) : null}
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={2} alignSelf="center">
              <Typography variant="h4"></Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              {formik.values?.tags?.length > 0 &&
                formik.values.tags?.map((tg) => (
                  <List orientation="horizontal" variant="outlined">
                    <ListItem sx={{ mb: -3 }} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <CloseIcon
                            onClick={(e) => {
                              formik.setFieldValue(
                                'tags',
                                formik.values.tags.filter((e) => e !== tg)
                              );
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={tg} />
                      </ListItemButton>
                    </ListItem>
                  </List>
                ))}
            </Grid>
            <Grid item xs={12} md={2} alignSelf="center">
              <Typography variant="h4">Tags</Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                id="tag"
                name="tag"
                value={formik.values.tag}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.tag && Boolean(formik.errors.tag)}
                helperText={formik.touched.tag && formik.errors.tag}
              />
            </Grid>
            <Grid item xs={12} md={2} alignSelf="center">
              <Button color="#00695c" variant="h4" onClick={() => addTags()}>
                Add Tags
              </Button>
            </Grid>
            <Grid item xs={12} md={2} alignSelf="center">
              <Typography variant="h4">Description</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Stack>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  variant="outlined"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  multiline
                  rows={4}
                />
                <Typography mt={4}>
                  This description will be viewable by anyone this document is
                  shared with.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} spacing={4} sx={{ p: 2 }}>
            {clients?.length > 0 ? (
              <>
                <List
                  dense
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                  }}
                >
                  {clients.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value._id}`;
                    return (
                      <>
                        <ListItem
                          key={value._id}
                          secondaryAction={
                            <Checkbox
                              edge="end"
                              onChange={handleToggle(value)}
                              checked={checked.indexOf(value) !== -1}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          }
                          disablePadding
                        >
                          <ListItemIcon>
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemButton>
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: '17px',
                                p: 1,
                              }}
                              id={labelId}
                              primary={`${value.firstName} ${value.lastName}`}
                            />
                          </ListItemButton>
                        </ListItem>
                        <Box
                          sx={{ borderBottom: '1px dotted #d3c3c4!important' }}
                        ></Box>
                      </>
                    );
                  })}

                  <ListItem key={value} disablePadding>
                    <ListItemIcon>
                      <BalanceIcon />
                    </ListItemIcon>
                    <ListItemButton>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: '17px',
                        }}
                        primary={`This item will be automatically shared with all firm users
                    linked to ${caseData?.caseName} .`}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </>
            ) : (
              <Typography variant="h4" ml={4}>
                Select a Case Link to configure sharing
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: {
              xs: 'center',
              md: 'end',
            },
            pt: 8,
            pr: 10,
            borderTop: '1px dotted #d3c3c4!important',
          }}
        >
          <>
            {!progress ? (
              <Button
                sx={{
                  width: 220,
                  height: 40,
                  borderRadius: '30px',
                  color: 'white',
                  bgcolor: 'primary.main',
                  ':hover': {
                    color: 'white',
                    bgcolor: 'primary.main',
                  },
                }}
                type="submit"
              >
                Upload New Document
              </Button>
            ) : (
              <Box sx={{ width: '20%' }}>
                <LinearProgress sx={{ height: 30 }} />
              </Box>
            )}
          </>
        </Box>
      </form>
    </>
  );
};

export default AddOneDocument;
