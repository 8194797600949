import {
  AUTH_ERROR,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from './authTypes';
// import { REMOVE_PROFILE } from "../profile/profileTypes";
import axios from 'axios';
import { showSnackbarAction } from '../alert/alertAction';
import api from '../../utility/api';
import setAuthToken from '../../utility/setAuthToken';

// loaduser

export const loadUserAction = () => async (dispatch) => {
  try {
    const response = await api.get('/auth/attorney/user');

    dispatch({
      type: USER_LOADED,
      payload: response?.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// register;
export const registerAction = (formData, history) => async (dispatch) => {
  try {
    const response = await api.post('register', formData);
    dispatch({
      type: REGISTER_SUCCESS,
    });
    const { msg } = response.data;
    // dispatch(messageAction(msg));
  } catch (err) {
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(showSnackbarAction(error.msg, 'error'))
      );
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// login
export const loginAction = (userData) => async (dispatch) => {
  setAuthToken(localStorage.token);
  dispatch({
    type: LOGIN_SUCCESS,
    payload: userData,
  });
};

// logout
export const logoutAction = () => async (dispatch) => {
  localStorage.removeItem('token');
  // delete api.defaults.headers.common['x-access-token'];
  dispatch({
    type: LOGOUT,
  });
};
