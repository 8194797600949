import Container from '@mui/material/Container';
import Signup from '../../components/signup/Signup';

function SignupPage() {
  return (
    <Container sx={{ mt: 30 }} maxWidth="xl">
      <Signup />
    </Container>
  );
}

export default SignupPage;
