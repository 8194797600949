import { Box, Container } from '@mui/system';
import BillingTable from '../../components/billing/BillingTable';

const BillingPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <BillingTable />
      </Box>
    </Container>
  );
};

export default BillingPage;
