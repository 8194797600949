import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import MaterialReactTable from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Stack } from '@mui/system';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';

import AddDocument from '../documents/AddDocument';
import Task from './Task';
import Loader from '../loader/Loader';

import { UploadFile, Close, ContentCopy, Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { loadTaskListAction } from '../../redux/task/taskActions';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import AddCaseTask from '../case/case-details/AddCaseTask';
import TaskDetails from '../case/case-details/TaskDetails';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const TaskTable = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const [caseData, setCase] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openTask, setOpenTask] = useState(false);

  const user = useSelector((state) => state.auth.user.user);
  const taskList = useSelector((state) => state.task?.task);

  const dispatch = useDispatch();

  const getAllTask = async (firmID) => {
    try {
      const response = await api.get(`/tasks/firms/${firmID}`);
      console.log({ response });
      if (response) {
        dispatch(loadTaskListAction(response.data.data.tasks));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getCaseDetails = async (id) => {
    try {
      const response = await api.get(`/cases/${id}`);

      if (response) {
        setCase(response.data.data.case);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const filterEditData = async (id, caseId) => {
    try {
      console.log({ caseId });
      if (caseId) {
        getCaseDetails(caseId);
      } else {
        setCase({});
      }

      const response = await api.get(`tasks/firms/${user.firm}/tasks/${id}`);
      // console.log("dddddddd", response.data.data);
      setEditData(response.data.data.task);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          return `${row.case} ${row.lead}`;
        },
        header: 'Case or Lead',
        Cell: ({ renderedCellValue, row }) => {
          return row.original.lead ? (
            <Typography variant="p">
              {`${row.original.lead.firstName} ${row.original.lead.lastName} (Lead)`}
            </Typography>
          ) : (
            <Typography variant="p">
              {`${row.original?.case?.caseName} (Case)`}
            </Typography>
          );
        },
        filterFn: 'taskFilterFun',
      },
      {
        accessorKey: 'name',
        header: 'Task Name',
      },
      {
        accessorKey: 'priority',
        header: 'Priority',
      },
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      //   filterVariant: 'checkbox',
      //   Cell: ({ renderedCellValue, row }) => {
      //     return row.original.status == 'open' ? (
      //       <Typography
      //         sx={{ fontSize: '12px', fontWeight: 'bold', color: '#21766D' }}
      //         variant="p"
      //       >
      //         Pending
      //       </Typography>
      //     ) : (
      //       <Typography sx={{ fontSize: '12px', color: '#21766D' }} variant="p">
      //         Completed
      //       </Typography>
      //     );
      //   },
      // },

      {
        header: 'Status',
        accessorFn: (originalRow) =>
          originalRow.status === 'open' ? 'false' : 'true', //must be strings
        id: 'status',
        filterVariant: 'checkbox',
        Cell: ({ renderedCellValue, row }) => {
          return row.original.status == 'open' ? (
            <Typography
              sx={{ fontSize: '12px', fontWeight: 'bold', color: '#21766D' }}
              variant="p"
            >
              Pending
            </Typography>
          ) : (
            <Typography sx={{ fontSize: '12px', color: '#21766D' }} variant="p">
              Completed
            </Typography>
          );
        },
        // cell.getValue() === 'true' ? 'Pending' : 'Completed',
      },
      {
        accessorFn: (row) => new Date(row.dueDate),
        header: 'Due date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  const deleteTask = async (taskID) => {
    try {
      const response = await api.delete(
        `/tasks/firms/${user.firm}/tasks/${taskID}`
      );
      if (response) {
        dispatch(loadTaskListAction(response.data.data.tasks));
        dispatch(showSnackbarAction(response.data.message, 'success'));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllTask(user.firm);
  }, [user.firm]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h2" mb={3}>
          Task Detail
        </Typography>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columns}
          filterFns={{
            taskFilterFun: (row, id, filterValue) => {
              if (row?.original?.case?.caseName) {
                return row.original?.case?.caseName
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              } else {
                return row.original?.lead?.firstName
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              }
            },
          }}
          data={taskList || []}
          editingMode="modal" //default
          enableColumnOrdering
          // enableEditing
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
              filterEditData(row.original._id, row?.original?.case?._id);
              setOpenTask(true);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          positionActionsColumn="last"
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                }, 1000);
                filterEditData(row.original._id, row?.original?.case?._id);
                setShowEdit(!showEdit);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={2}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteTask(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={2}>Delete</Typography>
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={() => (
            <Button
              onClick={() => setShowAddTask(!showAddTask)}
              variant="contained"
            >
              Add Task
            </Button>
          )}
        />
      </Box>

      {showAddTask && (
        <Task
          showAddTask={showAddTask}
          onClose={() => setShowAddTask(!showAddTask)}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        showEdit && (
          <AddCaseTask
            showAddTask={showEdit}
            onClose={() => setShowEdit(false)}
            showEdit={showEdit}
            editData={editData}
            caseDetails={caseData}
            type={'all'}
          />
        )
      )}
      {openTask && (
        <TaskDetails
          open={openTask}
          onClose={() => setOpenTask(false)}
          caseDetails={caseData}
          task={editData}
        />
      )}
    </>
  );
};

export const CreateNewAccountModal = ({
  open,
  onClose,
  onSubmit,
  showUplod,
}) => {
  const [showSingle, setShowSingle] = useState(false);
  const [showMulti, setShowMulti] = useState(false);
  return (
    <Dialog sx={{ padding: 6 }} open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: 'theme.typography.h3' }}>
          Add File
        </DialogTitle>
        <DialogTitle onClick={() => onClose()}>
          <Close />
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3">
            How would you like to add your file?
          </Typography>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box
            sx={{
              border: '1px solid #bdbdbd',
              minWidth: 250,
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => setShowSingle(true)}
              sx={{ color: 'primary.main' }}
            >
              <UploadFile sx={{ fontSize: '80px' }} />
              <Typography variant="h6"> Upload one file</Typography>
            </IconButton>
          </Box>
          <Box
            sx={{
              border: '1px solid #bdbdbd',
              minWidth: 250,
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => setShowMulti(true)}
              sx={{ color: 'primary.main' }}
            >
              <ContentCopy sx={{ fontSize: '80px' }} />
            </IconButton>
            <Typography variant="h6"> Upload multiple file</Typography>
          </Box>
        </Stack>
      </DialogContent>
      <AddDocument
        open={showSingle}
        multi={showMulti}
        setUploadDialog={() => setShowSingle(false)}
        setShowMultiUploadDialog={() => setShowMulti(false)}
      />
    </Dialog>
  );
};

export default TaskTable;
