import { Box, Grid, Paper, Typography } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HistoryIcon from '@mui/icons-material/History';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SettingsIcon from '@mui/icons-material/Settings';

const ItemsAndInfo = ({ caseInformation }) => {
  return (
    <>
      <Grid container spacing={4} sx={{ py: 6 }}>
        {/* <Grid item xs={12} md={12}>
          <Paper sx={{ padding: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3">
                Case Timeline by Stage - Days Open: 6
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <SettingsIcon sx={{ marginRight: 4 }} />
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
          </Paper>
        </Grid> */}

        <Grid container item xs={12} md={12} spacing={3}>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h3" sx={{ color: '#323232' }}>
                Case Information
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid sx={{ mt: 4 }} item md={3} sm={12}>
              <Typography variant="h4">Name</Typography>
            </Grid>
            <Grid sx={{ mt: 4 }} item md={9} sm={12}>
              <Typography>{caseInformation.caseName}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sx={{ mt: 4 }} md={3} sm={12}>
              <Typography variant="h4">Case Number</Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }} md={9}>
              <Typography>
                {caseInformation?.caseNumber
                  ? caseInformation.caseNumber
                  : 'Not specifed'}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid item sx={{ mt: 3 }} md={4}>
            <Typography variant="h4">Date Closed</Typography>
          </Grid> */}
          <Grid container>
            <Grid item sx={{ mt: 4 }} sm={12} md={3}>
              <Typography variant="h4">Practice Area</Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }} md={5}>
              <Typography>
                {' '}
                {caseInformation?.practiceArea?.name
                  ? caseInformation.practiceArea?.name
                  : 'Not specifed'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sx={{ mt: 4 }} sm={12} md={3}>
              <Typography variant="h4">Case Stage</Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }} md={5}>
              <Typography>{caseInformation?.caseStage}</Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sx={{ mt: 4 }} sm={12} md={3}>
              <Typography variant="h4">Description</Typography>
            </Grid>

            <Grid item sx={{ mt: 4 }} md={9}>
              <Typography>{caseInformation?.description}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid sx={{ mt: 4 }} sm={12} item md={3}>
              <Typography variant="h4">Date Opened</Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }} md={9}>
              <Typography>
                {caseInformation?.dateOpened
                  ? new Date(caseInformation?.dateOpened).toDateString()
                  : 'Not specified'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid sx={{ mt: 4 }} sm={12} item md={3}>
              <Typography variant="h4">Status</Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }} md={9}>
              <Typography>
                {caseInformation?.active ? 'open' : 'Close'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid sx={{ mt: 4 }} sm={12} item md={3}>
              <Typography variant="h4">Clients</Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }} md={9}>
              {caseInformation?.clients?.length > 0 &&
                caseInformation?.clients?.map((client) => {
                  return (
                    <Typography sx={{ color: '#11CA5B' }}>
                      {`${client?.firstName} ${client.lastName}`}
                    </Typography>
                  );
                })}
            </Grid>
          </Grid>
          <Grid container>
            <Grid sx={{ mt: 4 }} sm={12} item md={3}>
              <Typography variant="h4">Companies</Typography>
            </Grid>
            <Grid item sx={{ mt: 4 }} md={9}>
              {caseInformation?.companies?.length > 0 &&
                caseInformation?.companies?.map((comp) => {
                  return (
                    <Typography key={comp?._id} sx={{ color: '#11CA5B' }}>
                      {`${comp?.name}`}
                    </Typography>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ItemsAndInfo;
