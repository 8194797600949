import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,

  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Legalpad data usage',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June'];

const data = {
  labels,
  datasets: [
    {
      label: 'Case Documents',
      data: [10, 15, 50, 300, 400, 19],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Bills',
      data: [13, 200, 60, 200, 89, 19],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function DataUseChart() {
  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        padding: 1,
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Line options={options} data={data} />
      </CardContent>
    </Card>
  );
}
