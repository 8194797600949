//field array
import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FieldArray,
  Form,
  Formik,
  getIn,
  useFormik,
  useFormikContext,
} from 'formik';
import * as yup from 'yup';

import Dropzone, { useDropzone } from 'react-dropzone';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import axios from 'axios';
import api from '../../utility/api';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SharingDialog from './SharingDialog';

const options = [
  { label: 'Multiple documents to one case file', value: 0 },
  { label: 'Multiple documents to multiple cases', value: 1 },
  { label: 'Multiple firm documents', value: 2 },
];

const documentValidationSchema = yup.object().shape({
  caseLink: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),

  documentDetails: yup
    .array(
      yup.object().shape({
        docName: yup.string().required('Document name required'),
        // assignedDate: yup.string().required('Assigned date required'),
      })
    )
    .min(1)
    .required('Required'),

  // folder: yup.mixed().test('required', 'Required', (form) => {
  //   if (formik.values.folder?.length > 0 || formik.values.folderName != '') {
  //     return true;
  //   }

  //   return false;
  // }),
});

const AddMutliDocuments = ({ multi, setShowMultiUploadDialog, type }) => {
  const [multiFolderName, setMultiFolderName] = useState('');
  const [caseFolder, setCaseFolder] = useState([]);
  const [clients, setClients] = useState([]);
  const [caseData, setCaseData] = useState({});
  const [folderName, setFolderName] = useState('');
  const [showSharingDialog, setShowSharingDialog] = useState(false);

  const user = useSelector((state) => state.auth.user.user);
  const caseList = useSelector((state) => state.case?.caseList);
  const dispatch = useDispatch();

  const initialValues = {
    caseLink: null,
    createNewFolder: true,
    folder: '',
    documentDetails: [],
    uploadedBy: user._id,
    firmUserAccess: [user._id],
    type: 'case',
    documents: [],

    // docName: formik.values?.documentDetails.length>0 ,
  };

  const getCaseDetails = async (data) => {
    let caseData = caseList.find((cases) => cases._id === data.value);
    setCaseData(caseData);
    setClients(caseData.clients);
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      caseId: values.caseLink.value,
      createNewFolder: caseFolder.length > 0 ? false : true,
      folder: caseFolder.length > 0 ? values.folder : multiFolderName,
      documentDetails: values.documentDetails,
      uploadedBy: user._id,
      firmUserAccess: [],
      type: type,
    };
    console.log({ formData });
    return;
    async function apiCall() {
      try {
        let response;
        response = await api.post(
          `cases/documents/multiple/firms/${user.firm}`,
          formData
        );
        dispatch(showSnackbarAction(response.data.message, 'success'));
        setShowMultiUploadDialog();
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, 'error'));
      }
    }
    if (values.documentDetails?.length > 0) {
      let newFiles = [];
      values.documentDetails?.map((file) =>
        file.key ? null : newFiles.push(file)
      );

      if (newFiles.length > 0) {
        const fileDataObj = new FormData();
        newFiles.forEach((file) => {
          fileDataObj.append('file', file);
        });

        if (fileDataObj.has('file')) {
          try {
            const fileResponse = await axios.post(
              'https://file.starlawyer.in/api/afsgdfgsdf56754hgfjhgj',
              fileDataObj
            );

            if (fileResponse) {
              formData.documentDetails = fileResponse.data.data;
              for (const file of formData.documentDetails) {
                for (const originaFile of values.documentDetails) {
                  if (
                    originaFile.name === file.fileNameWithExtension &&
                    originaFile.size === file.size
                  ) {
                    file.description = originaFile?.description;
                    file.docName = originaFile?.docName;
                    file.assignedDate = originaFile.assignedDate;
                  }
                }
              }
            }
          } catch (err) {
            dispatch(showSnackbarAction(err.response.data.message, 'error'));
            return;
          }
        }
      }
      await apiCall();
    }
  };

  // const formik = useFormik({
  //   initialValues: initialValues,
  //   onSubmit: onSubmit,
  //   validationSchema: documentValidationSchema,
  //   enableReinitialize: true,
  // });

  const myDropZone = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 10,
    onDrop: async (acceptedFiles) => {
      acceptedFiles.forEach((acptFile) => {
        acptFile.description = '';
        acptFile.assignedDate = '';
        acptFile.docName = '';
        acptFile.clients = [1];
      });
      // props.setFieldValue('documentDetails', acceptedFiles);
    },
  });

  const { getRootProps, getInputProps } = myDropZone;

  let caseListOptions =
    caseList?.length > 0
      ? caseList?.map((item) => {
          let Obj = {
            label: item.caseName,
            value: item._id,
          };
          return Obj;
        })
      : '';

  const getFolders = async (data) => {
    try {
      const resp = await api.get(`cases/${data.value}/folders`);

      if (resp?.data?.data?.folders?.length === 0) {
        setCaseFolder([]);
        setFolderName(data.label);
      } else {
        setFolderName('');
        setCaseFolder(resp.data.data.folders);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const submitHandler = async () => {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={documentValidationSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        console.log(props);
        return (
          <form onSubmit={props.handleSubmit} noValidate autoComplete="off">
            <Grid sx={{ minWidth: '1000px' }} container pt={6}>
              <Grid item xs={12} md={5}>
                <Box>
                  <Autocomplete
                    id="caseLink"
                    name="caseLink"
                    disableClearable
                    options={caseListOptions || []}
                    getOptionLabel={(option) => option.label || ''}
                    value={props.values?.caseLink}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(event, newValue) => {
                      props.setFieldValue('caseLink', newValue);
                      if (newValue) {
                        getCaseDetails(newValue);
                        getFolders(newValue);
                      } else {
                        setClients([]);
                        setCaseData({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Case Link"
                        error={
                          props.touched.caseLink &&
                          Boolean(props.errors.caseLink)
                        }
                        helperText={
                          props.touched.caseLink && props.errors.caseLink
                        }
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={5}>
                {folderName || caseFolder?.length > 0 ? (
                  <Typography variant="h4" color="#00695c">
                    {folderName ? (
                      folderName
                    ) : (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Folder
                        </InputLabel>
                        <Select
                          labelId="folder"
                          id="folder"
                          name="folder"
                          options={caseFolder || []}
                          value={props.values?.folder}
                          label="Folder"
                          onChange={props.handleChange}
                          error={
                            props.touched.folder && Boolean(props.errors.folder)
                          }
                          helpertext={
                            props.touched.folder && props.errors.folder
                          }
                        >
                          {caseFolder.map((folder) => {
                            return (
                              <MenuItem
                                key={folder?._id}
                                value={
                                  folder._id === undefined ||
                                  folder._id === null ||
                                  caseFolder.length === 0
                                    ? ''
                                    : folder._id
                                }
                              >
                                {folder.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {props.touched.folder && props.errors.folder ? (
                          <FormHelperText
                            sx={{
                              color: '#bf3333',
                              marginLeft: '16px !important',
                            }}
                          >
                            {props.touched.folder && props.errors.folder}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  </Typography>
                ) : (
                  <Box
                    sx={{
                      height: 52,
                      bgcolor: '#CCD9E3',
                      border: '1px dashed grey',
                      borderRadius: '10px',
                      px: 4,
                    }}
                  >
                    <Typography mt={4}>No Case Seleted</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>

            <Box sx={{ mt: 6 }}>
              {props.values.documentDetails?.length > 0 ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: 100,
                      border: '1px dashed grey',
                      borderRadius: 5,
                      px: 5,
                      bgcolor: '#f5f5f5',
                    }}
                  >
                    <Box>
                      <Typography variant="h4" mr={3}>
                        {props.values.documentDetails?.map((item, index) => {
                          return (
                            <Box
                              sx={{ display: 'flex', alignContent: 'center' }}
                            >
                              <Typography mr={3}>{item.name}</Typography>
                              <i
                                onClick={() => {
                                  if (props.values.documentDetails[0]?.key) {
                                    props.setFieldValue(
                                      'deteledCertificate',
                                      props.values.documentDetails[0]?.key
                                    );
                                  }

                                  let filterdDoc =
                                    props.values?.documentDetails.filter(
                                      (doc) =>
                                        doc.name != item.name &&
                                        doc.size != item.size
                                    );

                                  props.setFieldValue(
                                    'documentDetails',
                                    filterdDoc
                                  );
                                }}
                              >
                                <CloseIcon />
                              </i>
                            </Box>
                          );
                        }) || 'document'}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: 100,
                      border:
                        props.touched.documentDetails &&
                        props.errors.documentDetails
                          ? '1px dashed red'
                          : '1px dashed grey',
                      borderRadius: 5,
                      px: 5,
                      bgcolor: '#f5f5f5',
                    }}
                  >
                    {/* <div {...getRootProps()}>
                      <div>
                        <Typography variant="h5">
                          Drag upto 10 files here, or click to Browse Files.
                        </Typography>
                      </div>
                      <input {...getInputProps()} />
                    </div> */}
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        acceptedFiles.forEach((acptFile) => {
                          acptFile.description = '';
                          acptFile.assignedDate = '';
                          acptFile.docName = '';
                          acptFile.clients = [1];
                        });
                        props.setFieldValue('documentDetails', acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </Box>
                  <Box>
                    {props.touched.documentDetails &&
                    props.errors.documentDetails ? (
                      <FormHelperText
                        sx={{
                          color: '#bf3333',
                          marginLeft: '16px !important',
                        }}
                      >
                        {props.touched.documentDetails &&
                          props.errors.documentDetails}
                      </FormHelperText>
                    ) : null}
                  </Box>
                </>
              )}
            </Box>

            <FieldArray
              name="documentDetails"
              render={(arrayHelpers) => (
                <div>
                  {arrayHelpers.form.values.documentDetails.map(
                    (document, index) => {
                      const docName = `documentDetails[${index}].docName`;

                      const toucheddocName = getIn(props.touched, docName);
                      const errordocName = getIn(props.errors, docName);
                      // console.log(toucheddocName, errordocName, props.errors);
                      const assignedDate = `documentDetails[${index}].assignedDate`;
                      const touchedassignedDate = getIn(
                        props.touched,
                        assignedDate
                      );
                      const errorassignedDate = getIn(
                        props.errors,
                        assignedDate
                      );

                      const description = `documentDetails[${index}].description`;

                      return (
                        <Box
                          key={index}
                          sx={{
                            mt: 6,
                            height: 230,
                            bgcolor: '#f5f5f5',
                            border: '1px solid grey',
                            borderRadius: '8px',
                            px: 4,
                          }}
                        >
                          <Grid container item py={6}>
                            <Grid item xs={12} md={1} alignSelf="center">
                              <Typography variant="p">Name*</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="docName"
                                name={docName}
                                label="Doc Name"
                                variant="outlined"
                                sx={{ bgcolor: 'gray' }}
                                value={document?.docName}
                                helperText={
                                  toucheddocName && errordocName
                                    ? errordocName
                                    : ''
                                }
                                error={Boolean(toucheddocName && errordocName)}
                                onChange={(e) => {
                                  document.docName = e.target.value;

                                  props.setFieldValue(
                                    'documentDetails',
                                    props.values.documentDetails
                                  );
                                }}
                                // onBlur={props.handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} md={2} alignSelf="center"></Grid>
                            <Grid item xs={12} md={1} alignSelf="center">
                              <Typography variant="p">Case*</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography
                                sx={{ mt: 4, color: '#00695c' }}
                                variant="h4"
                              >
                                {caseData.caseName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item py={4}>
                            <Grid item xs={12} md={1} alignSelf="center">
                              <Typography variant="p">
                                Assigned Date*
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  fullWidth
                                  id="assignedDate"
                                  name={assignedDate}
                                  onChange={(value) => {
                                    document.assignedDate = value;

                                    props.setFieldValue(
                                      'documentDetails',
                                      props.values.documentDetails
                                    );
                                  }}
                                  slotProps={{ textField: { fullWidth: true } }}
                                  renderInput={() => {
                                    <TextField
                                      value={
                                        props.values.documentDetails[index]
                                          ?.assignedDate
                                      }
                                      fullWidth
                                      variant="outlined"
                                    />;
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid item xs={12} md={1} alignSelf="center">
                              <Typography variant="p">Description</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="description"
                                name={description}
                                variant="outlined"
                                value={document.description}
                                onChange={(e) => {
                                  document.description = e.target.value;

                                  props.setFieldValue(
                                    'documentDetails',
                                    props.values.documentDetails
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',

                              justifyContent: {
                                xs: 'center',
                                md: 'end',
                                lg: 'end',
                              },
                            }}
                          >
                            <Button
                              sx={{
                                px: '0.7rem',
                                py: '0.25rem',
                                borderRadius: '30px',
                                color: 'white',
                                bgcolor: '#27BFAD',
                                ':hover': {
                                  color: 'white',
                                  bgcolor: '#21A293',
                                },
                              }}
                              onClick={() => setShowSharingDialog(true)}
                            >
                              Edit Sharing
                            </Button>
                            <SharingDialog
                              key={index}
                              props={props}
                              open={showSharingDialog}
                              onClose={() => setShowSharingDialog(false)}
                              clients={clients}
                              caseData={caseData}
                              index={index}
                              documentDetails={props.values.documentDetails}
                            />
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </div>
              )}
            />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                mt: 4,
                justifyContent: {
                  xs: 'end',
                  md: 'end',
                  lg: 'end',
                },
                pt: 2,
                pr: 10,
              }}
            >
              <Button
                sx={{
                  width: 220,
                  height: 40,
                  borderRadius: '30px',
                  color: 'white',
                  bgcolor: 'primary.main',
                  ':hover': {
                    color: 'white',
                    bgcolor: 'primary.main',
                  },
                }}
                type="submit"
              >
                Upload New Document
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddMutliDocuments;
