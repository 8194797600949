import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Box,
  Button,
  Stack,
  Typography,
  Paper,
  Grid,
  TextField,
  Link,
  Card,
  Autocomplete,
} from '@mui/material';
import { Celebration } from '@mui/icons-material';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutoModeIcon from '@mui/icons-material/AutoMode';

import { Fonts } from '../../utility/theme';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import setAuthToken from '../../utility/setAuthToken';
import api from '../../utility/api';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object().shape({
  firstName: yup.string('Enter First Name').required('Required'),
  lastName: yup.string('Enter Last Name').required('Required'),

  lawFirmName: yup.string('Enter Firm Name').required('Required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  numberOfEmployees: yup.number('Enter Firm Name').required('Required'),

  mobile: yup
    .string('Enter Mobile')
    .matches(phoneRegExp, 'Enter valid number')
    .min(10)
    .max(10),
});

export default function Signup() {
  const [signup, setSignup] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getCountryCode = async () => {
    try {
      const response = await api.get(`/locations/countryCodes`);
      setCountryCodes(response.data.data.countries);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    lawFirmName: '',
    numberOfEmployees: 1,
    email: '',
    mobile: '',
    countryCode: null,
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      firmName: values.lawFirmName,
      employeeCount: values.numberOfEmployees,
      email: values.email,
    };

    if (values?.mobile) {
      formData.mobile = values?.mobile;
      formData.countryCode = values?.countryCode?.value
        ? values?.countryCode?.value
        : '91';
    }

    try {
      const response = await api.post(`auth/signup/step1`, formData);
      if (response) {
        setAuthToken(response.data.data.token);
        setSignup(true);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    getCountryCode();
  }, []);

  return (
    <>
      {!signup && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > :not(style)': {
              width: 700,
              minHeight: 300,
            },
          }}
        >
          <Card sx={{ p: 8 }}>
            <Box>
              <Typography
                sx={{ textAlign: 'center', color: 'text.secondary' }}
                variant="h1"
              >
                Sign Up
              </Typography>
            </Box>

            <Box
              sx={{
                marginBottom: 3,
                marginTop: 5,
                flexGrow: 1,
              }}
            >
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First Name*"
                      variant="outlined"
                      fullWidth
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last Name*"
                      variant="outlined"
                      fullWidth
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lawFirmName"
                      name="lawFirmName"
                      label="Law Firm Name*"
                      variant="outlined"
                      fullWidth
                      value={formik.values.lawFirmName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lawFirmName &&
                        Boolean(formik.errors.lawFirmName)
                      }
                      helperText={
                        formik.touched.lawFirmName && formik.errors.lawFirmName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="numberOfEmployees"
                      name="numberOfEmployees"
                      label="Number of employees*"
                      variant="outlined"
                      fullWidth
                      type="number"
                      inputProps={{ min: 1 }}
                      value={formik.values.numberOfEmployees}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.numberOfEmployees &&
                        Boolean(formik.errors.numberOfEmployees)
                      }
                      helperText={
                        formik.touched.numberOfEmployees &&
                        formik.errors.numberOfEmployees
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email*"
                      variant="outlined"
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={formik.values?.countryCode}
                      id="countryCode"
                      name="countryCode"
                      options={countryCodes}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(event, newValue) => {
                        formik.setFieldValue('countryCode', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="CountryCode"
                          error={
                            formik.touched.countryCode &&
                            Boolean(formik.errors.countryCode)
                          }
                          helperText={
                            formik.touched.countryCode &&
                            formik.errors.countryCode
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="mobile"
                      name="mobile"
                      label="Mobile"
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={formik.touched.mobile && formik.errors.mobile}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    marginTop: 8,
                    gap: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      size="large"
                      sx={{
                        minWidth: 160,
                        fontWeight: Fonts.REGULAR,
                        fontSize: 16,
                        textTransform: 'capitalize',
                        padding: '4px 16px 8px',
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography> Already an account?</Typography>
                  <Link
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() => navigate('/privacy-policy')}
                  >
                    Login
                  </Link>
                </Box>
                <Typography
                  sx={{ textAlign: 'center', mt: 6, color: 'grey.500' }}
                >
                  By clicking “Get Started”, you assert that you have read and
                  agreed to our
                  <Link
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() =>
                      navigate('/signup/terms', {
                        state: {
                          signup: true,
                        },
                      })
                    }
                  >
                    Terms of Service
                  </Link>{' '}
                  . For information about how we collect, store and use your
                  personal data, please read our
                  <Link
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() =>
                      navigate('/signup/privacy-policy', {
                        state: {
                          signup: true,
                        },
                      })
                    }
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </form>
            </Box>
          </Card>
        </Box>
      )}

      {signup && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > :not(style)': {
              //   width: 600,
              //   minHeight: 200,
            },
          }}
        >
          <Paper sx={{ width: 500, padding: 6, textAlign: 'center' }}>
            <Celebration
              fontSize="large"
              color="secondary"
              sx={{
                fontSize: 56,
              }}
            />
            <Typography my={1}>Registration received</Typography>
            <Typography variant="h1">
              Check Your Email to Start Your Free Trial
            </Typography>

            <Typography sx={{ mt: 4 }} variant="h6">
              In order to protect your security, we’ve sent an email to the
              address you provided. Use that email to start your free trial of
              My Lawyer now.
            </Typography>

            <Typography sx={{ mt: 4 }} variant="h6">
              Having trouble finding the email? Don’t forget to check your spam
              folder (or your Promotions bundle if you use inbox by Google).
              Still don’t see it? Give us a call at 8210380847.
            </Typography>
          </Paper>
        </Box>
      )}
    </>
  );
}
