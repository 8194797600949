import { GET_ALL_FIRMDOCUMENT, ADD_FIRMDOCUMENT } from './firmDocumentTypes';

export const loadFirmDocumentListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_FIRMDOCUMENT,
    payload: data,
  });
};

export const loadAddFirmDocumentAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_FIRMDOCUMENT,
    payload: data,
  });
};
