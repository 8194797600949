import Container from '@mui/material/Container';
import Home from '../../components/home/Home';

function HomePage() {
  return (
    <Container sx={{ mt: 25 }} maxWidth="md">
      <Home />
    </Container>
  );
}

export default HomePage;
