import { Stack } from '@mui/material';
import PrivacyPolicy from '../../components/terms-privacy-policy/PrivacyPolicy';
import { Box, Container } from '@mui/system';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <PrivacyPolicy />
      </Box>
    </Container>
  );
};

export default PrivacyPolicyPage;
