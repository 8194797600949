import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MaterialReactTable from 'material-react-table';
import { Box, Button, MenuItem, Modal, Typography } from '@mui/material';

import AddCompany from './AddCompany';
import Loader from '../loader/Loader';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { loadComanyListAction } from '../../redux/comapny/companyActions';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

const CompaniesTable = () => {
  const [showCompany, setShowCompany] = useState(false);
  const [editCompany, setEditComany] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user.user);
  const companyList = useSelector((state) => state.company?.companyList);

  let companyColumn = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Comapny Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'website',
        header: 'Website',
      },

      {
        accessorKey: 'mobile',
        header: 'Phone',
      },
      {
        accessorKey: 'address',
        header: 'Address',
      },
    ],
    []
  );

  // let data =
  //   companyList?.length > 0 &&
  //   companyList.map((item) => {
  //     let Obj = {
  //       name: item.name,
  //       email: item.email,
  //       website: item.website,
  //       mobile: `+${item?.countryCode} ${item?.mobile}`,
  //       address: item.address,
  //       _id: item._id,
  //     };
  //     return Obj;
  //   });

  const dispatch = useDispatch();

  const getAllCompanies = async (firmID) => {
    try {
      const response = await api.get(`clients/company?firm=${firmID}`);
      dispatch(loadComanyListAction(response.data.data.company));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getEditData = async (ID) => {
    try {
      const response = await api.get(`/clients/company/${ID}`);
      if (response) {
        setEditComany(response.data?.data?.company);
        setIsLoading(false);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const deleteComany = async (ID) => {
    try {
      const response = await api.delete(
        `clients/company/${ID}/firms/${user.firm}`
      );
      dispatch(loadComanyListAction(response.data.data.companies));
      dispatch(showSnackbarAction(response.data.message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCompanies(user.firm);
  }, [user.firm]);

  return (
    <>
      <Box sx={{ my: 10 }}>
        <Typography variant="h2" mb={10}>
          Companies
        </Typography>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={companyColumn}
          data={companyList}
          enableColumnOrdering
          enableRowActions
          positionActionsColumn="last"
          renderTopToolbarCustomActions={() => (
            <Button onClick={() => setShowCompany(true)} variant="contained">
              Add Company
            </Button>
          )}
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                getEditData(row.original._id);
                setIsLoading(true);
                setShowEdit(!showEdit);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={3}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteComany(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={3}>Delete</Typography>
            </MenuItem>,
          ]}
        />
        {isLoading ? (
          <Loader open={isLoading} />
        ) : (
          (showCompany || showEdit) && (
            <AddCompany
              open={showCompany}
              setShowNote={showCompany ? setShowCompany : setShowEdit}
              editData={editCompany}
              showCompany={showEdit}
            />
          )
        )}
      </Box>
    </>
  );
};

export default CompaniesTable;
