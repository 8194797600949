import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, MenuItem, Button, Typography } from '@mui/material';
import CreateNewAccountModal from '../Document-popup/DocumentPopUp';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { useDispatch, useSelector } from 'react-redux/es';
import api from '../../utility/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadFirmDocumentListAction } from '../../redux/firmDocuments/firmDocumentAction';
import UploadFirmDocument from './UploadFirmDocument';
import EditIcon from '@mui/icons-material/Edit';
import EditDocument from './EditDocuments';
import { ArrowBack, Delete, Download } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const FirmDocumentsTable = (props) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [addDocument, setShowAddDocument] = useState(false);
  const [editData, setEditData] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { state } = useLocation();

  const user = useSelector((state) => state.auth.user.user);
  const firmDocuments = useSelector(
    (state) => state.firmDocument?.firmDocumentList
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllDocuments = async (firmID) => {
    try {
      const response = await api.get(
        `cases/documents/firms/${firmID}?type=firm&&folderId=${state.folderId}`
      );

      dispatch(loadFirmDocumentListAction(response.data.data.allDocuments));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'document.name',
        header: 'Document Name',
      },
      {
        accessorFn: (row) => new Date(row.assignedDate),
        header: 'Assigned date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },

      {
        accessorFn: (row) => new Date(row.updatedAt),
        header: 'Last updated',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  const deleteSingleDoc = async (ID) => {
    try {
      const response = await api.delete(
        `/cases/documents/${ID}/firms/${user.firm}?type=firm`
      );

      dispatch(loadFirmDocumentListAction(response.data.data.allDocuments));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllDocuments(user.firm);
  }, [user.firm]);

  const getEditData = async (ID) => {
    let editData = firmDocuments?.find((d) => d._id === ID);

    setEditData(editData);
  };

  return (
    <>
      <Box sx={{ marginTop: 4 }}>
        <UploadFirmDocument
          folder={state?.folderId}
          open={addDocument}
          onClose={() => setShowAddDocument(false)}
        />
        {editMode && (
          <EditDocument
            isFirmEditing={true}
            editData={editData}
            open={editMode}
            onClose={() => setEditMode(false)}
          />
        )}
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columns}
          data={firmDocuments || []}
          editingMode="modal" //default
          enableColumnOrdering
          // enableEditing
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              getEditData(row.original._id);
              setEditMode(true);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          positionActionsColumn="last"
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                getEditData(row.original._id);
                setEditMode(true);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={3}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteSingleDoc(row.original._id);
                closeMenu();
              }}
            >
              <Delete />
              <Typography ml={3}>Delete</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                window.open(
                  row.original?.document?.Location,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
            >
              <Download />
              <Typography ml={3}>Download</Typography>
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={() => (
            <Box>
              <Box>
                {' '}
                <ArrowBack
                  sx={{ cursor: 'pointer', ml: 1, mt: 1 }}
                  onClick={() => navigate(-1)}
                />
              </Box>
              <Box>
                <Button
                  onClick={() => setShowAddDocument(true)}
                  variant="contained"
                >
                  Add document
                </Button>
              </Box>
            </Box>
          )}
        />
      </Box>
    </>
  );
};

export default FirmDocumentsTable;
