import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import {
  UploadFile,
  Close,
  ContentCopy,
  PostAdd,
  Inbox,
} from '@mui/icons-material';

import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BalanceIcon from '@mui/icons-material/Balance';
import Loader from '../loader/Loader';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import axios from 'axios';
import { loadCaseListAction } from '../../redux/cases/caseActions';
import CaseDetails from '../case/case-details/CaseDetails';
import dayjs from 'dayjs';
import { loadDocumentListAction } from '../../redux/document/documentAction';
import { loadFirmFolderListAction } from '../../redux/firmFolder/firmFolderAction';
import { loadFirmDocumentListAction } from '../../redux/firmDocuments/firmDocumentAction';

const UploadFirmDocument = ({ folder, open, onClose }) => {
  const [value, setValue] = useState(0);
  const [caseFolder, setCaseFolder] = useState([]);
  const [firmMembers, setFirmMembers] = useState([]);
  const [checked, setChecked] = useState([1]);
  const [progress, setProgress] = useState(false);
  const [folderName, setFolderName] = useState('');

  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();

  const documentValidationSchema = yup.object().shape({
    docName: yup.string('Enter doc name').required('Required'),

    ...(caseFolder?.length > 0 && {
      folder: yup.string().required('Required'),
    }),
    document: yup.array().min(1).required('Required'),
  });

  const initialValues = {
    folder: folder ? folder : '',
    docName: '',
    assignedDate: '',
    tag: '',
    tags: [],
    description: '',
    document: [],
  };
  const onSubmit = async (values, actions) => {
    const formData = {
      createNewFolder: caseFolder.length > 0 ? false : true,
      folder: caseFolder.length > 0 ? values.folder : folderName,
      docName: values.docName,
      assignedDate: values.assignedDate,
      tags: values.tags,
      description: values.description,
      uploadedBy: user._id,
      firmUserAccess: checked.slice(1),
      type: 'firm',
      document: values.document,
    };

    async function apiCall() {
      try {
        let response = await api.post(
          `cases/firmDocuments/single/firms/${user.firm}`,
          formData
        );

        setProgress(false);
        dispatch(loadFirmFolderListAction(response.data.data.folders));
        dispatch(loadFirmDocumentListAction(response.data.data.allDocuments));
        dispatch(showSnackbarAction(response.data.message, 'success'));
        formik.resetForm({});
        onClose();
      } catch (err) {
        setProgress(false);
        dispatch(showSnackbarAction(err.response.data.message, 'error'));
      }
    }
    if (formik.values.document?.length > 0) {
      let newFiles = [];
      formik.values.document?.map((file) =>
        file.key ? null : newFiles.push(file)
      );
      if (newFiles.length > 0) {
        const fileDataObj = new FormData();
        newFiles.forEach((file) => fileDataObj.append('file', file));

        if (fileDataObj.has('file')) {
          try {
            const fileResponse = await axios.post(
              'https://file.starlawyer.in/api/afsgdfgsdf56754hgfjhgj',
              fileDataObj
            );
            if (fileResponse) {
              formData.document = fileResponse.data.data;
              formData.document[0].name = formik.values.document[0]?.name;
            }
          } catch (err) {
            setProgress(false);
            dispatch(showSnackbarAction(err.response.data.message, 'error'));
            return;
          }
        }
      }
      await apiCall();
    } else {
      await apiCall();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: documentValidationSchema,
    enableReinitialize: true,
  });

  const addTags = () => {
    if (!formik.values.tag?.length > 0) {
      formik.setFieldError('tag', 'Required');
      formik.setFieldTouched('tag', true, true);
      return;
    }
    const match = formik.values.tags.includes(formik.values.tag);

    if ((formik.values.tag?.length > 0) & !match) {
      formik.setFieldValue('tags', [...formik.values.tags, formik.values.tag]);
      formik.setFieldValue('tag', '');
      formik.setFieldValue('openTagsModal', false);
    }
  };

  const myDropZone = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      formik.setFieldValue('document', acceptedFiles);
    },
  });

  const { getRootProps, getInputProps } = myDropZone;

  const getFolders = async (id) => {
    try {
      const resp = await api.get(`cases/firms/${user.firm}/firmFolders`);

      if (resp?.data?.data?.folders?.length === 0) {
        setFolderName(firmMembers[0]?.firm?.name);
        setCaseFolder([]);
      } else {
        setFolderName('');
        setCaseFolder(resp.data.data.folders);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getFirmMembers = async (id) => {
    try {
      const resp = await api.get(`/attornies/firmMembers/${id}`);
      if (resp.data.data.users?.length > 0) {
        let data = resp.data.data.users.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            firm: user.firm,
            value: user._id,
          };
        });

        setFirmMembers(data);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getFirmMembers(user.firm);
    getFolders(user.firm);
    setChecked([1, user._id]);
  }, [user.firm]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    // if (value?.value === 'all') {
    //   let arr = [1, ...clients];
    //   if (checked.length === clients.length + 1) {
    //     console.log('runn');
    //     setChecked([1]);
    //   } else {
    //     setChecked(arr);
    //   }
    // } else {

    // }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        fullWidth
        sx={{ padding: 4 }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            borderBottom: '1px dotted #d3c3c4!important',
            bgcolor: '#f5f5f5',
            py: 5,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={9}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h2">{'Upload Firm Document'}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h2">{'Sharing'}</Typography>
                <Typography
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid
                item
                sx={{ borderRight: '1px dotted grey' }}
                xs={12}
                sm={9}
                pt={6}
              >
                <Grid container>
                  <Grid item xs={12} md={3} alignSelf="center">
                    {!folder && (
                      <Typography variant="h4">Folder Name*</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ bgcolor: 'gray' }}>
                    {folderName ||
                      (caseFolder?.length > 0 && !folder && (
                        <Typography variant="h4" sx={{ color: '#21766d' }}>
                          {folderName ? (
                            folderName
                          ) : (
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Select Folder
                              </InputLabel>
                              <Select
                                labelId="folder"
                                id="folder"
                                name="folder"
                                options={caseFolder || []}
                                value={formik.values?.folder}
                                label="Folder"
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.folder &&
                                  Boolean(formik.errors.folder)
                                }
                                helperText={
                                  formik.touched.folder && formik.errors.folder
                                }
                              >
                                {caseFolder.map((folder) => {
                                  return (
                                    <MenuItem
                                      value={
                                        folder._id === undefined ||
                                        folder._id === null ||
                                        caseFolder.length === 0
                                          ? ''
                                          : folder._id
                                      }
                                    >
                                      {folder.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {formik.touched.folder && formik.errors.folder ? (
                                <FormHelperText
                                  sx={{
                                    color: '#bf3333',
                                    marginLeft: '16px !important',
                                  }}
                                >
                                  {formik.touched.folder &&
                                    formik.errors.folder}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        </Typography>
                      ))}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Doc Name*</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      id="docName"
                      name="docName"
                      label="Doc Name"
                      variant="outlined"
                      sx={{ bgcolor: 'gray' }}
                      value={formik.values.docName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.docName && Boolean(formik.errors.docName)
                      }
                      helperText={
                        formik.touched.docName && formik.errors.docName
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Assigned Date</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="assignedDate"
                        name="assignedDate"
                        onChange={(value) =>
                          formik.setFieldValue('assignedDate', value)
                        }
                        value={dayjs(formik.values.assignedDate)}
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Source*</Typography>
                  </Grid>
                  <Grid item sm={8}>
                    {formik.values.document?.length > 0 ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            height: 100,
                            border: '1px dashed grey',
                            borderRadius: 5,
                            px: 5,
                            bgcolor: '#f5f5f5',
                          }}
                        >
                          <Box>
                            <Typography variant="h4" mr={3}>
                              {formik.values.document[0]?.name || 'document'}
                            </Typography>
                          </Box>
                          <i
                            onClick={() => {
                              if (formik.values.document[0]?.key) {
                                formik.setFieldValue(
                                  'deteledCertificate',
                                  formik.values.document[0]?.key
                                );
                              }
                              formik.setFieldValue('document', []);
                            }}
                          >
                            <CloseIcon />
                          </i>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            height: 100,
                            border:
                              formik.touched.document && formik.errors.document
                                ? '1px dashed red'
                                : '1px dashed grey',
                            borderRadius: 5,
                            px: 5,
                            bgcolor: '#f5f5f5',
                          }}
                        >
                          <div {...getRootProps()}>
                            <div>
                              <Typography
                                sx={{ cursor: 'pointer' }}
                                variant="h5"
                              >
                                Click & browse to upload document.
                              </Typography>
                            </div>
                            <input {...getInputProps()} />
                          </div>
                        </Box>
                        <Box>
                          {formik.touched.document && formik.errors.document ? (
                            <FormHelperText
                              sx={{
                                color: '#bf3333',
                                marginLeft: '16px !important',
                              }}
                            >
                              {formik.touched.document &&
                                formik.errors.document}
                            </FormHelperText>
                          ) : null}
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4"></Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {formik.values?.tags.length > 0 &&
                      formik.values.tags?.map((tg) => (
                        <List orientation="horizontal" variant="outlined">
                          <ListItem sx={{ mb: -3 }} disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                <CloseIcon
                                  onClick={(e) => {
                                    formik.setFieldValue(
                                      'tags',
                                      formik.values.tags.filter((e) => e !== tg)
                                    );
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={tg} />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      ))}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Tags</Typography>
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <TextField
                      fullWidth
                      id="tag"
                      name="tag"
                      value={formik.values.tag}
                      onChange={formik.handleChange}
                      variant="outlined"
                      error={formik.touched.tag && Boolean(formik.errors.tag)}
                      helperText={formik.touched.tag && formik.errors.tag}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} alignSelf="center">
                    <Button
                      color="#00695c"
                      variant="h4"
                      onClick={() => addTags()}
                    >
                      Add Tags
                    </Button>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Description</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        variant="outlined"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        multiline
                        rows={4}
                      />
                      <Typography mt={4}>
                        This description will be viewable by anyone this
                        document is shared with.
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} sx={{ p: 2 }}>
                {firmMembers?.length > 0 && (
                  <>
                    <List
                      dense
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                      }}
                    >
                      {firmMembers?.map((value) => {
                        const labelId = `checkbox-list-secondary-label-${value._id}`;
                        return (
                          <>
                            <ListItem
                              key={value.value}
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  onChange={handleToggle(value.value)}
                                  checked={checked.indexOf(value.value) !== -1}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              }
                              disablePadding
                            >
                              <ListItemIcon>
                                <AccountCircleIcon />
                              </ListItemIcon>
                              <ListItemButton>
                                <ListItemText
                                  primaryTypographyProps={{
                                    fontSize: '17px',
                                    p: 1,
                                  }}
                                  id={labelId}
                                  primary={value.label}
                                />
                              </ListItemButton>
                            </ListItem>
                            <Box
                              sx={{
                                borderBottom: '1px dotted #d3c3c4!important',
                              }}
                            ></Box>
                          </>
                        );
                      })}
                    </List>
                  </>
                )}
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: {
                  xs: 'center',
                  md: 'end',
                },
                pt: 8,
                pr: 10,
                borderTop: '1px dotted #d3c3c4!important',
              }}
            >
              <>
                {!progress ? (
                  <Button
                    sx={{
                      width: 220,
                      height: 40,
                      borderRadius: '30px',
                      color: 'white',
                      bgcolor: 'primary.main',
                      ':hover': {
                        color: 'white',
                        bgcolor: 'primary.main',
                      },
                    }}
                    type="submit"
                  >
                    Upload New Document
                  </Button>
                ) : (
                  <Box sx={{ width: '20%' }}>
                    <LinearProgress sx={{ height: 30 }} />
                  </Box>
                )}
              </>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadFirmDocument;
