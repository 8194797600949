import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import api from '../../../utility/api';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import Staff from '../Staff';
import { useFormik } from 'formik';
import * as yup from 'yup';

const staffValidation = yup.object().shape({
  leadAttornies: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),
  originatingAttornies: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),
});

const CaseStaff = ({ caseInformation }) => {
  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();

  const [caseData, setCaseData] = useState({});

  const getCaseDetails = async (id) => {
    try {
      const response = await api.get(`/cases/${id}`);

      if (response) {
        setCaseData(response.data.data.case);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      caseId: caseData._id,
      originatingAttornies: values.originatingAttornies.value,
      leadAttornies: values.leadAttornies.value,
      firmId: user.firm,
      firmMembers: values.firmMembers,
    };

    try {
      const response = await api.post(`cases/staff`, formData);
      dispatch(showSnackbarAction(response.data.message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  console.log({ caseData });
  const initialValues = {
    leadAttornies:
      caseData?.leadAttornies?.length > 0
        ? {
            label: `${caseData?.leadAttornies[0].firstName} ${caseData?.leadAttornies[0].lastName}`,
            value: caseData?.leadAttornies[0]._id,
          }
        : null,
    originatingAttornies:
      caseData?.originatingAttornies?.length > 0
        ? {
            label: `${caseData?.originatingAttornies[0].firstName} ${caseData?.originatingAttornies[0].lastName}`,
            value: caseData?.originatingAttornies[0]._id,
          }
        : null,
    firmMembers:
      caseData?.firmMembers?.length > 0
        ? caseData?.firmMembers?.map((val) => {
            let obj = {
              label: `${val.firstName} ${val.lastName}`,
              value: val._id,
            };
            return obj;
          })
        : [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: staffValidation,
    enableReinitialize: true,
  });

  useEffect(() => {
    getCaseDetails(caseInformation?._id);
  }, [user.firm]);

  return (
    <>
      <Box>
        <Paper sx={{ p: 4 }}>
          <Box sx={{ mb: 14 }}>
            <form onSubmit={formik.handleSubmit}>
              <Staff formik={formik} />
              <Button
                sx={{ float: 'right', mt: 3 }}
                size="large"
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </form>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default CaseStaff;
