import { GET_ALL_CASE, ADD_CASE } from "./caseTypes";

export const loadCaseListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CASE,
    payload: data,
  });
};

export const loadAddCaseAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_CASE,
    payload: data,
  });
};
