import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import Navbar from './components/navbar/Navbar';
import DocumentLayoutPage from './pages/documents-home/DocumentsLayout-page';
import HomePage from './pages/home-page/Home-page';
import SignupPage from './pages/Signup/Signup-pages';
import SignupPasswordPage from './pages/Signup/SignUpPassword-page';
import LoginPage from './pages/login/Login';
import InviteFirmMembers from './components/add-firm-members/InviteFirmMembers';
import BillingPage from './pages/billing-page/BillingPage';
import CasePage from './pages/case-page/CasePage';
import ResetPasswordPage from './pages/resetPassword-page/ResetPasswordPage';
import ForgotPasswordPage from './pages/forgot-password-page/ForgotPasswordPage';
import LandingPage from './pages/landing-page/LandingPage';
import TaskPage from './pages/task-page/TaskPage';
import AccountPage from './pages/account/Account-page';
import LeadPage from './pages/lead-page/Lead-page';
import PrivateRoute from './components/routing/PrivateRoute';
import { SnackbarComp } from './components/snackbar/Snackbar';
import DashboardLayoutPage from './pages/dashboard-page/DashboardPage';
import setAuthToken from './utility/setAuthToken';
import { loadUserAction, loginFailureAction } from './redux/auth/authAction';
import { useDispatch } from 'react-redux';
import FirmMembersPage from './pages/firm-members-page/FirmMembersPage';
import ContactsPage from './pages/contacts/ContactsPage';
import TermsPage from './pages/TermsPage/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import NotesPage from './pages/notes-page/NotesPage';
import CaseDetails from './components/case/case-details/CaseDetails';
import CaseDetailPage from './pages/case-details/CaseDetail';
import DocumentDetailPage from './pages/document-detail/DocumentDetails-page';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    setAuthToken(localStorage.token);
    dispatch(loadUserAction());
  }, []);
  return (
    <PersistGate persistor={persistor}>
      <Fragment>
        <SnackbarComp />
        <InviteFirmMembers />
        <BrowserRouter>
          {/* <Navbar /> */}
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="/reset-password/:secret"
              element={<ResetPasswordPage />}
            />
            <Route
              path="/signup/verification/:secret"
              element={<SignupPasswordPage />}
            />
            <Route path="/signup/terms" element={<TermsPage />} />
            <Route
              path="/signup/privacy-policy"
              element={<PrivacyPolicyPage />}
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <DashboardLayoutPage />
                </PrivateRoute>
              }
            >
              <Route path="/terms" element={<TermsPage />} />

              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/dashboard" element={<HomePage />} />
              <Route path="/documents" element={<DocumentLayoutPage />} />
              <Route
                path="/documents/case/document-details"
                element={<DocumentDetailPage />}
              />
              <Route path="/documents/case" element={<DocumentLayoutPage />} />
              <Route path="/documents/firm" element={<DocumentLayoutPage />} />
              <Route
                path="/documents/firm/doc"
                element={<DocumentLayoutPage />}
              />
              <Route path="/documents/eSign" element={<DocumentLayoutPage />} />
              <Route
                path="/documents/intake"
                element={<DocumentLayoutPage />}
              />
              <Route
                path="/documents/templates"
                element={<DocumentLayoutPage />}
              />
              <Route path="/contacts" element={<ContactsPage />} />
              <Route path="/contacts/people" element={<ContactsPage />} />
              <Route path="/contacts/companies" element={<ContactsPage />} />
              <Route path="/contacts/groups" element={<ContactsPage />} />

              <Route path="/billing" element={<BillingPage />} />
              <Route path="/task" element={<TaskPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/lead" element={<LeadPage />} />
              <Route path="/cases" element={<CasePage />} />
              <Route path="/case-details" element={<CaseDetailPage />} />
              <Route path="/firm-members" element={<FirmMembersPage />} />
              <Route path="/notes" element={<NotesPage />} />

              <Route
                index={true}
                element={<Navigate to="/dashboard" replace />}
              ></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Fragment>
    </PersistGate>
  );
}

export default App;
