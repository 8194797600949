import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Typography, Stack, Paper, Button } from '@mui/material';
import {
  Pageview,
  Description,
  AddTask,
  Leaderboard,
  AccountBox,
  Work,
  TaskAlt,
} from '@mui/icons-material';

import InviteFirmMembers from '../add-firm-members/InviteFirmMembers';
import AddCaseDialog from '../case/CaseDialog';
import CreateNewAccountModal from '../Document-popup/DocumentPopUp';

import { removeContactAction } from '../../redux/contacts/contactsActions';
import theme from '../../utility/theme';
import AddLead from '../lead/AddLead';
import Task from '../task/Task';
import TaskIcon from '@mui/icons-material/Task';

const drawerActiveListBackgroundColor = 'rgba(255,255,255, 0.08)';
export default function ExpandCard({ counts }) {
  const [open, setOpen] = useState(true);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const [createCase, setCreateCaseOpen] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const user = useSelector((state) => state.auth.user.user);

  const dispatch = useDispatch();

  const closeCaseDialog = () => {
    setCreateCaseOpen(false);
  };
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Box
        sx={{
          minWidth: 300,
        }}
      >
        {/* <CardHeader
          onClick={() => setOpen(!open)}
          title={`Welcome to LegalPad, ${user.firstName} `}
          action={
            <IconButton aria-label="expand" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        ></CardHeader> */}
        <Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 3 }}
                // sx={{ mr: 3, ml: 3 }}
              >
                <Card
                  sx={{
                    width: '100%',
                    p: 4,
                    borderRadius: 2,

                    height: 240,
                    '&:hover': {
                      background: 'rgba(55, 65, 81, 0.04)',
                    },
                    // borderTop: "3px solid black",
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontWeight: 900 }}>
                      Add a Document
                    </Typography>
                    <Box sx={{ mt: 5 }}>
                      <Description
                        sx={{ fontSize: 30, color: 'success.main' }}
                      />
                    </Box>

                    <Typography variant="h2" sx={{ mt: 5 }}>
                      {counts?.documents || 0}
                    </Typography>
                    <Button
                      onClick={() => setShowDocument(true)}
                      variant="contained"
                      sx={{ mt: 5 }}
                    >
                      Add Document
                    </Button>
                  </Box>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    p: 4,
                    height: 240,
                    borderRadius: 2,

                    // borderTop: "3px solid black",
                    '&:hover': {
                      background: 'rgba(55, 65, 81, 0.04)',
                    },
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontWeight: 900 }}>Add a Lead</Typography>
                    <Box sx={{ mt: 5 }}>
                      <AccountBox color="warning" sx={{ fontSize: 30 }} />
                    </Box>

                    <Typography variant="h2" sx={{ mt: 5 }}>
                      {counts?.leads || 0}
                    </Typography>
                    <Button
                      onClick={() => setCreateModalOpen(true)}
                      variant="contained"
                      sx={{ mt: 5 }}
                    >
                      Add Lead
                    </Button>
                  </Box>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    borderRadius: 2,

                    p: 4,
                    height: 240,
                    '&:hover': {
                      background: 'rgba(55, 65, 81, 0.04)',
                    },
                    // borderTop: "3px solid black",
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontWeight: 900 }}>Add a Case</Typography>
                    <Box sx={{ mt: 5 }}>
                      <Work color="error" sx={{ fontSize: 30 }} />
                    </Box>

                    <Typography variant="h2" sx={{ mt: 5 }}>
                      {counts?.cases || 0}
                    </Typography>
                    <Button
                      onClick={() => {
                        setCreateCaseOpen(true);
                        dispatch(removeContactAction());
                      }}
                      variant="contained"
                      sx={{ mt: 5 }}
                    >
                      Add Case
                    </Button>
                  </Box>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    p: 4,
                    height: 240,
                    borderRadius: 2,

                    '&:hover': {
                      background: 'rgba(55, 65, 81, 0.04)',
                    },
                    // borderTop: "3px solid black",
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontWeight: 900 }}>Add a Task</Typography>
                    <Box sx={{ mt: 5 }}>
                      <TaskIcon
                        size="large "
                        color="primary"
                        sx={{ fontSize: 30 }}
                      />
                    </Box>

                    <Typography variant="h2" sx={{ mt: 5 }}>
                      {counts?.pendingTasks || 0}
                    </Typography>
                    <Button
                      onClick={() => setCreateTaskModalOpen(true)}
                      variant="contained"
                      sx={{ mt: 5 }}
                    >
                      Add Task
                    </Button>
                  </Box>
                </Card>
              </Stack>
            </Box>
          </Collapse>
        </Box>
        {createCase && (
          <AddCaseDialog open={createCase} onClose={closeCaseDialog} />
        )}
        {createModalOpen && (
          <AddLead
            open={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
          />
        )}
        {showDocument && (
          <CreateNewAccountModal
            open={showDocument}
            onClose={setShowDocument}
          />
        )}
        {createTaskModalOpen && (
          <Task
            showAddTask={createTaskModalOpen}
            onClose={() => setCreateTaskModalOpen(false)}
          />
        )}
      </Box>
    </>
  );
}
