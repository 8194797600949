import { Modal } from '@mui/material';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';

// export default function Loader({ open }) {
//   return (
//     <Modal
//       open={open}
//       sx={{
//         paddingY: '50vh',
//         paddingX: '50vw',
//       }}
//     >
//       <CircularProgress />
//     </Modal>
//   );
// }

import spinner from './spinner.gif';

export default function Loader() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '30%',
        left: '40%',
        width: '200px',
        margin: 'auto',
        display: 'block',
      }}
    >
      <img src={spinner} alt="Loading..." />
    </Box>
  );
}
