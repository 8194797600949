import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import api from '../../utility/api';
import { loadCaseListAction } from '../../redux/cases/caseActions';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import MaterialReactTable from 'material-react-table';
import { Card } from '@mui/material';

function HomeCaseTable() {
  const hospital = useSelector((state) => state.auth.hospital);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const caseList = useSelector((state) => state.case.caseList);

  const user = useSelector((state) => state.auth.user.user);
  const getAllCases = async (firmId) => {
    try {
      const response = await api.get(`/cases/firms/${firmId}`);
      dispatch(loadCaseListAction(response.data.data.case));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCases(user.firm);
  }, [user.firm]);

  let columns = useMemo(
    () => [
      {
        accessorKey: 'caseName',
        header: 'Case Name',
        size: 150,
      },
      {
        accessorKey: 'caseStage',
        header: 'Case Stage',
        size: 150,
      },
      {
        accessorKey: 'caseNumber', //access nested data with dot notation
        header: 'Case Number',
        size: 150,
      },
    ],
    []
  );

  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        padding: 4,
        borderRadius: 2,
      }}
    >
      <Box mb={1}>
        <Button onClick={() => navigate('/cases')}>Cases Table</Button>
      </Box>
      <Box sx={{ mt: 5 }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
            },
          }}
          muiTableHeadCellProps={{
            //easier way to create media queries, no useMediaQuery hook needed.
            sx: {
              fontSize: {
                xs: '10px',
                sm: '11px',
                md: '12px',
                lg: '13px',
                xl: '14px',
              },
            },
          }}
          muiTableContainerProps={{ sx: { maxHeight: '160px' } }}
          columns={columns}
          data={caseList || []}
          muiTablePaperProps={{
            elevation: 0,

            sx: {
              borderRadius: '0',
              border: '1px dashed #e0e0e0',
            },
          }}
          editingMode="modal" //default
          enableColumnOrdering
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              navigate('/case-details', {
                state: { caseId: row.original._id },
              });
            },
            sx: {
              cursor: 'pointer',
            },
          })}
        />
      </Box>
    </Card>
  );
}

export default HomeCaseTable;
