import { GET_ALL_FIRMMEMBER, ADD_FIRMMEMBER } from './FirmMemberTypes';

const INITIAL_STATE = {
  firmMemberList: [],
  addFirmMember: {},
};

const firmMemberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FIRMMEMBER:
      return {
        ...state,
        addFirmMember: action.payload,
      };
    case GET_ALL_FIRMMEMBER:
      return {
        ...state,
        firmMemberList: action.payload,
      };

    default:
      return state;
  }
};

export default firmMemberReducer;
