import { ADD_CASETASK, GET_ALL_CASETASK } from './caseTaskType';

export const loadCaseTaskListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CASETASK,
    payload: data,
  });
};

export const loadCaseAddTaskAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_CASETASK,
    payload: data,
  });
};
