import { GET_ALL_CASENOTE, ADD_CASENOTE } from './caseNoteTypes';

const INITIAL_STATE = {
  caseNoteList: [],
  addCaseNote: {},
};

const caseNoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CASENOTE:
      return {
        ...state,
        addCaseNote: action.payload,
      };
    case GET_ALL_CASENOTE:
      return {
        ...state,
        caseNoteList: action.payload,
      };

    default:
      return state;
  }
};

export default caseNoteReducer;
