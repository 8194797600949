import {
  GET_ALL_CONTACTS,
  REMOVE_CONTACT,
  GET_ALL_CONTACTLIST,
} from './contactsTypes';

export const loadContactListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CONTACTS,
    payload: data,
  });
};

export const loadAllContactListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CONTACTLIST,
    payload: data,
  });
};

export const removeContactAction = () => async (dispatch) => {
  dispatch({
    type: REMOVE_CONTACT,
  });
};
