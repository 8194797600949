import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';

import { Box } from '@mui/system';
import MaterialReactTable from 'material-react-table';
import { Button, MenuItem, Typography } from '@mui/material';

import AddCaseDialog from './CaseDialog';
import CaseDetails from './case-details/CaseDetails';
import EditCase from './EditCase';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import { loadCaseListAction } from '../../redux/cases/caseActions';
import api from '../../utility/api';
import dayjs from 'dayjs';
import { removeContactAction } from '../../redux/contacts/contactsActions';
import Loader from '../loader/Loader';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CasesTable = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState('');
  const [editID, setEditID] = useState(null);
  const [open, setOpen] = useState(false);
  const [caseDetails, setCaseDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const caseList = useSelector((state) => state.case.caseList);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllCases = async (firmId) => {
    try {
      const response = await api.get(`/cases/firms/${firmId}`);
      dispatch(loadCaseListAction(response.data.data.case));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const filterEditData = (id) => {
    let editCase = caseList.find((item) => item._id === id);
    setEditData(editCase);
  };
  const closeCaseDialog = () => {
    setCreateModalOpen(false);
  };

  const deleteCase = async (ID) => {
    try {
      const response = await api.delete(`cases/${ID}/firms/${user.firm}`);
      dispatch(loadCaseListAction(response.data.data.cases));
      dispatch(showSnackbarAction(response.data.message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getCaseDetails = async (id) => {
    try {
      const response = await api.get(`/cases/${id}`);

      if (response) {
        setCaseDetails(response.data.data.case);
        setOpen(true);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let columns = useMemo(
    () => [
      {
        accessorKey: 'caseName',
        header: 'Case Name',
      },
      {
        accessorKey: 'caseStage',
        header: 'Case Stage',
      },
      {
        accessorKey: 'caseNumber', //access nested data with dot notation
        header: 'Case Number',
      },
      {
        accessorFn: (row) => new Date(row.dateOpened),
        header: 'Date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      // {
      //   accessorKey: "sharedWith",
      //   header: "Shared with",
      // },
    ],
    []
  );

  useEffect(() => {
    getAllCases(user.firm);
  }, [user.firm]);

  return (
    <>
      <Box>
        <Typography variant="h2" mb={3}>
          Case Detail
        </Typography>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columns}
          data={caseList || []}
          editingMode="modal" //default
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              navigate('/case-details', {
                state: { caseId: row.original._id },
              });
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          enableColumnFilterModes
          positionActionsColumn="last"
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                filterEditData(row.original._id);
                setShowEdit(true);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={2}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteCase(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={2}>Delete</Typography>
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={() => (
            <Button
              onClick={() => {
                setCreateModalOpen(true);
                dispatch(removeContactAction());
              }}
              variant="contained"
            >
              Add Case
            </Button>
          )}
        />
        {createModalOpen && (
          <AddCaseDialog
            open={createModalOpen}
            onClose={() => closeCaseDialog()}
          />
        )}
      </Box>

      {showEdit && (
        // <EditCase
        //   showEdit={showEdit}
        //   setShowEdit={setShowEdit}
        //   editData={editData}
        // />
        <AddCaseDialog
          editData={editData}
          open={showEdit}
          onClose={() => setShowEdit(false)}
        />
      )}
    </>
  );
};

export default CasesTable;
