import { GET_ALL_COMPANY } from "./companyTypes";

const INITIAL_STATE = {
  companyList: [],
};

const compnayReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COMPANY:
      return {
        companyList: action.payload,
      };

    default:
      return state;
  }
};

export default compnayReducer;
