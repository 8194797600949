import { Container } from '@mui/material';
import FirmMembers from '../../components/add-firm-members/FirmMembers';
import { Box } from '@mui/system';

const FirmMembersPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <FirmMembers />
      </Box>
    </Container>
  );
};

export default FirmMembersPage;
