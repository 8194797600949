import { GET_ALL_CASEFOLDER, ADD_CASEFOLDER } from './caseFolderType';

const INITIAL_STATE = {
  caseFolderList: [],
  addCaseFolder: {},
};

const caseFolderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CASEFOLDER:
      return {
        ...state,
        addCaseFolder: action.payload,
      };
    case GET_ALL_CASEFOLDER:
      return {
        ...state,
        caseFolderList: action.payload,
      };

    default:
      return state;
  }
};

export default caseFolderReducer;
