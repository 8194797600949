import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Stepper,
  Typography,
  Step,
  StepLabel,
  Button,
  Paper,
  Stack,
} from '@mui/material';

import { Celebration, Close } from '@mui/icons-material';

import ClientsAndContacts from './ClientsAndContacts';
import AddCase from './AddCase';
import Staff from './Staff';

import theme from '../../utility/theme';
import {
  loadAddCaseAction,
  loadCaseListAction,
} from '../../redux/cases/caseActions';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { removeContactAction } from '../../redux/contacts/contactsActions';
import api from '../../utility/api';
import dayjs from 'dayjs';

const contactValidation = yup.object().shape({
  clientOrCompany: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .min(1, 'Client or Company required.'),
});
const addCaseValidation = yup.object().shape({
  caseName: yup.string('Enter Case Name').trim().required('Required'),
});
const staffValidation = yup.object().shape({
  leadAttornies: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),
  originatingAttornies: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),
});

const steps = ['Clients & Contacts', 'Case Details', 'Staff'];

export default function AddCaseDialog({ open, onClose, editData }) {
  const [activeStep, setActiveStep] = useState(editData ? 1 : 0);
  const [showPracticeArea, setShowPracticeArea] = useState(false);
  const [showBack, setShowBack] = useState(true);
  const user = useSelector((state) => state.auth.user.user);
  const contactData = useSelector((state) => state?.contact?.contactList);
  const caselist = useSelector((state) => state?.case?.addCase);

  const dispatch = useDispatch();

  const handleBack = (previousStep) => {
    setActiveStep(previousStep - 1);
  };

  const initialValues = {
    clientOrCompany: [],
    firmMembers: editData ? editData?.firmMembers : [],
    createPracticeArea: showPracticeArea,
    caseName: editData ? editData?.caseName : '',
    caseNumber: editData ? editData?.caseNumber : '',
    practiceArea: editData ? editData?.practiceArea : '',
    practiceAreaInput: '',
    caseStage: editData ? editData?.caseStage : '',
    dateOpened: editData ? editData?.dateOpened : '',
    statuteOfLimitation: editData ? editData.statuteOfLimitation : '',
    office: editData ? editData.office : '',
    description: editData ? editData.description : '',
    conflictCheck: editData ? editData.conflictCheck : false,
    conflictCheckNote: editData ? editData.conflictCheckNote : '',
    clientIds: '',
    companyIds: '',
    attorneyId: '',
    firmId: '',
    caseId: '',
    leadAttornies:
      editData?.leadAttornies?.length > 0
        ? {
            label: `${editData?.leadAttornies[0].firstName} ${editData?.leadAttornies[0].lastName}`,
            value: editData?.leadAttornies[0]._id,
          }
        : null,
    originatingAttornies:
      editData?.originatingAttornies?.length > 0
        ? {
            label: `${editData?.originatingAttornies[0].firstName} ${editData?.originatingAttornies[0].lastName}`,
            value: editData?.originatingAttornies[0]._id,
          }
        : null,
    firmMembers:
      editData?.firmMembers?.length > 0
        ? editData?.firmMembers?.map((val) => {
            let obj = {
              label: `${val.firstName} ${val.lastName}`,
              value: val._id,
            };
            return obj;
          })
        : [],
  };

  const onSubmit = async (values, actions) => {
    let clientID = [];
    let companyID = [];

    values?.clientOrCompany.forEach((element) => {
      if (element.type === 'client') {
        clientID.push(element.value);
      } else {
        companyID.push(element.value);
      }
    });

    if (activeStep === 0) {
      if (clientID?.length > 0 || companyID?.length > 0) {
        setActiveStep(activeStep + 1);
      } else {
        dispatch(
          showSnackbarAction(
            'Please select Company/Client or Add Contact',
            'error'
          )
        );
      }
    }

    if (activeStep === 1) {
      let formData = {
        createPracticeArea: formik.values.practiceAreaInput ? true : false,
        caseName: values.caseName,
        caseNumber: values.caseNumber,
        practiceArea: !formik.values.practiceAreaInput
          ? values.practiceArea?._id
          : values.practiceAreaInput,
        caseStage: values.caseStage,
        dateOpened: values.dateOpened,
        office: values.office,
        description: values.description,
        statuteOfLimitation: values.statuteOfLimitation,
        conflictCheck: values.conflictCheck,
        conflictCheckNote: values.conflictCheckNote,
        clientIds:
          editData && editData?.clients?.length > 0
            ? editData?.clients
            : clientID,
        companyIds:
          editData && editData?.companies?.length > 0
            ? editData?.companies
            : companyID,
        attorneyId: user._id,
        firmId: user.firm,
        caseId: caselist?.savedCase?._id || '',
        editMode: false,
      };

      if (clientID.length === 0) {
        delete formData.clientIds;
      }

      if (companyID.length === 0) {
        delete formData.companyIds;
      }

      if (editData) {
        formData.editMode = true;
      }

      try {
        const response = await api.post(`/cases`, formData);

        if (formData?.createPracticeArea) {
          formik.setFieldValue(
            'practiceArea',
            response.data.data.caseData.practiceArea
          );
        }
        setShowBack(true);
        dispatch(loadAddCaseAction(response.data.data));
        dispatch(loadCaseListAction(response.data.data.cases));
        setActiveStep(activeStep + 1);
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
    if (activeStep === 2) {
      const formData = {
        caseId: caselist.savedCase._id,
        originatingAttornies: values.originatingAttornies.value,
        leadAttornies: values.leadAttornies.value,
        firmId: user.firm,
        firmMembers: values.firmMembers,
      };

      try {
        const response = await api.post(`cases/staff`, formData);
        dispatch(loadAddCaseAction({}));
        dispatch(loadCaseListAction(response.data.data.cases));
        dispatch(removeContactAction());
        setActiveStep(activeStep + 1);
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
  };

  const validationArray = [
    contactValidation,
    addCaseValidation,
    staffValidation,
  ];

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationArray[activeStep],
    enableReinitialize: true,
  });

  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <ClientsAndContacts formik={formik} />;
      case 1:
        return (
          <AddCase
            formik={formik}
            showPracticeArea={showPracticeArea}
            setShowPracticeArea={setShowPracticeArea}
          />
        );
      case 2:
        return <Staff formik={formik} />;

      default:
        return <div>Page Not Found</div>;
    }
  }

  useEffect(() => {
    if (editData) {
      dispatch(
        loadAddCaseAction({
          savedCase: {
            _id: editData?._id,
          },
        })
      );
      setShowBack(false);
    } else {
      dispatch(loadAddCaseAction({}));
    }
  }, [user.firm]);
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      //   PaperProps={{ sx: { height: '100%' } }}
      sx={{ padding: 6 }}
      open={open}
      onClose={() => onClose()}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: theme.typography.h3 }}>
          {editData ? 'Update Case' : 'Add Case'}
        </DialogTitle>
        <DialogTitle>
          <Close onClick={() => onClose()} />
        </DialogTitle>
      </Box>
      <DialogContent>
        {activeStep === steps.length ? (
          <Box
            sx={{
              width: 600,
              paddingY: 20,
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <Celebration
              fontSize="large"
              color="secondary"
              sx={{
                fontSize: 56,
              }}
            />
            <Typography my={1} variant="h4">
              {`Case ${editData ? 'Updated' : 'Created'} Successfully.`}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>
                      <Typography variant="h2">{label}</Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <form onSubmit={formik.handleSubmit}>
              {renderPageContent(activeStep)}

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 3 }}
                sx={{
                  justifyContent: 'end',
                  mt: 20,
                  mb: 4,
                }}
              >
                {showBack && (
                  <Button
                    size="large"
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={() => handleBack(activeStep)}
                    sx={{ mr: 1, width: 250 }}
                  >
                    Back
                  </Button>
                )}

                <Button
                  // fullWidth={activeStep > 0 && !editData}
                  sx={{
                    width: 250,
                  }}
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  {(activeStep === 1 && 'continue to staff') ||
                    (activeStep === 0 && 'Continue to case details') ||
                    (activeStep === 2 && 'save & Finish')}
                </Button>
              </Stack>
            </form>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
