import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MaterialReactTable from 'material-react-table';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../utility/api';
import AddCaseNote from './AddCaseNote';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import { loadCaseNoteListAction } from '../../../redux/caseNote/caseNoteAction';
import { Edit } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CaseNotes = ({ caseInformation }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [caseData, setCaseData] = useState({});
  const user = useSelector((state) => state.auth.user.user);

  const notes = useSelector((state) => state?.caseNote?.caseNoteList);

  const dispatch = useDispatch();

  const getCaseDetails = async (id) => {
    try {
      const response = await api.get(`/cases/${id}`);

      if (response) {
        setCaseData(response.data.data.case);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getAllNotes = async (firmId, caseId) => {
    try {
      const response = await api.get(
        `/notes/firms/${firmId}?caseId=${caseId}&&type=case`
      );

      if (response) {
        dispatch(loadCaseNoteListAction(response.data.data.notes));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const deleteNote = async (noteID, caseId) => {
    try {
      const response = await api.delete(
        `/notes/firms/${user.firm}/notes/${noteID}?caseId=${caseId}&&type=case`
      );
      if (response) {
        dispatch(loadCaseNoteListAction(response.data.data.notes));
        dispatch(showSnackbarAction('Note Deleted Successfully', 'success'));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let data = [];

  useEffect(() => {
    if (caseInformation?._id) {
      getAllNotes(user.firm, caseInformation?._id);
      getCaseDetails(caseInformation?._id);
    }
  }, []);

  let columns = useMemo(
    () => [
      {
        accessorKey: 'case.caseName',
        header: 'Case Name',
      },
      {
        accessorKey: 'subject',
        header: 'Subject',
      },

      {
        accessorFn: (row) => new Date(row.date),
        header: 'Assigned date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  const getEditData = async (ID) => {
    let editData = notes?.find((d) => d._id === ID);

    setEditData(editData);
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={notes || []}
        enableColumnOrdering
        enableRowActions
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            cursor: 'pointer',
          },
        })}
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key="edit"
            onClick={() => {
              getEditData(row.original._id);
              setEditMode(true);
              closeMenu();
            }}
          >
            <Edit />
            <Typography ml={3}>Edit</Typography>
          </MenuItem>,
          <MenuItem
            key="delete"
            onClick={() => {
              deleteNote(row.original._id, row.original?.case._id);
              closeMenu();
            }}
          >
            <DeleteIcon />
            <Typography ml={2}>Delete</Typography>
          </MenuItem>,
        ]}
        renderDetailPanel={({ row }) => (
          <>
            <Box
              sx={{
                width: '50%',
              }}
            >
              <Typography variant="h4">{row.original?.note}</Typography>
            </Box>
          </>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button variant="contained" onClick={() => setShowAdd(true)}>
              Add
            </Button>
          </>
        )}
      />
      <AddCaseNote
        open={showAdd}
        caseDetails={caseData}
        onClose={() => setShowAdd(false)}
      />

      {editMode && (
        <AddCaseNote
          open={editMode}
          caseDetails={caseData}
          onClose={() => setEditMode(false)}
          editData={editData}
          showEdit={editMode}
        />
      )}

      {/* {showAdd && <AddNote open={showAdd} setShowNote={setShowAdd} />} */}
    </Box>
  );
};

export default CaseNotes;
