import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import AddCompany from './AddCompany';
import AddPerson from './AddPerson';
import AddNewContact from '../case/AddNewContact';

const AddContact = ({ open, setShowContact }) => {
  const [showCompany, setShowCompany] = useState(false);
  const [showPerson, setShowPerson] = useState(false);

  return (
    <Dialog open={open} onClose={() => setShowContact(false)} maxWidth="lg">
      <DialogTitle
        sx={{
          paddingY: 6,
          bgcolor: 'lightgrey',
          borderBottom: '1px solid grey',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h1">Add a Person or a Company</Typography>
          <CloseIcon onClick={() => setShowContact(false)} />
        </Box>
      </DialogTitle>

      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: {
              xs: 'center',
              md: 'end',
            },
            pt: 6,
            pr: 10,
          }}
        >
          <Button
            sx={{
              width: 140,
              height: 50,
              borderRadius: '30px',
              border: '2px solid grey',
              mr: 2,
              mb: {
                xs: 4,
              },
              color: 'grey',
            }}
            onClick={() => setShowContact(false)}
          >
            <Typography variant="h5" mr={1}>
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              width: 140,
              height: 50,
              borderRadius: '30px',
              border: '2px solid grey',
              mr: 2,
              mb: {
                xs: 4,
              },
              color: 'grey',
            }}
            onClick={() => setShowCompany(true)}
          >
            <Typography variant="h5" mr={1}>
              Add Company
            </Typography>
          </Button>
          <Button
            sx={{
              width: 150,
              height: 50,
              borderRadius: '30px',
              color: 'white',
              bgcolor: 'primary.main',
              ':hover': {
                color: 'white',
                bgcolor: 'primary.main',
              },
            }}
            onClick={() => setShowPerson(true)}
          >
            Add Person
          </Button>
        </Box>
      </DialogActions>
      <AddCompany open={showCompany} setShowNote={setShowCompany} />
      <AddNewContact
        open={showPerson}
        onClose={() => setShowPerson(!showPerson)}
      />
    </Dialog>
  );
};

export default AddContact;
