import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BalanceIcon from '@mui/icons-material/Balance';
import PeopleIcon from '@mui/icons-material/People';

const SharingDialog = ({ open, onClose, clients, caseData, props, index }) => {
  const [checked, setChecked] = useState([1]);

  console.log(props.values.documentDetails);
  const handleToggle = (value, index) => () => {
    const currentIndex =
      props.values.documentDetails[index]?.clients?.indexOf(value);

    const newChecked = [...props.values.documentDetails[index]?.clients];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // setChecked(newChecked);
    props.values.documentDetails[index].clients = newChecked;
    props.setFieldValue('documentDetails', props.values.documentDetails);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      sx={{ padding: 4 }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          borderBottom: '1px dotted #d3c3c4!important',
          bgcolor: '#f5f5f5',
          py: 5,
        }}
      >
        <Grid container sx={{ minWidth: '400px' }}>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h2">Document Sharing</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ float: 'right' }}>
              <Typography
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={3} spacing={4} sx={{ p: 2 }}>
          {clients?.length > 0 ? (
            <>
              {/* <Box display="flex" justifyContent="space-between" mb={6}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <PeopleIcon />
                    </Typography>
                    <Typography variant="h4" ml={4}>
                      Select All
                    </Typography>
                  </Box>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked />} />
                  </FormGroup>
                </Box> */}
              {/* <Box display="flex" justifyContent="space-between" mb={6}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <AccountCircleIcon />
                    </Typography>
                    <Typography variant="h4" ml={4}>
                      [SAMPLE]Vinay Patil
                    </Typography>
                  </Box>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked />} />
                  </FormGroup>
                </Box> */}
              <List
                dense
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
                {clients.map((value) => {
                  const labelId = `checkbox-list-secondary-label-${value._id}`;
                  return (
                    <>
                      <ListItem
                        key={value._id}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={handleToggle(value, index)}
                            checked={
                              props.values?.documentDetails[
                                index
                              ].clients?.indexOf(value) !== -1
                            }
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        }
                        disablePadding
                      >
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemButton>
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: '17px',
                              p: 1,
                            }}
                            id={labelId}
                            primary={`${value.firstName} ${value.lastName}`}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Box
                        sx={{ borderBottom: '1px dotted #d3c3c4!important' }}
                      ></Box>
                    </>
                  );
                })}

                <ListItem disablePadding>
                  <ListItemIcon>
                    <BalanceIcon />
                  </ListItemIcon>
                  <ListItemButton>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: '17px',
                      }}
                      primary={`This item will be automatically shared with all firm users
                    linked to ${caseData?.caseName} .`}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          ) : (
            <Typography variant="h4" ml={4}>
              Select a Case Link to configure sharing
            </Typography>
          )}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mt: 4,
            justifyContent: {
              xs: 'end',
              md: 'end',
              lg: 'end',
            },
            pt: 2,
            pr: 10,
          }}
        >
          <Button
            sx={{
              width: 100,
              height: 40,
              borderRadius: '30px',
              color: 'white',
              bgcolor: 'primary.main',
              ':hover': {
                color: 'white',
                bgcolor: 'primary.main',
              },
            }}
            type="button"
            // type="submit"
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SharingDialog;
