import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Fonts } from '../../utility/theme';
import { loginAction } from '../../redux/auth/authAction';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const validationSchema = yup.object().shape({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .matches(
      passwordRegex,
      'Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required'
    )
    .required('Required'),
});

const SiginCore = () => {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleShowPassword = () => setShowPassword(!showPassword);

  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      email: values.email,
      password: values.password,
    };

    try {
      const response = await api.post(`auth/login`, formData);
      if (response) {
        localStorage.setItem('token', response.data.data.token);
        dispatch(loginAction(response.data.data));
        navigate('/dashboard');
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', mb: 5 }}>
        <form
          style={{ textAlign: 'left' }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Box sx={{ mb: { xs: 5, xl: 8 } }}>
            <TextField
              placeholder="Email"
              name="email"
              label="Email"
              variant="outlined"
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  fontSize: 14,
                },
              }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>

          <Box sx={{ mb: { xs: 3, xl: 4 } }}>
            <TextField
              id="password"
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>

          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
            }}
          >
            <Box
              component="span"
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: Fonts.MEDIUM,
                cursor: 'pointer',
                display: 'block',
                textAlign: 'right',
              }}
              onClick={() => navigate('/forgot-password')}
            >
              Forgot Passsword?
            </Box>
          </Box>

          <Box sx={{ mt: 8 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                minWidth: 160,
                fontWeight: Fonts.REGULAR,
                fontSize: 16,
                textTransform: 'capitalize',
                padding: '4px 16px 8px',
              }}
            >
              Login
            </Button>
          </Box>
        </form>
      </Box>

      <Box
        sx={{
          color: 'grey.500',
          mb: { xs: 5, md: 7 },
        }}
      >
        <Typography variant="span" style={{ marginRight: 4 }}>
          Don't have account?
        </Typography>
        <Box
          component="span"
          sx={{
            fontWeight: Fonts.MEDIUM,
            '& a': {
              color: (theme) => theme.palette.primary.main,
              textDecoration: 'none',
            },
          }}
        >
          <Link to="/signup">Sign up</Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SiginCore;
