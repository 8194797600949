import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import { Celebration, Close, Image, Work } from '@mui/icons-material';
import LuggageIcon from '@mui/icons-material/Luggage';
import ItemsAndInfo from './ItemsAndInfo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CaseStatus from './CaseStatus';
import theme from '../../../utility/theme';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CaseDocuments from './DocumentsTable';
import CaseTaskTable from './CaseTask';
import CaseNotes from './CaseNotes';
import CaseBillingTable from './CaseBillings';
import api from '../../../utility/api';
import { useDispatch, useSelector } from 'react-redux/es';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import CaseFolderTable from './CaseFolders';
import CaseStaff from './CaseStaff';
const drawerActiveListBackgroundColor = 'rgba(255,255,255, 0.08)';

const CaseDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let [caseStep, setCaseStep] = useState(1);
  let [caseDetails, setCaseDetails] = useState({});
  const user = useSelector((state) => state.auth.user.user);
  const { state } = useLocation();
  const dispatch = useDispatch();

  function renderPageContent(render) {
    switch (render) {
      case 1:
        return <ItemsAndInfo caseInformation={caseDetails} />;
      case 2:
        return <CaseDocuments caseInformation={caseDetails} />;
      case 3:
        return <CaseTaskTable caseInformation={caseDetails} />;
      case 4:
        return <CaseNotes caseInformation={caseDetails} />;
      case 5:
        return <CaseBillingTable caseInformation={caseDetails} />;
      case 6:
        return <CaseFolderTable caseInformation={caseDetails} />;
      case 7:
        return <CaseStaff caseInformation={caseDetails} />;

      default:
        return <div>Page Not Found</div>;
    }
  }

  const getCaseDetails = async (id) => {
    try {
      const response = await api.get(`/cases/${id}`);

      if (response) {
        setCaseDetails(response.data.data.case);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const handleClose = async () => {
    const formData = {
      ...caseDetails,
      active: !caseDetails.active,
    };

    try {
      const response = await api.patch(`/cases/${caseDetails._id}`, formData);
      getCaseDetails(caseDetails._id);
      dispatch(showSnackbarAction(response.data.message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getCaseDetails(state.caseId);
  }, [state.caseId]);
  return (
    <>
      <Box>
        <Box sx={{ mb: 7 }} justifyContent="center" alignItems="center">
          <Box sx={{ textAlign: 'center' }}>
            <Work sx={{ mr: 1, fontSize: '28px', color: 'grey' }} />
            <Typography variant="h2">{caseDetails?.caseName}</Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              {`${
                caseDetails?.caseNumber
                  ? caseDetails?.caseNumber
                  : 'Not specified'
              } | Statute of Limitations: ${
                caseDetails?.statuteOfLimitation
                  ? new Date(caseDetails?.statuteOfLimitation).toDateString()
                  : 'Not Specified'
              }`}
            </Typography>

            {caseDetails?.originatingAttornies && (
              <Typography>
                {`Lead Attorney: ${
                  caseDetails?.leadAttornies[0]
                    ? caseDetails?.leadAttornies[0]?.firstName
                    : ''
                } ${
                  caseDetails?.leadAttornies[0]
                    ? caseDetails?.leadAttornies[0]?.lastName
                    : ''
                } `}
              </Typography>
            )}

            <Box>
              <Button onClick={() => navigate('/cases')} variant="text">
                Back to Cases
              </Button>
              <Button
                sx={{
                  ml: 3,
                  color: caseDetails?.active ? '#C9252D' : '#OA8FDC',
                  '&:hover': {
                    bgcolor: '#ffeeed ',
                  },
                }}
                variant="text"
                onClick={() => handleClose()}
              >
                {caseDetails?.active ? 'Close Case' : 'Open Case'}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            borderBottom: '1px dotted  grey',
            p: 4,
          }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            textAlign="center"
            spacing={{ xs: 4, sm: 6 }}
            sx={{
              color: theme.palette.text.secondary,

              cursor: 'pointer',
            }}
          >
            <Typography
              sx={{
                ...(caseStep === 1 && {
                  backgroundColor: drawerActiveListBackgroundColor,
                  color: 'success.main',
                  fontWeight: 'medium',
                  borderBottom: '2px solid #11CA5B',
                }),
                '&:hover': {
                  bgcolor: drawerActiveListBackgroundColor,
                },
              }}
              variant="h4"
              component="h4"
              onClick={() => {
                setCaseStep(1);
              }}
            >
              Item & Info
            </Typography>
            <Typography
              onClick={() => {
                setCaseStep(2);
              }}
              sx={{
                ...(caseStep === 2 && {
                  backgroundColor: drawerActiveListBackgroundColor,
                  color: 'success.main',
                  fontWeight: 'medium',
                  borderBottom: '2px solid #11CA5B',
                }),
                '&:hover': {
                  bgcolor: drawerActiveListBackgroundColor,
                },
              }}
              variant="h4"
              component="h4"
            >
              Documents
            </Typography>
            <Typography
              onClick={() => {
                setCaseStep(6);
              }}
              sx={{
                ...(caseStep === 6 && {
                  backgroundColor: drawerActiveListBackgroundColor,
                  color: 'success.main',
                  fontWeight: 'medium',
                  borderBottom: '2px solid #11CA5B',
                }),
                '&:hover': {
                  bgcolor: drawerActiveListBackgroundColor,
                },
              }}
              variant="h4"
              component="h4"
            >
              Folders
            </Typography>
            <Typography
              onClick={() => {
                setCaseStep(3);
              }}
              sx={{
                ...(caseStep === 3 && {
                  backgroundColor: drawerActiveListBackgroundColor,
                  color: 'success.main',
                  fontWeight: 'medium',
                  borderBottom: '2px solid #11CA5B',
                }),
                '&:hover': {
                  bgcolor: drawerActiveListBackgroundColor,
                },
              }}
              variant="h4"
              component="h4"
            >
              Tasks
            </Typography>
            <Typography
              sx={{
                ...(caseStep === 4 && {
                  backgroundColor: drawerActiveListBackgroundColor,
                  color: 'success.main',
                  fontWeight: 'medium',
                  borderBottom: '2px solid #11CA5B',
                }),
                '&:hover': {
                  bgcolor: drawerActiveListBackgroundColor,
                },
              }}
              variant="h4"
              component="h4"
              onClick={() => {
                setCaseStep(4);
              }}
            >
              Notes
            </Typography>
            <Typography
              onClick={() => {
                setCaseStep(5);
              }}
              sx={{
                ...(caseStep === 5 && {
                  backgroundColor: drawerActiveListBackgroundColor,
                  color: 'success.main',
                  fontWeight: 'medium',
                  borderBottom: '2px solid #11CA5B',
                }),
                '&:hover': {
                  bgcolor: drawerActiveListBackgroundColor,
                },
              }}
              variant="h4"
              component="h4"
            >
              Billings
            </Typography>
            <Typography
              onClick={() => {
                setCaseStep(7);
              }}
              sx={{
                ...(caseStep === 7 && {
                  backgroundColor: drawerActiveListBackgroundColor,
                  color: 'success.main',
                  fontWeight: 'medium',
                  borderBottom: '2px solid #11CA5B',
                }),
                '&:hover': {
                  bgcolor: drawerActiveListBackgroundColor,
                },
              }}
              variant="h4"
              component="h4"
            >
              Manage Members
            </Typography>
          </Stack>
        </Box>
        <Grid container spacing={8} sx={{ px: 12, mt: 1 }}>
          <Grid item md={8} lg={caseStep === 1 ? 9 : 12}>
            {renderPageContent(caseStep)}
          </Grid>

          {caseStep === 1 && (
            <Grid item md={12} lg={3}>
              <CaseStatus caseDetails={caseDetails} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default CaseDetails;
