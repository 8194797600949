import { Box, Container } from '@mui/system';
import Account from '../../components/Account';

const AccountPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <Account />
      </Box>
    </Container>
  );
};

export default AccountPage;
