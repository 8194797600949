import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import MaterialReactTable from 'material-react-table';
import { Box, Button, MenuItem, Typography } from '@mui/material';

import InviteFirmMembers from './InviteFirmMembers';
import Loader from '../loader/Loader';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { loadFirmFolderListAction } from '../../redux/firmFolder/firmFolderAction';
import { loadFirmMemberListAction } from '../../redux/FirmMembers.js/firmMemberAction';

const FirmMembers = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [editData, setEditData] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user.user);
  const firmMembers = useSelector((state) => state.firmMember?.firmMemberList);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getFirmMembers = async () => {
    try {
      const response = await api.get(`/attornies/firmMembers/${user.firm}`);

      dispatch(loadFirmMemberListAction(response.data.data.users));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const sendLinkHandler = async (id) => {
    try {
      const response = await api.get(
        `/attornies/${id}/firms/${user.firm}/verifyLink`
      );
      dispatch(showSnackbarAction(response.data.message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getEditData = (id) => {
    let data = firmMembers?.find((item) => item._id === id);
    setEditData(data);
    setIsLoading(false);
  };

  const deleteFirmMember = async (ID) => {
    try {
      const response = await api.delete(
        `/attornies/${ID}/firms/${user.firm}?userId=${user._id}`
      );
      dispatch(loadFirmMemberListAction(response.data.data.users));
      dispatch(showSnackbarAction(response.data.message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let firmColumn = useMemo(
    () => [
      {
        accessorKey: 'firstName', //access nested data with dot notation
        header: 'First Name',
      },
      {
        accessorKey: 'lastName', //normal accessorKey
        header: 'Last Name',
      },

      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'userType',
        header: 'User Type',
      },
      {
        accessorKey: 'attorneyLogin',
        header: 'Status',
        Cell: ({ renderedCellValue, row }) => {
          return row.original.attorneyLogin ? (
            <Typography sx={{ fontSize: '12px', color: '#21766D' }} variant="p">
              Registered
            </Typography>
          ) : (
            <>
              <Typography
                sx={{ fontSize: '12px', fontWeight: 'bold', color: '#21766D' }}
                variant="p"
              >
                Pending
              </Typography>
              <Box sx={{ ml: -2, mt: 1 }}>
                <Button
                  variant="text"
                  onClick={() => {
                    sendLinkHandler(row.original._id);
                  }}
                >
                  Send link
                </Button>
              </Box>
            </>
          );
        },
      },
      {
        accessorKey: 'active',
        header: 'Grant Acc',
        Cell: ({ renderedCellValue, row }) => {
          return row.original.active ? (
            <Typography sx={{ fontSize: '12px', color: '#21766D' }} variant="p">
              Allowed
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: '12px', fontWeight: 'bold', color: 'red' }}
              variant="p"
            >
              Blocked
            </Typography>
          );
        },
      },
    ],
    []
  );
  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  useEffect(() => {
    getFirmMembers();
  }, [user.firm]);
  return (
    <>
      <Box>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          renderTopToolbarCustomActions={() => (
            <Button variant="contained" onClick={() => setShowAdd(!showAdd)}>
              Add
            </Button>
          )}
          columns={firmColumn}
          data={firmMembers}
          enableRowActions
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                setIsLoading(true);
                getEditData(row.original._id);
                setShowEdit(true);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={3}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteFirmMember(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={3}>Delete</Typography>
            </MenuItem>,
          ]}
          positionActionsColumn="last"
        />
      </Box>
      {showAdd && (
        <InviteFirmMembers
          open={showAdd}
          setCreateModalOpen={() => setShowAdd(!showAdd)}
        />
      )}
      {isLoading ? (
        <Loader open={isLoading} />
      ) : (
        showEdit && (
          <InviteFirmMembers
            showEdit={showEdit}
            editData={editData}
            setCreateModalOpen={() => setShowEdit(!showEdit)}
          />
        )
      )}
    </>
  );
};

export default FirmMembers;
