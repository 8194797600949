import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Navbar from "../../components/navbar/Navbar";

export default function DashboardLayoutPage() {
  return (
    <Box sx={{ display: "flex" }}>
      {/** AdminAppbar has fixed position to the top 0 and right 0 and left auto */}

      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
        {/** toolbar here gives empty height and content down bdelow appbar  */}
        <Navbar />
        <Divider />
        <Box py={1}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
