import { GET_ALL_BILLDOCUMENT, ADD_BILLDOCUMENT } from './billType';

const INITIAL_STATE = {
  billDocumentList: [],
  addBillDocument: {},
};

const billDocumentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_BILLDOCUMENT:
      return {
        ...state,
        billDocument: action.payload,
      };
    case GET_ALL_BILLDOCUMENT:
      return {
        ...state,
        billDocumentList: action.payload,
      };

    default:
      return state;
  }
};

export default billDocumentReducer;
