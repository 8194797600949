import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';

import ContactsTable from './Contacts';
import CompaniesTable from './Companies';

import theme from '../../utility/theme';

const drawerActiveListBackgroundColor = 'rgba(255,255,255, 0.08)';

const ContactsHome = () => {
  const location = useLocation();
  const navigate = useNavigate();

  function renderPageContent(render) {
    switch (render) {
      case '/contacts':
        return <ContactsTable />;

      case '/contacts/people':
        return <ContactsTable />;
      case '/contacts/companies':
        return <CompaniesTable />;

      default:
        return (
          <Typography variant="h4" mt={20} pl={170}>
            Page Not Found
          </Typography>
        );
    }
  }

  return (
    <>
      <Box sx={{ bgcolor: '#eeeeee', marginLeft: 0, marginRight: 0, p: 4 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          textAlign="center"
          spacing={{ xs: 4, sm: 6 }}
          sx={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
        >
          <Typography
            sx={{
              ...((location.pathname === '/contacts/people' ||
                location.pathname === '/contacts') && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: 'success.main',
                fontWeight: 'medium',
              }),
              '&:hover': {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
            onClick={() => {
              navigate('/contacts/people');
            }}
          >
            Contacts
          </Typography>
          <Typography
            onClick={() => {
              navigate('/contacts/companies');
            }}
            sx={{
              ...((location.pathname === '/contacts/companies' ||
                location.pathname === '/contacts/companies') && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: 'success.main',
                fontWeight: 'medium',
              }),
              '&:hover': {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
          >
            Companies
          </Typography>
          {/* <Typography
            onClick={() => {
              navigate("/contacts/groups");
            }}
            sx={{
              ...((location.pathname === "/contacts/groups" ||
                location.pathname === "/contacts/groups") && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: "success.main",
                fontWeight: "medium",
              }),
              "&:hover": {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
          >
            Contact Groups
          </Typography> */}
        </Stack>
      </Box>

      {renderPageContent(location.pathname)}
    </>
  );
};

export default ContactsHome;
