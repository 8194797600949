import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import CreateNewAccountModal from '../Document-popup/DocumentPopUp';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import AddOneDocument from './AddOneDocument';
import { loadDocumentListAction } from '../../redux/document/documentAction';
import EditDocument from './EditDocuments';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';

const MaterialTable = (props) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});

  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documents = useSelector((state) => state?.document?.documentList);

  const getAllDocuments = async (firmID) => {
    try {
      const response = await api.get(`cases/documents/firms/${firmID}`);

      dispatch(loadDocumentListAction(response.data.data.allDocuments));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getEditData = async (ID, nav) => {
    let editData = documents?.find((d) => d._id === ID);

    setEditData(editData);
    if (nav) {
      navigate('/documents/case/document-details', {
        state: {
          documentId: editData._id,
          caseId: editData?.case?._id,
        },
      });
    }
  };

  const deleteSingleDoc = async (ID) => {
    try {
      const response = await api.delete(
        `/cases/documents/${ID}/firms/${user.firm}`
      );

      console.log({ response });
      dispatch(loadDocumentListAction(response.data.data.allDocuments));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let data = [];
  let columns = useMemo(
    () => [
      {
        accessorKey: 'case.caseName',
        header: 'Case',
      },
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'document.name',
        header: 'Document Name',
      },
      {
        accessorFn: (row) => new Date(row.assignedDate),
        header: 'Assigned date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: 'clients',
        header: 'Shared with',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Typography sx={{ color: '#21766d' }} variant="p">
              {' '}
              {`${row.original?.clients.length} Contact`}
            </Typography>
          );
        },
      },
      {
        accessorFn: (row) => new Date(row.updatedAt),
        header: 'Last updated',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getAllDocuments(user.firm);
  }, [user.firm]);

  return (
    <>
      <Box sx={{ marginTop: 4 }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columns}
          data={documents || []}
          enableColumnOrdering
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              getEditData(row.original._id, true);
              // setEditMode(true);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          positionActionsColumn="last"
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                getEditData(row.original._id);
                setEditMode(true);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={3}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteSingleDoc(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={3}>Delete</Typography>
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={() => (
            <Button
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
            >
              Add document
            </Button>
          )}
        />
        <CreateNewAccountModal
          open={createModalOpen}
          onClose={setCreateModalOpen}
          type={'case'}
        />
        {editMode && (
          <EditDocument
            editData={editData}
            open={editMode}
            onClose={() => setEditMode(false)}
          />
        )}
      </Box>
    </>
  );
};

export default MaterialTable;
