import { GET_ALL_CASE, ADD_CASE } from "./caseTypes";

const INITIAL_STATE = {
  caseList: [],
  addCase: {},
};

const caseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CASE:
      return {
        ...state,
        addCase: action.payload,
      };
    case GET_ALL_CASE:
      return {
        ...state,
        caseList: action.payload,
      };

    default:
      return state;
  }
};

export default caseReducer;
