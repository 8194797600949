import { GET_ALL_TASK } from "./taskTypes";

const INITIAL_STATE = {
  task: [],
};

const taskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TASK:
      return {
        task: action.payload,
      };

    default:
      return state;
  }
};

export default taskReducer;
