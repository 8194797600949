import { Container } from '@mui/material';
import Notes from '../../components/Note/Notes';
import { Box } from '@mui/system';

const NotesPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <Notes />
      </Box>
    </Container>
  );
};

export default NotesPage;
