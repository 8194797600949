import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';

const CompanyAndClientsTable = ({ formik, contactData }) => {
  let columns = useMemo(
    () => [
      {
        accessorKey: 'label',
        header: 'Contact Name',
      },
      {
        accessorKey: 'type',
        header: 'Client/Company',
      },
    ],
    []
  );
  return (
    <Box mt={10}>
      <Typography variant="h3" mb={8}>
        Contacts Added to this case
      </Typography>
      {contactData?.length > 0 && (
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columns}
          data={contactData}
          editingMode="modal" //default
          enableColumnOrdering
          muiTablePaperProps={{
            elevation: 0,

            sx: {
              borderRadius: '0',
              border: '1px dashed #e0e0e0',
            },
          }}
          // enableEditing
          // enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              console.log('clicked');
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          positionActionsColumn="last"
          showE
          // renderRowActionMenuItems={({ row }) => [
          //   <MenuItem key="delete" onClick={() => console.info('Delete')}>
          //     Delete
          //   </MenuItem>,
          // ]}
        />
      )}
    </Box>
  );
};

export default CompanyAndClientsTable;
