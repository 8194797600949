import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  TextField,
  Autocomplete,
  Card,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Celebration } from '@mui/icons-material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Fonts } from '../../utility/theme';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { timezones } from '../../utility/commonUtil';
import api from '../../utility/api';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const userTitles = [
  { label: 'Accountant', value: 0 },
  { label: 'Associate Attorney', value: 1 },
  { label: 'Attorney', value: 2 },
  { label: 'Billing Speacialist', value: 3 },
  { label: 'Bookkeeper', value: 4 },
  { label: 'Business Manager', value: 5 },
  { label: 'Counsel', value: 6 },
  { label: 'Data Entry Clerk', value: 7 },
  { label: 'Deputy Director', value: 8 },
  { label: 'Finance Manager', value: 9 },
  { label: 'IT Manager', value: 10 },
  { label: 'Law Clerk', value: 11 },
  { label: 'Legal Assistant', value: 12 },
  { label: 'Legal Intake Specialist', value: 13 },
  { label: 'Legal Secretary', value: 14 },
  { label: 'Office Manager', value: 15 },
  { label: 'Paralegal', value: 16 },
  { label: 'Partener', value: 17 },
  { label: 'Receptionist', value: 18 },
  { label: 'Staff', value: 19 },
];

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .matches(
      passwordRegex,
      'Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required'
    )
    .required('Required'),

  confirmPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),

  userTitle: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),

  timezone: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),
});

export default function SignupPassword() {
  const [signUp, setSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { secret } = useParams();

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const initialValues = {
    password: '',
    confirmPassword: '',
    userTitle: null,
    timezone: null,
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      password: values.password,
      confirmPassword: values.confirmPassword,
      userTitle: values.userTitle.label,
      timezone: values.timezone.label,
    };

    try {
      const response = await api.post(
        `auth/signup/step2?verifyToken=${secret}`,
        formData
      );
      setSignUp(true);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <>
      {!signUp && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > :not(style)': {
              width: 700,
              minHeight: 300,
            },
          }}
        >
          <Card sx={{ p: 8 }}>
            <Box>
              <Typography
                sx={{ textAlign: 'center', color: 'text.secondary' }}
                variant="h1"
              >
                Welcome to Legal Pad
              </Typography>
            </Box>

            <Box
              sx={{
                marginBottom: 3,
                marginTop: 6,
                flexGrow: 1,
              }}
            >
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm Password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleShowConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="userTitle"
                      name="userTitle"
                      value={formik.values.userTitle}
                      options={userTitles}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option) => option.label || ''}
                      onChange={(event, newValue) => {
                        formik.setFieldValue('userTitle', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="User Title"
                          error={
                            formik.touched.userTitle &&
                            Boolean(formik.errors.userTitle)
                          }
                          helperText={
                            formik.touched.userTitle && formik.errors.userTitle
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      value={formik.values?.timezone}
                      id="timezone"
                      name="timezone"
                      options={timezones}
                      getOptionLabel={(option) => option.label || ''}
                      onChange={(event, newValue) => {
                        formik.setFieldValue('timezone', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Timezone"
                          error={
                            formik.touched.timezone &&
                            Boolean(formik.errors.timezone)
                          }
                          helperText={
                            formik.touched.timezone && formik.errors.timezone
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    marginTop: 8,
                    gap: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      type="submit"
                      sx={{
                        minWidth: 160,
                        fontWeight: Fonts.REGULAR,
                        fontSize: 16,
                        textTransform: 'capitalize',
                        padding: '4px 16px 8px',
                      }}
                    >
                      Register
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Card>
        </Box>
      )}
      {signUp && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > :not(style)': {
              //   width: 600,
              //   minHeight: 200,
            },
          }}
        >
          <Paper sx={{ width: 500, padding: 6, textAlign: 'center' }}>
            <Celebration
              fontSize="large"
              color="secondary"
              sx={{
                fontSize: 56,
              }}
            />
            <Typography my={1}>Registration success</Typography>

            <Typography>Please login to access all your cases.</Typography>
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                navigate('/signin', { state: {} });
              }}
            >
              Okay
            </Button>
          </Paper>
        </Box>
      )}
    </>
  );
}
