import { GET_ALL_CASEFOLDER, ADD_CASEFOLDER } from './caseFolderType';

export const loadCaseFolderListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CASEFOLDER,
    payload: data,
  });
};

export const loadAddCaseFolderAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_CASEFOLDER,
    payload: data,
  });
};
