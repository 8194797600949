import Container from '@mui/material/Container';
import Signin from '../../components/login/Login';

function LoginPage() {
  return (
    <Container sx={{ mt: 30 }} maxWidth="xl">
      <Signin />
    </Container>
  );
}

export default LoginPage;
