import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MaterialReactTable from 'material-react-table';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';

import api from '../../../utility/api';
import { useDispatch } from 'react-redux/es';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import { loadFirmFolderListAction } from '../../../redux/firmFolder/firmFolderAction';
import FolderIcon from '@mui/icons-material/Folder';
import DocumentFolder, { CreateNewFolder } from './AddFolder';
import {
  loadAddCaseFolderAction,
  loadCaseFolderListAction,
} from '../../../redux/caseFolder/caseFolderAction';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CaseFolderTable = (props) => {
  const [tableData, setTableData] = useState(() => props.rows);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(false);
  const user = useSelector((state) => state.auth.user.user);
  const caseFolders = useSelector((state) => state.caseFolder?.caseFolderList);
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [showEditFolderDialog, setShowEditFolderDialog] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();

  const getFoldersAndDoc = async (ID) => {
    try {
      const response = await api.get(`/cases/${ID}/folders`);

      dispatch(loadCaseFolderListAction(response.data.data.folders));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getEditData = async (ID) => {
    setEditMode(true);
    let editData = caseFolders?.find((d) => d._id === ID);
    setEditData(editData);
  };

  let firmColumn = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Title',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Stack direction="row" spacing={2}>
              <FolderIcon sx={{ color: '#2787c5', mr: 3 }} />
              <Typography
                sx={{ fontSize: '17px', color: '#21766D' }}
                variant="p"
              >
                {' '}
                {row.original?.name}
              </Typography>
            </Stack>
          );
        },
      },

      {
        accessorFn: (row) => new Date(row.createdAt),
        header: 'Assigned date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },

      {
        accessorFn: (row) => new Date(row.updatedAt),
        header: 'Last updated',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getFoldersAndDoc(props?.caseInformation?._id);
  }, [props?.caseInformation?._id]);
  return (
    <>
      {showEditFolderDialog && (
        <CreateNewFolder
          editData={editData}
          edit={true}
          type={'case'}
          caseDetails={props?.caseInformation}
          open={showEditFolderDialog}
          onClose={() => setShowEditFolderDialog(false)}
        />
      )}
      <Box sx={{ marginTop: 4 }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={firmColumn}
          data={caseFolders || []}
          editingMode="modal" //default
          enableColumnOrdering
          // enableEditing
          enableRowActions
          //   muiTableBodyRowProps={({ row }) => ({
          //     onClick: () => {
          //       navigate('/documents/firm/doc', {
          //         state: { folderId: row.original._id },
          //       });
          //     },
          //     sx: {
          //       cursor: 'pointer',
          //     },
          //   })}
          positionActionsColumn="last"
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                getEditData(row?.original._id);
                setShowEditFolderDialog(true);
                closeMenu();
              }}
            >
              Edit
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={() => (
            <>
              {' '}
              <Button
                variant="contained"
                onClick={() => setShowFolderDialog(true)}
              >
                Add
              </Button>
            </>
          )}
        />

        <CreateNewFolder
          edit={false}
          editData={{}}
          open={showFolderDialog}
          type={'case'}
          caseDetails={props?.caseInformation}
          onClose={() => setShowFolderDialog(false)}
        />
      </Box>
    </>
  );
};

export default CaseFolderTable;
