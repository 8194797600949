import { GET_ALL_FIRMFOLDER, ADD_FIRMFOLDER } from './firmFolderTypes';

const INITIAL_STATE = {
  firmFolderList: [],
  addFirmFolder: {},
};

const firmFolderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FIRMFOLDER:
      return {
        ...state,
        addFirmFolder: action.payload,
      };
    case GET_ALL_FIRMFOLDER:
      return {
        ...state,
        firmFolderList: action.payload,
      };

    default:
      return state;
  }
};

export default firmFolderReducer;
