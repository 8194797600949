import React from "react";
import Box from "@mui/material/Box";
import AuthWrapper from "./AuthWrapper";
import SiginCore from "./LoginCore";
import { Typography } from "@mui/material";

const Signin = () => {
  return (
    <AuthWrapper>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ mb: { xs: 6, xl: 8 } }}>
          <Box
            sx={{
              mb: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ textAlign: "center", color: "text.secondary" }}
              variant="h1"
            >
              Legal Pad
            </Typography>
          </Box>
        </Box>

        <SiginCore />
      </Box>
    </AuthWrapper>
  );
};

export default Signin;
