import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import DocumentDetails from '../../components/documents/DocumentDetails';

function DocumentDetailPage() {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 12, p: 4 }}>
        <DocumentDetails />
      </Box>
    </Container>
  );
}

export default DocumentDetailPage;
