import { GET_ALL_FIRMFOLDER, ADD_FIRMFOLDER } from './firmFolderTypes';

export const loadFirmFolderListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_FIRMFOLDER,
    payload: data,
  });
};

export const loadAddFirmFolderAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_FIRMFOLDER,
    payload: data,
  });
};
