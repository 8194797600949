import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinearProgress from '@mui/material/LinearProgress';

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import {
  UploadFile,
  Close,
  ContentCopy,
  PostAdd,
  Inbox,
  Work,
  Task,
  Delete,
} from '@mui/icons-material';

import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BalanceIcon from '@mui/icons-material/Balance';
import CloseIcon from '@mui/icons-material/Close';

import { showSnackbarAction } from '../../../redux/alert/alertAction';
import api from '../../../utility/api';
import axios from 'axios';

const validationSchema = yup.object().shape({});
const TaskDetails = ({ caseDetails, onClose, open, task }) => {
  const user = useSelector((state) => state.auth.user.user);
  let [comment, setComment] = useState('');
  let [taskDetails, setTaskDetails] = useState({});
  let [taskComments, setTaskComments] = useState([]);

  const dispatch = useDispatch();

  const getTaskDetails = async (taskId) => {
    try {
      let response = await api.get(`/tasks/firms/${user.firm}/tasks/${taskId}`);
      setTaskDetails(response.data.data.task);
      setTaskComments(response?.data?.data?.alltaskComments);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  const commentDeletehandler = async (commentId) => {
    try {
      const response = await api.delete(
        `/tasks/${task?._id}/firms/${user.firm}/comments/${commentId}?userId=${user?._id}`
      );

      setTaskComments(response.data.data.allTaskComments);
      dispatch(showSnackbarAction('Comment deleted successfully.', 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    if (task?._id) {
      getTaskDetails(task?._id);
    }
  }, [task]);

  const changeTaskStatus = async (firmId, taskId, stats) => {
    try {
      let status = 'close';
      let message = 'Task completed successfully.';
      if (stats === 'close') {
        status = 'open';
        message = 'Task open successfully.';
      }

      delete taskDetails?.status;
      let formData = {
        ...taskDetails,
        status: status,
      };

      const response = await api.patch(
        `tasks/firms/${firmId}/tasks/${taskId}`,
        formData
      );

      setTaskDetails(response.data.data.singleTask);
      dispatch(showSnackbarAction(message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const commentSubmithandler = async (e) => {
    try {
      let formData = {
        text: comment,
        attorneyId: user?._id,
      };
      const response = await api.post(
        `/tasks/${taskDetails?._id}/firms/${user.firm}/comments?attorneyId=${user._id}`,
        formData
      );

      setComment('');
      setTaskComments(response.data.data.allTaskComments);
      dispatch(showSnackbarAction('Comment added successfully.', 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onclose={() => onclose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            borderBottom: '1px dotted #d3c3c4!important',
            bgcolor: '#f5f5f5',
            py: 5,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={9}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h2">Task Details</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Typography
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container item pt={6}>
              <Grid
                item
                xs={12}
                sm={6}
                container
                spacing={2}
                sx={{
                  borderRight: '1px dotted #d3c3c4!important',
                }}
              >
                <Grid container item pt={6}>
                  <Grid sx={{ mb: 4 }} item xs={12} md={6} alignSelf="center">
                    <Typography variant="h1">
                      <Task
                        sx={{ mr: 1, mb: -1, fontSize: '28px', color: 'grey' }}
                      />
                      {taskDetails?.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item pt={6}>
                  <Grid sx={{ mb: 4 }} item xs={12} md={12} alignSelf="center">
                    <Box
                      sx={{
                        bgcolor:
                          taskDetails?.status === 'close'
                            ? '#d3f8d3'
                            : '#CCD9E3',
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        p: 4,
                      }}
                    >
                      <FormControl>
                        <FormControlLabel
                          value={
                            taskDetails?.status === 'close' ? 'open' : 'close'
                          }
                          control={
                            <Radio
                              checked={taskDetails?.status === 'close'}
                              onClick={() =>
                                changeTaskStatus(
                                  user.firm,
                                  taskDetails?._id,
                                  taskDetails?.status
                                )
                              }
                            />
                          }
                          label={
                            taskDetails?.status === 'close'
                              ? 'Mark Task as pending'
                              : 'Mark Task Complete'
                          }
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                {caseDetails?.caseName && (
                  <Grid item xs={12} md={6} alignSelf="center">
                    <Typography variant="h4">Case</Typography>
                  </Grid>
                )}
                {caseDetails?.caseName && (
                  <Grid item xs={12} md={6} sx={{ bgcolor: 'gray' }}>
                    {caseDetails?.caseName}
                  </Grid>
                )}
                <Grid item xs={12} md={6} alignSelf="center">
                  <Typography variant="h4">Priority</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ bgcolor: 'gray' }}>
                  {taskDetails?.priority}
                </Grid>
                <Grid item xs={12} md={6} alignSelf="center">
                  <Typography variant="h4">Status</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ bgcolor: 'gray' }}>
                  {taskDetails?.status}
                </Grid>
                <Grid item xs={12} md={6} alignSelf="center">
                  <Typography variant="h4">Due date</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ color: '#C9252D' }}>
                  {new Date(taskDetails?.dueDate).toDateString()}
                </Grid>
                {taskDetails?.lead && (
                  <Grid item xs={12} md={6} alignSelf="center">
                    <Typography variant="h4">Lead</Typography>
                  </Grid>
                )}
                {taskDetails?.lead && (
                  <Grid item xs={12} md={6} sx={{ color: '#C9252D' }}>
                    {`${taskDetails?.lead?.firstName} ${taskDetails?.lead?.lastName}`}
                  </Grid>
                )}

                <Grid item xs={12} md={6} alignSelf="center">
                  <Typography variant="h4">Clients</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ bgcolor: 'gray' }}>
                  {taskDetails?.clients?.length > 0 &&
                    taskDetails?.clients?.map((client) => {
                      return (
                        <Typography>{`${client?.firstName} ${client?.lastName}`}</Typography>
                      );
                    })}
                </Grid>
                <Grid item xs={12} md={6} alignSelf="center">
                  <Typography variant="h4">Attornies</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ bgcolor: 'gray' }}>
                  {taskDetails?.staffs?.length > 0 &&
                    taskDetails?.staffs?.map((client) => {
                      return (
                        <Typography>{`${client?.firstName} ${client?.lastName}`}</Typography>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pl: 3 }} spacing={4}>
                <Grid container item pt={6}>
                  <Grid sx={{ mb: 4 }} item xs={12} md={6} alignSelf="center">
                    <Typography variant="h1">Comments</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {taskComments?.length > 0 &&
                        taskComments.map((comment) => {
                          return (
                            <>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar
                                    sx={{
                                      width: 46,
                                      height: 46,
                                      bgcolor: '#0070BB',
                                    }}
                                  >
                                    {comment?.createdBy?.client
                                      ? Array.from(
                                          comment.createdBy?.client?.firstName.toUpperCase()
                                        )[0]
                                      : Array.from(
                                          comment.createdBy?.attorney?.firstName.toUpperCase()
                                        )[0]}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  sx={{
                                    marginLeft: 2,
                                  }}
                                  // primaryTypographyProps={{
                                  //   fontSize: '18px',
                                  //   color: 'black',
                                  // }}
                                  primaryTypographyProps={{
                                    variant: 'subtitle2',
                                    style: {
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    },
                                  }}
                                  secondaryTypographyProps={{
                                    variant: 'caption',
                                    style: {
                                      wordWrap: 'break-word',
                                    },
                                  }}
                                  primary={
                                    comment?.createdBy?.client
                                      ? comment.createdBy?.client?.firstName +
                                        ' ' +
                                        comment.createdBy?.client?.lastName +
                                        ' ' +
                                        '(Client)'
                                      : comment.createdBy?.attorney?.firstName +
                                        ' ' +
                                        comment.createdBy?.attorney?.lastName +
                                        ' ' +
                                        '(Attorney)'
                                  }
                                  secondary={
                                    <>
                                      <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="#636C72"
                                      >
                                        {new Date(
                                          comment?.date
                                        ).toLocaleString()}
                                      </Typography>

                                      <Typography
                                        sx={{
                                          color: 'black',
                                          fontSize: '15px',
                                          mt: 1,
                                        }}
                                      >
                                        {comment.text}
                                      </Typography>
                                      {comment.createdBy?.attorney?._id ===
                                        user?._id && (
                                        <Box
                                          sx={{
                                            float: 'right',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <Delete
                                            onClick={() =>
                                              commentDeletehandler(comment?._id)
                                            }
                                          />
                                        </Box>
                                      )}
                                    </>
                                  }
                                />
                              </ListItem>

                              <Divider />
                            </>
                          );
                        })}
                    </List>
                    <Box>
                      <TextField
                        fullWidth
                        id="outlined-multiline-flexible"
                        label="Add Comment"
                        multiline
                        value={comment}
                        minRows={4}
                        maxRows={4}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      <Button
                        // type="submit"
                        variant="contained"
                        sx={{ bgcolor: '#0070BB', mt: 2, fontSize: '18px' }}
                        disabled={comment.trim() ? false : true}
                        onClick={() => commentSubmithandler()}
                      >
                        Post
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TaskDetails;
