import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Menu,
  InputBase,
  alpha,
  Autocomplete,
  TextField,
  Container,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
} from '@mui/material';
import DrawerComp from '../drawerComp/DrawerComp';

import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GavelIcon from '@mui/icons-material/Gavel';
import PeopleIcon from '@mui/icons-material/People';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../redux/auth/authAction';

const AppNav = styled(AppBar)({
  // backgroundColor: '#212121',
});

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const isMatches = useMediaQuery(theme.breakpoints.down('md'));

  let option = [{ label: '1', value: '1' }];
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: 30,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 6,
  }));

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '500px',
    height: 40,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),

      width: 'auto',
    },
  }));
  const navigate = useNavigate();
  return (
    <AppNav position="fixed" sx={{ paddingX: '1.8vw' }}>
      <Toolbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            // textAlign: 'center',
            cursor: 'pointer',
          }}
          marginRight="auto"
        >
          {isMatches ? (
            <DrawerComp />
          ) : (
            <>
              <NavLink
                to="/dashboard"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? 'black' : '#FFFFFF',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h4" letterSpacing={0.5}>
                  HOME
                </Typography>
              </NavLink>
              <NavLink
                to="/task"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? 'black' : '#FFFFFF',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h4" letterSpacing={0.5}>
                  TASKS
                </Typography>
              </NavLink>
              <NavLink
                to="/cases"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? 'black' : '#FFFFFF',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h4" letterSpacing={0.5}>
                  CASES
                </Typography>
              </NavLink>
              <NavLink
                to="/contacts"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? 'black' : '#FFFFFF',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h4" letterSpacing={0.5}>
                  CONTACTS
                </Typography>
              </NavLink>
              {/* <Typography marginRight={4} variant="h4">
                REPORTS
              </Typography> */}

              <NavLink
                to="/billing"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? 'black' : '#FFFFFF',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h4" letterSpacing={0.5}>
                  BILLING
                </Typography>
              </NavLink>
              <NavLink
                to="/lead"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? 'black' : '#FFFFFF',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h4" letterSpacing={0.5}>
                  LEAD
                </Typography>
              </NavLink>
            </>
          )}
          {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={option}
          sx={{ width: 50, bgcolor: 'white', height: 40 }}
          renderInput={(params) => <TextField {...params} label="Movie" />}
        /> */}
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
              <Typography mb={1} ml={1}>
                {" "}
                search documents
              </Typography>
            </SearchIconWrapper>
            <StyledInputBase inputProps={{ "aria-label": "search" }} />
          </Search> */}
        </Box>
        <NavLink
          to="/documents/case"
          style={({ isActive, isPending }) => {
            return {
              fontWeight: isActive ? 'bold' : '',
              color: isActive ? 'black' : '#FFFFFF',
              textDecoration: 'none',
            };
          }}
        >
          <Typography
            marginRight={4}
            variant="h4"
            letterSpacing={0.5}
            sx={{ cursor: 'pointer' }}
          >
            DOCUMENTS
          </Typography>
        </NavLink>
        <Typography
          sx={{ cursor: 'pointer', letterSpacing: 0.5 }}
          variant="h4"
          onClick={handleClick}
        >
          MY ACCOUNT
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              bgcolor: 'white',
              color: 'black',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => {
              navigate('/firm-members');
            }}
          >
            <ListItemIcon>
              <PeopleIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Firm Members
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/notes');
            }}
          >
            <ListItemIcon>
              <NoteAltIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Notes
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/account');
            }}
          >
            <ListItemIcon>
              <SettingsIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/terms');
            }}
          >
            <ListItemIcon>
              <GavelIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Terms
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/privacy-policy');
            }}
          >
            <ListItemIcon>
              <PrivacyTipIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Privacy Policy
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(logoutAction());
            }}
          >
            <ListItemIcon>
              <LogoutIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppNav>
  );
};

export default Navbar;
