import styled from "@emotion/styled";
import { FormControl, Switch, FormControlLabel } from "@mui/material";
import { useState } from "react";

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 70,
  height: 40,
  padding: 0,
  "&::after": {
    content: '"X"',
    position: "absolute",
    top: 10,
    right: 10,
    color: "white",
  },
  "&::before": {
    content: '"✔"',
    position: "absolute",
    top: 10,
    left: 10,
    color: "white",
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(30px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 35,
    height: 35,
  },
  "& .MuiSwitch-track": {
    borderRadius: 40 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#424242" : "#424242",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function SwitchComp() {
  const [checked, setchecked] = useState(false);
  return (
    <FormControl>
      <FormControlLabel
        control={<CustomSwitch sx={{ m: 1 }} defaultChecked />}
      />
    </FormControl>
  );
}
