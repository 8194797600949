import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Celebration, Close } from '@mui/icons-material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styled from '@emotion/styled';
import api from '../../../utility/api';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import { useDispatch, useSelector } from 'react-redux';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(10),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));
const CaseStatus = ({ caseDetails }) => {
  const [open, setOpen] = React.useState(false);
  const [statusValue, setStatusValue] = React.useState('');
  const [caseData, setCaseData] = React.useState({});
  const [noteCount, setNoteCount] = React.useState('');
  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getCaseDetails = async (id) => {
    try {
      const response = await api.get(`/cases/${id}`);

      if (response) {
        setCaseData(response.data.data.case);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  const handleClose = async () => {
    let caseStatus = {};

    if (caseDetails?.caseStatus) {
      delete caseDetails?.caseStatus;
    }
    const formData = {
      caseStatus: {
        createdBy: user?._id,
        text: statusValue,
        date: new Date(),
      },
      ...caseDetails,
    };

    try {
      const response = await api.patch(`/cases/${caseDetails._id}`, formData);

      setCaseData(response.data.data.singleCase);
      dispatch(showSnackbarAction(response.data.message, 'success'));
      setStatusValue('');
      setOpen(false);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getNotesCount = async (id) => {
    try {
      console.log({ id });
      const response = await api.get(
        `/tasks/firms/${user.firm}/count?caseId=${id}`
      );

      if (response) {
        setNoteCount({
          pending: response.data.data.pending,
          completed: response.data.data.completed,
        });
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  useEffect(() => {
    if (caseDetails?._id) {
      getCaseDetails(caseDetails._id);
      getNotesCount(caseDetails._id);
    }
  }, [caseDetails]);

  return (
    <>
      <Grid conatiner item spacing={2}>
        <Grid item md={12} lg={12}>
          <Paper sx={{ padding: 3, mb: 8 }}>
            <Typography variant="h2">Case status</Typography>
            {caseData?.caseStatus?.createdBy?.firstName && (
              <Typography variant="h5" sx={{ mb: 4, mt: 4 }}>
                {`Created ${new Date(
                  caseData?.caseStatus?.date
                ).toDateString()} by `}
                <Typography sx={{ color: '#11CA5B' }} variant="span">
                  {caseData?.caseStatus?.createdBy?.firstName +
                    ' ' +
                    caseData?.caseStatus?.createdBy?.lastName}
                </Typography>
              </Typography>
            )}
            {caseData?.caseStatus?.text && (
              <Typography variant="p" sx={{ fontSize: '15px' }}>
                {caseData?.caseStatus?.text}
              </Typography>
            )}
            <Box sx={{ ml: -2 }}></Box>
            <Box sx={{ ml: -2 }}>
              <Button variant="text" onClick={() => handleClickOpen()}>
                Add New Update
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item md={12} lg={12}>
          <Paper sx={{ padding: 3, mb: 8 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
            >
              <Typography variant="h2">Tasks(next 30 days)</Typography>
            </Box>
            <Typography mt={3}>{`Pending: ${noteCount?.pending}`}</Typography>
            <Typography
              mt={3}
            >{`completed: ${noteCount?.completed}`}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        >
          Add Status
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TextField
            id="outlined-multiline-static"
            label="Status"
            sx={{ minWidth: '500px' }}
            placeholder="Whats going on with this case?"
            multiline
            rows={4}
            onChange={(e) => setStatusValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              borderRadius: '30px',
              color: 'white',
              bgcolor: 'primary.main',
              ':hover': {
                color: 'white',
                bgcolor: 'primary.main',
              },
            }}
            disabled={statusValue.trim() ? false : true}
            autoFocus
            onClick={handleClose}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 5, fontSize: '18px', bgcolor: '#f5f5f5' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default CaseStatus;
