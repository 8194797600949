import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Close } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Link,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CountryAutocomplete from '../reusables/CountryAutocomplete';
import StateAutocomplete from '../reusables/StateAutocomplete';
import CityAutocomplete from '../reusables/CityAutocomplete';

import theme from '../../utility/theme';
import SwitchComp from '../../utility/switch/Switch';
import {
  loadCityListAction,
  loadStateListAction,
  removeCityListAction,
  removeStateListAction,
} from '../../redux/list/listAction';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { timezones } from '../../utility/commonUtil';

import { countryList } from '../../utility/countryList';
import {
  loadAllContactListAction,
  loadContactListAction,
} from '../../redux/contacts/contactsActions';
import api from '../../utility/api';
import dayjs from 'dayjs';
import { loadComanyListAction } from '../../redux/comapny/companyActions';

const websiteRegax = new RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
);
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const zipCodeRegax = /^[1-9][0-9]{5}$/;

export default function AddNewContact({ open, onClose, showEdit, editData }) {
  const [showPeopleGroup, setShowPeopleGroup] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [moreInfo, setShowMoreInfo] = useState(false);
  const [peopleGroupList, setPeopleGroupList] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [initialCountry, setInitialCountry] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);
  const [initialCountryCode, setCountryCode] = useState(null);
  const validationSchema = yup.object().shape({
    firstName: yup.string('Enter First Name').trim().required('Required'),
    lastName: yup.string('Enter Last Name').trim().required('Required'),

    // email: yup.string('Enter your email').when('mobile', {
    //   is: (value) => {
    //     console.log(value);
    //     if (value === null || value === 'undefined') {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   then: yup
    //     .string('Enter your email')
    //     .email('Provide a valid email.')
    //     .required('Required'),
    // }),
    email: yup.mixed().test('required', 'Required', (form) => {
      console.log('runn', formik.values?.mobile);
      if (formik.values?.mobile > 0 || formik.values?.mobile != '') {
        console.log('runn mobilr');
        return true;
      }
      console.log('email', formik.values?.email);
      if (formik.values?.email > 0 || formik.values?.email != '') {
        console.log('runn email');
        return true;
      }
      return false;
    }),
    mobile: yup
      .string('Enter Mobile')
      .matches(phoneRegExp, 'Enter valid number'),
    workMobile: yup
      .string('Enter Mobile')
      .matches(phoneRegExp, 'Enter valid number')
      .min(10)
      .max(10),
    country: yup
      .object()
      .shape({
        label: yup.string('Enter Country').required('Required'),
      })
      .typeError('Choose a country'),

    state: yup
      .object()
      .shape({
        label: yup.string('Enter state').required('Required'),
      })
      .typeError('Choose a state'),

    city: yup
      .object()
      .shape({
        label: yup.string('Enter city').required('Required'),
      })
      .typeError('Choose a city'),
    zipcode: yup
      .string('Enter zipcode')
      .matches(zipCodeRegax, 'Enter valid zipcod'),

    website: yup
      .string('Enter your password')
      .matches(websiteRegax, 'enter valid url'),

    ...(!showPeopleGroup && {
      peopleGroup: yup.object().shape({
        value: yup.string().required('Required'),
        label: yup.string('Required'),
      }),
    }),

    ...(showPeopleGroup && {
      peopleGroupInput: yup.string().trim().required('Required'),
    }),
  });

  const user = useSelector((state) => state.auth.user.user);

  const companyList = useSelector((state) => state.company?.companyList);

  const dispatch = useDispatch();

  const getPeopleGroup = async () => {
    try {
      const response = await api.get(`/clients/peopleGroup`);
      setPeopleGroupList(response.data.data.peopleGroups);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getCountryCode = async () => {
    try {
      const response = await api.get(`/locations/countryCodes`);
      setCountryCodes(response.data.data.countries);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let options = peopleGroupList?.map((item) => {
    let obj = {
      label: item.name,
      value: item._id,
    };
    return obj;
  });

  const initialValues = {
    firstName: showEdit ? editData.firstName : '',
    lastName: showEdit ? editData.lastName : '',
    middleName: showEdit ? editData?.middleName : '',
    email: showEdit ? editData?.user?.email : '',
    peopleGroup:
      showEdit && editData?.peopleGroup?.name
        ? {
            label: editData?.peopleGroup?.name,
            value: editData?.peopleGroup?._id,
          }
        : null,
    peopleGroupInput: '',
    companyInput: '',
    enableClientPortal: showEdit ? editData.clientLogin : false,
    mobile: showEdit ? editData?.user?.mobile : '',
    countryCode:
      showEdit && countryCodes.length > 0 && editData?.user?.countryCode
        ? countryCodes.find((c) => c.value === editData?.user?.countryCode)
        : null,
    workMobile: showEdit ? editData?.workMobile : '',
    workCountryCode:
      showEdit && countryCodes.length > 0 && editData?.workCountryCode
        ? countryCodes.find((c) => c.value === editData?.workCountryCode)
        : null,
    address: showEdit ? editData?.address : '',
    address2: showEdit ? editData?.address2 : '',
    country: initialCountry || null,
    state: initialState || null,
    city: initialCity || null,
    timezone:
      showEdit && editData?.timezone
        ? {
            label: editData?.timezone,
            value: editData?.timezone,
          }
        : null,
    zipcode: showEdit ? editData?.zipcode : '',
    birthday: showEdit ? editData.user?.dateOfBirth : '',
    company: showEdit ? editData?.company : '',
    jobTitle: showEdit ? editData?.jobTitle : '',
    website: showEdit ? editData?.website : '',
    note: showEdit ? editData?.note : '',
    attorneyId: '',
  };

  const loadCountryStateCity = async () => {
    const country = countryList.find((c) => c.value === editData?.user.country);
    setInitialCountry(country);
    loadStateList(country?.countryId);
  };

  const getAllCompanies = async (firmID) => {
    try {
      const response = await api.get(`clients/company?firm=${user.firm}`);
      dispatch(loadComanyListAction(response.data.data.company));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const loadStateList = async (countryId) => {
    try {
      const response = await api.get(`locations/states?countryId=${countryId}`);

      const state = response.data.data.states?.find(
        (s) => s.value === editData?.user?.state
      );

      setInitialState(state);
      dispatch(loadStateListAction(response.data.data.states));
      loadCityList(state?.stateId);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  const loadCityList = async (stateId) => {
    try {
      const response = await api.get(`locations/cities?stateId=${stateId}`);
      const city = response.data.data.cities.find(
        (c) => c.value === editData?.user.city
      );
      setInitialCity(city);
      dispatch(loadCityListAction(response.data.data.cities));
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    loadCountryStateCity();
    return () => {
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
    };
  }, []);

  const onSubmit = async (values, action) => {
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      email: values.email || '',
      peopleGroup: !showPeopleGroup
        ? values.peopleGroup?.value
        : values.peopleGroupInput,
      createPeopleGroup: showPeopleGroup,
      enableClientPortal: values.enableClientPortal,
      mobile: values.mobile || '',
      countryCode: values.countryCode?.value,
      workMobile: values.workMobile,
      workCountryCode: values.workCountryCode?.value,
      address: values.address,
      address2: values.address2,
      country: values.country?.label,
      state: values.state?.label,
      city: values.city?.label,
      timezone: values.timezone?.label,
      zipcode: values.zipcode,
      birthday: values.birthday,
      company: showCompany ? values.companyInput : values.company,
      createNewCompany: showCompany ? true : false,
      jobTitle: values.jobTitle,
      website: values.website,
      note: values.note,
      attorneyId: user._id,
      firmId: user.firm,
    };

    // if (formData?.email === '' && formData.mobile === '') {
    //   formik.setErrors({ email: 'Either email or mobile is required' });
    //   return;
    // }

    if (formData.company === '') {
      delete formData.company;
    }

    console.log({ formData });
    try {
      if (showEdit) {
        let response = await api.patch(
          `/clients/${editData?._id}/firms/${editData.firm}`,
          formData
        );
        if (response?.data?.data) {
          // onGetClients();
          dispatch(loadAllContactListAction(response?.data?.data?.clients));
          dispatch(showSnackbarAction(response?.data?.message, 'success'));
          onClose();
          formik.resetForm({});
        }
      } else {
        if (formData.mobile === '') {
          delete formData.mobile;
        }
        if (formData.email === '') {
          delete formData.email;
        }
        if (formData.workMobile === '') {
          delete formData.workMobile;
        }
        let res = await api.post(`/clients`, formData);

        if (res?.data?.data) {
          // onGetClients();
          dispatch(loadAllContactListAction(res?.data?.data?.clients));
          dispatch(showSnackbarAction(res?.data?.message, 'success'));
          onClose();
          formik.resetForm({});
        }
      }
    } catch (error) {
      console.log({ error });
      dispatch(showSnackbarAction(error.response?.data?.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    getPeopleGroup();
    getCountryCode();
    getAllCompanies();
  }, []);

  console.log({ formik });

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { height: '100%' } }}
      open={open || showEdit}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: theme.typography.h3 }}>
          {showEdit ? ' Edit Person' : '  Add Person'}
        </DialogTitle>
        <DialogTitle>
          <Close onClick={onClose} />
        </DialogTitle>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Name*
              </Typography>
            </Grid>
            <Grid item xs={12} md={3.5}>
              <TextField
                id="firstName"
                name="firstName"
                variant="outlined"
                label="First Name*"
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                id="middleName"
                name="middleName"
                variant="outlined"
                label="M"
                fullWidth
                value={formik.values.middleName}
                onChange={formik.handleChange}
                error={
                  formik.touched.middleName && Boolean(formik.errors.middleName)
                }
                helperText={
                  formik.touched.middleName && formik.errors.middleName
                }
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <TextField
                id="lastName"
                name="lastName"
                variant="outlined"
                label="Last Name*"
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Email
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                label="xyz@gmail.com"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Contact Group*
              </Typography>
            </Grid>
            <Grid item xs={12} md={4.5}>
              {!showPeopleGroup ? (
                <Autocomplete
                  value={formik.values?.peopleGroup}
                  id="peopleGroup"
                  name="peopleGroup"
                  options={options}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('peopleGroup', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.peopleGroup &&
                        Boolean(formik.errors.peopleGroup)
                      }
                      helperText={
                        formik.touched.peopleGroup && formik.errors.peopleGroup
                      }
                      label="People Group"
                    />
                  )}
                />
              ) : (
                <TextField
                  id="peopleGroupInput"
                  name="peopleGroupInput"
                  variant="outlined"
                  fullWidth
                  value={formik.values.peopleGroupInput}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.peopleGroupInput &&
                    Boolean(formik.errors.peopleGroupInput)
                  }
                  helperText={
                    formik.touched.peopleGroupInput &&
                    formik.errors.peopleGroupInput
                  }
                />
              )}
            </Grid>
            <Grid sx={{ mt: 2, ml: 2 }} item xs={12} md={4}>
              <Button
                onClick={() => setShowPeopleGroup(!showPeopleGroup)}
                variant="text"
              >
                {showPeopleGroup ? 'cancel' : 'Add new people group'}
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Enable Client Portal
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box sx={{ ml: 4 }}>
                <FormControl>
                  {formik.values.enableClientPortal === true && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="enableClientPortal"
                          defaultChecked
                          onChange={formik.handleChange}
                          value={formik.values.enableClientPortal}
                        />
                      }
                    />
                  )}
                  {formik.values.enableClientPortal === false && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="enableClientPortal"
                          onChange={formik.handleChange}
                          value={formik.values.enableClientPortal}
                        />
                      }
                    />
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mt: 1 }}>
                Securely share documents and ivoices. They will receive a
                welcome email with login instructions. Your client will only
                have access to items that you explicitly share.
              </Box>
              {/* <Box sx={{ mt: 1 }}>
                <Link>What will my clients see?</Link>
              </Box> */}
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Mobile
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                value={formik.values?.countryCode}
                id="countryCode"
                name="countryCode"
                options={countryCodes}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('countryCode', newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Country Code" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                id="mobile"
                name="mobile"
                variant="outlined"
                fullWidth
                label="Mobile"
                inputProps={{ maxLength: 10 }}
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Work Mobile
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                value={formik.values?.workCountryCode}
                id="workCountryCode"
                name="workCountryCode"
                options={countryCodes}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('workCountryCode', newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Country Code " />
                )}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                id="workMobile"
                name="workMobile"
                variant="outlined"
                label="Work Mobile"
                inputProps={{ maxLength: 10 }}
                fullWidth
                value={formik.values.workMobile}
                onChange={formik.handleChange}
                error={
                  formik.touched.workMobile && Boolean(formik.errors.workMobile)
                }
                helperText={
                  formik.touched.workMobile && formik.errors.workMobile
                }
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Address
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                id="address"
                name="address"
                variant="outlined"
                fullWidth
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Address2
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                id="address2"
                name="address2"
                variant="outlined"
                fullWidth
                value={formik.values.address2}
                onChange={formik.handleChange}
                error={
                  formik.touched.address2 && Boolean(formik.errors.address2)
                }
                helperText={formik.touched.address2 && formik.errors.address2}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Country*, State*, City*
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <CountryAutocomplete formik={formik} />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <StateAutocomplete
                formik={formik}
                countryId={formik.values.country?.countryId}
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <CityAutocomplete
                formik={formik}
                stateId={formik.values.state?.stateId}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Timezone, Zipcode
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                value={formik.values?.timezone}
                id="timezone"
                name="timezone"
                options={timezones}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('timezone', newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Timezone" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                id="zipcode"
                name="zipcode"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 6 }}
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                helperText={formik.touched.zipcode && formik.errors.zipcode}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Button
                onClick={() => setShowMoreInfo(!moreInfo)}
                sx={{ ml: -2 }}
                variant="text"
              >
                Add more information
                {moreInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            </Grid>
          </Grid>
          {moreInfo && (
            <>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Birthday
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="birthday"
                      name="birthday"
                      onChange={(value) =>
                        formik.setFieldValue('birthday', value)
                      }
                      value={dayjs(formik.values.birthday)}
                      slotProps={{
                        textField: {
                          error: false,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 4 }} variant="h4">
                    Company
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  {!showCompany ? (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Company
                      </InputLabel>
                      <Select
                        id="company"
                        name="company"
                        label="Comapny"
                        options={companyList}
                        value={formik.values.company}
                        onChange={formik.handleChange}
                      >
                        {companyList.map((company) => {
                          return (
                            <MenuItem value={company._id}>
                              {company.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    !showEdit && (
                      <TextField
                        id="companyInput"
                        name="companyInput"
                        variant="outlined"
                        fullWidth
                        value={formik.values.companyInput}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.companyInput &&
                          Boolean(formik.errors.companyInput)
                        }
                        helperText={
                          formik.touched.companyInput &&
                          formik.errors.companyInput
                        }
                      />
                    )
                  )}
                </Grid>
                <Grid sx={{ mt: 2, ml: 2 }} item xs={12} md={4}>
                  {!showEdit && (
                    <Button
                      onClick={() => setShowCompany(!showCompany)}
                      variant="text"
                    >
                      {showCompany ? 'cancel' : 'Add new Company'}
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Job Title
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    id="jobTitle"
                    name="jobTitle"
                    variant="outlined"
                    fullWidth
                    value={formik.values.jobTitle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.jobTitle && Boolean(formik.errors.jobTitle)
                    }
                    helperText={
                      formik.touched.jobTitle && formik.errors.jobTitle
                    }
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Website
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    id="website"
                    name="website"
                    variant="outlined"
                    fullWidth
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.website && Boolean(formik.errors.website)
                    }
                    helperText={formik.touched.website && formik.errors.website}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Note
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    id="note"
                    name="note"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={formik.touched.note && formik.errors.note}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 80 }}
            sx={{ justifyContent: 'center', mt: 15, mb: 4 }}
          >
            <Button
              fullWidth
              size="large"
              variant="outlined"
              sx={{ mr: 1 }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button fullWidth size="large" variant="contained" type="submit">
              Save Person
            </Button>
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
}
