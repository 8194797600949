import { Container } from '@mui/material';
import TaskTable from '../../components/task/TaskTable';
import { Box } from '@mui/system';

const TaskPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <TaskTable />
      </Box>
    </Container>
  );
};

export default TaskPage;
