import { GET_ALL_CASETASK, ADD_CASETASK } from './caseTaskType';

const INITIAL_STATE = {
  caseTaskList: [],
  addCaseTask: {},
};

const caseTaskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CASETASK:
      return {
        ...state,
        addCaseTask: action.payload,
      };
    case GET_ALL_CASETASK:
      return {
        ...state,
        caseTaskList: action.payload,
      };

    default:
      return state;
  }
};

export default caseTaskReducer;
