import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import {
  UploadFile,
  Close,
  ContentCopy,
  PostAdd,
  Inbox,
} from '@mui/icons-material';

import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BalanceIcon from '@mui/icons-material/Balance';
import Loader from '../loader/Loader';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import axios from 'axios';
import { loadCaseListAction } from '../../redux/cases/caseActions';
import CaseDetails from '../case/case-details/CaseDetails';
import dayjs from 'dayjs';
import { loadDocumentListAction } from '../../redux/document/documentAction';
import { loadFirmDocumentListAction } from '../../redux/firmDocuments/firmDocumentAction';
import { loadCaseDocumentListAction } from '../../redux/caseDocument/caseDocumentAction';
import { loadCaseBillDocumentListAction } from '../../redux/caseBill/caseBillAction';
import { loadBillDocumentListAction } from '../../redux/bill/billAction';

const EditDocument = ({
  firmUsers,
  isFirmEditing,
  editData,
  open,
  onClose,
  type,
}) => {
  const [value, setValue] = useState(0);
  const [caseFolder, setCaseFolder] = useState([]);
  const [clients, setClients] = useState([]);
  const [checked, setChecked] = useState([1]);
  const [firmMembers, setFirmMembers] = useState(firmUsers);

  const user = useSelector((state) => state.auth.user.user);
  const caseList = useSelector((state) => state.case?.caseList);
  const dispatch = useDispatch();

  const documentValidationSchema = yup.object().shape({
    docName: yup.string('Enter doc name').required('Required'),

    // folder: yup.mixed().test('required', 'Required', (form) => {
    //   if (formik.values.folder.length > 0 || formik.values.folderName != '') {
    //     return true;
    //   }

    //   return false;
    // }),
    ...(caseFolder?.length > 0 && {
      folder: yup.string().required('Required'),
    }),
  });

  const initialValues = {
    folder: caseFolder.length > 0 ? editData?.folder?._id : '',
    docName: editData?.name,
    assignedDate: editData?.assignedDate,
    tag: '',
    tags: editData?.tags,
    description: editData?.description,
  };
  const onSubmit = async (values, actions) => {
    let formData = {};

    if (isFirmEditing) {
      formData = {
        docName: values.docName,
        assignedDate: values.assignedDate,
        tags: values.tags,
        description: values.description,
        firmUserAccess: checked.slice(1),
      };
    } else {
      formData = {
        folder: values?.folder,
        docName: values.docName,
        assignedDate: values.assignedDate,
        tags: values.tags,
        clients: checked.slice(1),
        description: values.description,
        caseId: editData?.case?._id,
      };
    }

    async function apiCall() {
      try {
        let url = `cases/documents/${editData?._id}/firms/${user.firm}?type=${type}`;

        if (isFirmEditing) {
          url = `cases/documents/${editData?._id}/firms/${user.firm}?type=firm`;
        }

        let response = await api.patch(url, formData);

        if (isFirmEditing) {
          dispatch(loadFirmDocumentListAction(response.data.data.allDocuments));
        } else {
          dispatch(loadBillDocumentListAction(response.data.data.allDocuments));
          dispatch(
            loadCaseDocumentListAction(response.data.data.caseDocuments)
          );
          dispatch(
            loadCaseBillDocumentListAction(response.data.data.caseDocuments)
          );
          dispatch(loadDocumentListAction(response.data.data.allDocuments));
        }

        dispatch(showSnackbarAction(response.data.message, 'success'));
        onClose();
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, 'error'));
      }
    }
    apiCall();
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: documentValidationSchema,
    enableReinitialize: true,
  });

  const addTags = () => {
    if (!formik.values.tag?.length > 0) {
      formik.setFieldError('tag', 'Required');
      formik.setFieldTouched('tag', true, true);
      return;
    }
    const match = formik.values.tags.includes(formik.values.tag);

    if ((formik.values.tag?.length > 0) & !match) {
      formik.setFieldValue('tags', [...formik.values.tags, formik.values.tag]);
      formik.setFieldValue('tag', '');
      formik.setFieldValue('openTagsModal', false);
    }
  };

  const getFirmMembers = async (id) => {
    try {
      const resp = await api.get(`/attornies/firmMembers/${id}`);
      if (resp.data.data.users?.length > 0) {
        // let data = resp.data.data.users.map((user) => {
        //   return {
        //     label: `${user.firstName} ${user.lastName}`,
        //     firm: user.firm,
        //     value: user._id,
        //   };
        // });

        setFirmMembers(resp.data.data.users);

        const result2 = resp.data.data.users?.filter(({ _id: id1 }) =>
          editData.firmUserAccess.some(({ _id: id2 }) => {
            return id2 === id1;
          })
        );

        console.log({ result2 });
        if (result2) {
          setChecked([1, ...result2]);
        }
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getFolders = async (id) => {
    try {
      const resp = await api.get(`cases/${id}/folders`);

      if (resp?.data?.data?.folders?.length === 0) {
        setCaseFolder([]);
      } else {
        setCaseFolder(resp.data.data.folders);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    const results = editData?.case?.clients?.filter(({ _id: id1 }) =>
      editData.clients.some(({ _id: id2 }) => {
        return id2 === id1;
      })
    );

    if (results) {
      setChecked([1, ...results]);
    }

    if (isFirmEditing) getFirmMembers(user.firm);

    if (editData?.case?._id) getFolders(editData?.case?._id);
  }, [editData?.case?._id]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    // if (value?.value === 'all') {
    //   let arr = [1, ...clients];
    //   if (checked.length === clients.length + 1) {
    //     console.log('runn');
    //     setChecked([1]);
    //   } else {
    //     setChecked(arr);
    //   }
    // } else {

    // }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
        sx={{ padding: 4 }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            borderBottom: '1px dotted #d3c3c4!important',
            bgcolor: '#f5f5f5',
            py: 5,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={9}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h2">{'Edit Document'}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h2">{'Sharing'}</Typography>
                <Typography
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid
                item
                sx={{ borderRight: '1px dotted grey', pt: 6 }}
                xs={12}
                sm={9}
              >
                <Grid container>
                  <Grid item xs={12} md={3} alignSelf="center">
                    {editData?.case?._id && (
                      <Typography variant="h4">Folder Name*</Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={8} sx={{ bgcolor: 'gray' }}>
                    {editData?.case?._id && (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Folder
                          </InputLabel>
                          <Select
                            labelId="folder"
                            id="folder"
                            name="folder"
                            options={caseFolder}
                            value={formik.values?.folder}
                            label="Folder"
                            onChange={formik.handleChange}
                            error={
                              formik.touched.folder &&
                              Boolean(formik.errors.folder)
                            }
                            helperText={
                              formik.touched.folder && formik.errors.folder
                            }
                          >
                            {caseFolder.map((folder) => {
                              return (
                                <MenuItem
                                  value={
                                    folder._id === undefined ||
                                    folder._id === null ||
                                    caseFolder.length === 0
                                      ? ''
                                      : folder._id
                                  }
                                >
                                  {folder.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {formik.touched.folder && formik.errors.folder ? (
                            <FormHelperText
                              sx={{
                                color: '#bf3333',
                                marginLeft: '16px !important',
                              }}
                            >
                              {formik.touched.folder && formik.errors.folder}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Doc Name*</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      id="docName"
                      name="docName"
                      label="Doc Name"
                      variant="outlined"
                      sx={{ bgcolor: 'gray' }}
                      value={formik.values.docName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.docName && Boolean(formik.errors.docName)
                      }
                      helperText={
                        formik.touched.docName && formik.errors.docName
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Assigned Date</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="assignedDate"
                        name="assignedDate"
                        onChange={(value) =>
                          formik.setFieldValue('assignedDate', value)
                        }
                        value={dayjs(formik.values.assignedDate)}
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4"></Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {formik.values?.tags.length > 0 &&
                      formik.values.tags?.map((tg) => (
                        <List orientation="horizontal" variant="outlined">
                          <ListItem sx={{ mb: -3 }} disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                <CloseIcon
                                  onClick={(e) => {
                                    formik.setFieldValue(
                                      'tags',
                                      formik.values.tags.filter((e) => e !== tg)
                                    );
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={tg} />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      ))}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Tags</Typography>
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <TextField
                      fullWidth
                      id="tag"
                      name="tag"
                      value={formik.values.tag}
                      onChange={formik.handleChange}
                      variant="outlined"
                      error={formik.touched.tag && Boolean(formik.errors.tag)}
                      helperText={formik.touched.tag && formik.errors.tag}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} alignSelf="center">
                    <Button
                      color="#00695c"
                      variant="h4"
                      onClick={() => addTags()}
                    >
                      Add Tags
                    </Button>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 8 }}>
                  <Grid item xs={12} md={3} alignSelf="center">
                    <Typography variant="h4">Description</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        variant="outlined"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        multiline
                        rows={4}
                      />
                      <Typography mt={4}>
                        This description will be viewable by anyone this
                        document is shared with.
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} sx={{ p: 2 }}>
                {editData?.case?.clients?.length > 0 ||
                editData?.firmUserAccess.length > 0 ? (
                  <>
                    <List
                      dense
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                      }}
                    >
                      {editData?.case?.clients
                        ? editData?.case?.clients.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value._id}`;
                            return (
                              <>
                                <ListItem
                                  key={value._id}
                                  secondaryAction={
                                    <Checkbox
                                      edge="end"
                                      onChange={handleToggle(value)}
                                      checked={checked.indexOf(value) !== -1}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemIcon>
                                    <AccountCircleIcon />
                                  </ListItemIcon>
                                  <ListItemButton>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        fontSize: '17px',
                                        p: 1,
                                      }}
                                      id={labelId}
                                      primary={`${value.firstName} ${value.lastName}`}
                                    />
                                  </ListItemButton>
                                </ListItem>
                                <Box
                                  sx={{
                                    borderBottom:
                                      '1px dotted #d3c3c4!important',
                                  }}
                                ></Box>
                              </>
                            );
                          })
                        : firmMembers?.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value._id}`;
                            return (
                              <>
                                <ListItem
                                  key={value._id}
                                  secondaryAction={
                                    <Checkbox
                                      edge="end"
                                      onChange={handleToggle(value)}
                                      checked={checked.indexOf(value) !== -1}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemIcon>
                                    <AccountCircleIcon />
                                  </ListItemIcon>
                                  <ListItemButton>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        fontSize: '17px',
                                        p: 1,
                                      }}
                                      id={labelId}
                                      primary={`${value.firstName} ${value.lastName}`}
                                    />
                                  </ListItemButton>
                                </ListItem>
                                <Box
                                  sx={{
                                    borderBottom:
                                      '1px dotted #d3c3c4!important',
                                  }}
                                ></Box>
                              </>
                            );
                          })}

                      {!isFirmEditing && (
                        <ListItem key={value} disablePadding>
                          <ListItemIcon>
                            <BalanceIcon />
                          </ListItemIcon>
                          <ListItemButton>
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: '17px',
                              }}
                              primary={`This item will be automatically shared with all firm users
                    linked to ${editData?.case?.caseName} .`}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </List>
                  </>
                ) : (
                  <Typography variant="h4" ml={4}>
                    Select a Case Link to configure sharing
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: {
                  xs: 'center',
                  md: 'end',
                },
                pt: 8,
                pr: 10,
                borderTop: '1px dotted #d3c3c4!important',
              }}
            >
              <Button
                sx={{
                  width: 220,
                  height: 40,
                  borderRadius: '30px',
                  color: 'white',
                  bgcolor: 'primary.main',
                  ':hover': {
                    color: 'white',
                    bgcolor: 'primary.main',
                  },
                }}
                type="submit"
              >
                Update Document
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditDocument;
