import React, { useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import theme from '../../utility/theme';
import AddDocument from '../documents/AddDocument';

import { UploadFile, Close, ContentCopy } from '@mui/icons-material';

const CreateNewAccountModal = ({
  open,
  onClose,
  onSubmit,
  caseInformation,
  type,
}) => {
  const [showUplod, setUploadDialog] = useState(false);
  const [multi, showMulti] = useState(false);

  return (
    <Dialog sx={{ padding: 6 }} open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: theme.typography.h3 }}>
          Add Document
        </DialogTitle>
        <DialogTitle onClick={() => onClose()}>
          <Close />
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3">
            How would you like to add your document?
          </Typography>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box
            sx={{
              border: '1px solid #bdbdbd',
              minWidth: 250,
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => setUploadDialog(true)}
              sx={{ color: 'primary.main' }}
            >
              <UploadFile sx={{ fontSize: '80px' }} />
            </IconButton>
            <Typography variant="h6"> Upload one document</Typography>
            {/* <AddDocument
                open={showUplod}
                handleAddDocument={() => setUploadDialog(false)}
              /> */}
          </Box>
          <Box
            sx={{
              border: '1px solid #bdbdbd',
              minWidth: 250,
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => showMulti(true)}
              sx={{ color: 'primary.main' }}
            >
              <ContentCopy sx={{ fontSize: '80px' }} />
            </IconButton>
            <Typography variant="h6"> Upload multiple documents</Typography>
            <AddDocument
              open={showUplod}
              multi={multi}
              caseDetails={caseInformation}
              setUploadDialog={() => setUploadDialog(false)}
              setShowMultiUploadDialog={() => showMulti(false)}
              type={type}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewAccountModal;
