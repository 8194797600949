import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  UploadFile,
  Close,
  ContentCopy,
  PostAdd,
  Inbox,
} from '@mui/icons-material';

import AddDocument from '../../documents/AddDocument';

import theme from '../../../utility/theme';
import { useDispatch, useSelector } from 'react-redux/es';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import api from '../../../utility/api';
import { loadFirmFolderListAction } from '../../../redux/firmFolder/firmFolderAction';
import UploadFirmDocument from '../../documents/UploadFirmDocument';
import { loadCaseDocumentListAction } from '../../../redux/caseDocument/caseDocumentAction';
import { loadCaseFolderListAction } from '../../../redux/caseFolder/caseFolderAction';

export const CreateNewFolder = ({
  edit,
  editData,
  open,
  onClose,
  caseDetails,
  type,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user.user);
  const onSubmit = async (values, actions) => {
    let formData = {
      type: type,
      name: values?.folder,
      caseId: caseDetails._id,
      createdBy: user._id,
      firmId: caseDetails.firm,
    };
    async function apiCall() {
      try {
        let response;

        if (edit) {
          response = await api.patch(
            `/cases/${caseDetails._id}/firms/${user.firm}/caseFolders/${editData._id}`,
            formData
          );
        } else {
          response = await api.post(`cases/folders`, formData);
        }

        if (response) {
          dispatch(loadCaseFolderListAction(response.data.data.docAndFolders));

          formik.resetForm({});
          onClose();
        }
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, 'error'));
      }
    }

    apiCall();
  };

  const initialValues = {
    folder: edit ? editData.name : '',
  };

  const folderValidationSchema = yup.object().shape({
    folder: yup.string('Enter folder name').required('Required'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: folderValidationSchema,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{
        padding: 6,
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: theme.typography.h3 }}>
          {edit ? 'UpdateFolder' : 'Add Folder'}
        </DialogTitle>
        <DialogTitle onClick={() => onClose()}>
          <Close />
        </DialogTitle>
      </Box>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            labelId="folder"
            id="folder"
            fullWidth
            name="folder"
            value={formik.values?.folder}
            label="Folder"
            onChange={formik.handleChange}
            error={formik.touched.folder && Boolean(formik.errors.folder)}
            helperText={formik.touched.folder && formik.errors.folder}
          />
          <Box sx={{ textAlign: 'end', mt: 4 }}>
            <Button type="submit" variant="contained">
              {!edit ? 'Add Folder' : 'Update Folder'}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewFolder;
