import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Loader from '../loader/Loader';

const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { isLoading, isAuthenticated } = auth;
  return isLoading ? (
    <Box>
      <Loader />
    </Box>
  ) : isAuthenticated ? (
    children
  ) : (
    <Navigate to="/signin" state={{ from: location }} />
  );
};

export default PrivateRoute;
