import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { showSnackbarAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      email: values.email,
    };

    try {
      const response = await api.post(`auth/resetPassword/step1`, formData);
      setMessage(response.data.message);
      setEmailSent(true);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <>
      {!emailSent && (
        <Paper sx={{ p: 10, marginTop: 30, height: "35vh" }}>
          <Typography variant="h3">Forgot Password? </Typography>
          <Typography sx={{ mt: 2 }} variant="h5">
            Don't worry! Happens to the best of us.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
              <Box my={4}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="text"
                  fullWidth
                  placeholder="Enter your email"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>

              <Button variant="contained" type="submit">
                Send
              </Button>
            </Stack>
          </form>
        </Paper>
      )}
      {emailSent && (
        <Paper sx={{ height: 200, width: 580, padding: 10, mt: 30 }}>
          <Typography variant="h3" mb={8}>
            {message}
          </Typography>
          <Button
            variant="contained"
            sx={{ width: "100%" }}
            onClick={() => navigate("/signin")}
          >
            Ok
          </Button>
        </Paper>
      )}
    </>
  );
};

export default ForgotPassword;
