import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <Box>
      {state?.signup && (
        <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
          <Button
            onClick={() => navigate('/signup')}
            variant="text"
            sx={{ color: '#21766D' }}
          >
            <ArrowBackIosNew /> Back to Signup
          </Button>
        </Box>
      )}
      <Stack>
        <Typography variant="h1" mb={15} textAlign="center" color="#1976d2">
          Our Terms of Service
        </Typography>
        <Box
          sx={{
            textAlign: 'left',
            letterSpacing: 0.5,
            color: '#42a5f5',
          }}
        >
          <Typography variant="h3" mb={4}>
            These Terms of Service and Privacy Policy describe how the Daily Doc
            Technologies LLC ("Daily Doc LLC") mobile application ("Daily Doc
            Healthcare App", or "our App") for the health care system in which
            you have been authorized for its use ("Health Care System"), shall
            be used, and how selected information is handled within our App.
          </Typography>
          <Typography variant="h3" mb={4}>
            These Terms of Service and Privacy Policy describe how the Daily Doc
            As a condition of using our App, you acknowledge and agree to the
            following Terms of Service:
          </Typography>
          <Typography variant="h3" mb={4}>
            1. Authorized Provider. You are an “Authorized Provider if you are a
            participating physician, nurse, clinician, therapist or other health
            care provider in the Health Care System in which you have been
            authorized to use our App and have been duly provisioned with a User
            ID and password permitting access to our App.
          </Typography>
          <Typography variant="h3" mb={4}>
            2. Compliance with Law. You have access to our App and its content
            only in accordance with all applicable laws, rules and regulations.
          </Typography>
          <Typography variant="h3" mb={4}>
            3. Appropriate use. Our App is a private service for Authorized
            Providers and it may not be accessed, nor may the content used, by
            patients or members of the general public. Only Authorized Providers
            are permitted to use our App and the content therein.
          </Typography>
          <Typography variant="h3" mb={4}>
            4. Security. Our App has reasonable security measures in place to
            help protect against the loss, misuse, or alteration of information
            under our control. These measures include encryption of personal
            information using the Secure Socket Layer (SSL) system during your
            log-in session, and using a secured messaging service when we send
            you personal information electronically. Despite these measures, the
            confidentiality of any communication or material transmitted to or
            from Authorized Providers within our App cannot be guaranteed. You
            should access our App only from a device that you know is secure and
            that has appropriate anti-malware software running.
          </Typography>
          <Typography variant="h3" mb={4}>
            5. Information available. Our App allows Authorized Providers to
            view treatment-related information about their patients and related
            aggregate information. All of this is deemed part of the content and
            constitutes confidential information.
          </Typography>
          <Typography variant="h3" mb={4}>
            6. User ID and password. The administrator of our App, which may
            also include the owner and provider of any related website, will,
            upon appropriate authentication and determination that you are a
            care provider authorized to use our App, issue you a User ID and
            password that is unique to you. You may never share your User ID and
            password with anybody else. A User ID and password permitting access
            to our App is a privilege that may be revoked at any time by the
            administrator of our App in his or her sole discretion.
          </Typography>
          <Typography variant="h3" mb={4}>
            7. Responsibility for User ID and password. You are solely
            responsible for any activities related to your User ID and password.
            You are required to immediately report to the administrator of our
            App any loss, disclosure or inappropriate access to your Daily Doc
            account, User ID or password, treatment-related information or
            patient-related file, reports, or other confidential content to
            which you or another person should not have access or when you
            suspect such or the likelihood of such.
          </Typography>
          <Typography variant="h1" mb={4} textAlign="center">
            Privacy Policy
          </Typography>
          <Typography variant="h5" color="black" mb={4}>
            Overview
          </Typography>
          <Typography variant="h3" mb={4}>
            Daily Doc Technologies LLC ("Daily Doc LLC") takes very seriously
            its obligation to protect the confidentiality of patients and
            providers personal information. Our App is intended to connect to
            servers and systems operated and maintained by the Health Care
            System in which you are an Authorized Provider, including but not
            limited to EPIC, Amion.com or other electronic health records,
            scheduling applications, paging and or messaging systems and the
            active directory of the Health Care System, in order to provide
            secure, mobile access to those applications, to patient health
            information, to other Authorized Providers within the Health Care
            System, and to providers competencies and availability.
          </Typography>
          <Typography variant="h3" mb={4}>
            This Privacy Policy describes how Daily Doc LLC and Daily Doc ("our
            App") uses, stores and transmits information and data within the
            Health Care System. By using our App to connect to other providers
            and the Health Care System, you consent to this Privacy Policy.
            Daily Doc LLC may modify this Privacy Policy at any time effective
            upon its posting. Your continued use of our App constitutes your
            acceptance of this Privacy Policy and any updates. Your use of our
            App is subject to the linked applications (such as EPIC, Cerner or
            other Electronic heath records, Amion.com, American messaging, and
            others) End User License Agreements.{' '}
          </Typography>
          <Typography variant="h5" color="black" mb={4}>
            Personal Information
          </Typography>
          <Typography variant="h3" mb={4}>
            Daily Doc LLC does not sell or license any information that it may
            collect from you from using our App. Except for those items listed
            below, our App does not store any of your own or your patients
            personal information on your device. Daily Doc LLC attempts to
            minimize the amount of your and your patients personal or health
            information stored or retained on your device. Nevertheless, our App
            may:{' '}
          </Typography>
          <Typography variant="h3" mb={4}>
            Store patient personal health information entered into any messages
            sent through our App in your communications with other Authorized
            Providers. Store a copy of your profile, and your picture on your
            device if you choose to add a picture to your profile. Temporarily
            store your or your patients personal information in memory or on the
            device while you use our App.
          </Typography>{' '}
        </Box>
      </Stack>
    </Box>
  );
};

export default PrivacyPolicy;
