import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import authReducer from './auth/authReducer';
import listReducer from './list/listReducer';
import alertReducer from './alert/alertReducer';
import contactReducer from './contacts/contactsReducer';
import caseReducer from './cases/caseReducer';
import leadReducer from './lead/leadReducer';
import taskReducer from './task/taskReducer';
import compnayReducer from './comapny/companyReducer';
import noteReducer from './Notes/NotesReducer';
import documentReducer from './document/documentReducer';
import firmFolderReducer from './firmFolder/firmFolderReducer';
import firmDocumentReducer from './firmDocuments/firmDocumentReducer';
import caseDocumentReducer from './caseDocument/caseDocumentReducer';
import caseFolderReducer from './caseFolder/caseFolderReducer';
import caseBillDocumentReducer from './caseBill/caseBillReducer';
import caseNoteReducer from './caseNote/caseNoteReducer';
import caseTaskReducer from './caseTask/caseTaskReducer';
import billDocumentReducer from './bill/billReducer';
import firmMemberReducer from './FirmMembers.js/firmMemberReducer';

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['authReducer'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  list: listReducer,
  snackbar: alertReducer,
  contact: contactReducer,
  case: caseReducer,
  lead: leadReducer,
  task: taskReducer,
  company: compnayReducer,
  note: noteReducer,
  document: documentReducer,
  firmFolder: firmFolderReducer,
  firmDocument: firmDocumentReducer,
  caseDocument: caseDocumentReducer,
  caseFolder: caseFolderReducer,
  caseBillDocument: caseBillDocumentReducer,
  caseNote: caseNoteReducer,
  caseTask: caseTaskReducer,
  bill: billDocumentReducer,
  firmMember: firmMemberReducer,
});

export default persistReducer(persistConfig, rootReducer);
