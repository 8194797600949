import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import CountryAutocomplete from '../reusables/CountryAutocomplete';
import StateAutocomplete from '../reusables/StateAutocomplete';
import CityAutocomplete from '../reusables/CityAutocomplete';

import {
  loadCityListAction,
  loadStateListAction,
  removeCityListAction,
  removeStateListAction,
} from '../../redux/list/listAction';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { countryList } from '../../utility/countryList';

import { loadComanyListAction } from '../../redux/comapny/companyActions';
import api from '../../utility/api';

const websiteRegax = new RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
);

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const zipCodeRegax = /^[1-9][0-9]{5}$/;

const validationSchema = yup.object().shape({
  companyName: yup.string('Enter  Name').trim().required('Required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phone: yup
    .string('Enter phone')
    .matches(phoneRegExp, 'Enter valid number')
    .min(10)
    .max(10),
  zipcode: yup
    .string('Enter zipcode')
    .matches(zipCodeRegax, 'Enter valid zipcod'),
  website: yup
    .string('Enter your password')
    .matches(websiteRegax, 'enter valid url'),
  faxNumber: yup
    .string('Enter fax')
    .matches(/^\+?[0-9]{7,}$/, 'Enter valid fax number'),
});
const AddCompany = ({ open, setShowNote, showCompany, editData }) => {
  const [initialCountry, setInitialCountry] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);

  const user = useSelector((state) => state.auth.user.user);
  const { isStateListLoading, isCityListLoading } = useSelector(
    (state) => state.list
  );

  const dispatch = useDispatch();

  const getCountryCode = async () => {
    try {
      const response = await api.get(`/locations/countryCodes`);
      setCountryCodes(response.data.data.countries);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const loadCountryStateCity = async () => {
    const country = countryList.find((c) => c.value === editData?.country);
    setInitialCountry(country);
    loadStateList(country?.countryId);
  };

  const loadStateList = async (countryId) => {
    try {
      const response = await api.get(`locations/states?countryId=${countryId}`);
      const state = response.data.data.states?.find(
        (s) => s.value === editData.state
      );
      setInitialState(state);
      dispatch(loadStateListAction(response.data.data.states));
      loadCityList(state?.stateId);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  const loadCityList = async (stateId) => {
    try {
      const response = await api.get(`locations/cities?stateId=${stateId}`);
      const city = response.data.data.cities.find(
        (c) => c.value === editData.city
      );
      setInitialCity(city);
      dispatch(loadCityListAction(response.data.data.cities));
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    loadCountryStateCity();
    return () => {
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
    };
  }, []);

  const initialValues = {
    companyName: showCompany ? editData.name : '',
    email: showCompany ? editData.email : '',
    website: showCompany ? editData.website : '',
    countryCode:
      showCompany && countryCodes.length > 0
        ? countryCodes.find((c) => c.value === editData?.countryCode)
        : null,
    phone: showCompany ? editData?.mobile : '',
    address: showCompany ? editData?.address : '',
    address2: showCompany ? editData?.address2 : '',
    country: showCompany ? initialCountry : null,
    state: showCompany ? initialState : null,
    city: showCompany ? initialCity : null,
    zipcode: showCompany ? editData?.zipcode : '',
    note: showCompany ? editData?.privateNote : '',
    faxNumber: showCompany ? editData.faxNumber : '',
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      name: values.companyName,
      email: values.email,
      website: values.website,
      mobile: values.phone,
      countryCode: values.countryCode?.value,
      address: values.address,
      address2: values.address2,
      country: values.country?.label,
      state: values.state?.label,
      city: values.city?.label,
      zipcode: values.zipcode,
      privateNote: values.note,
      firmId: user.firm,
      faxNumber: values.faxNumber,
    };
    if (formData.mobile === '') {
      delete formData.mobile;
    }

    if (showCompany) {
      try {
        const response = await api.patch(
          `clients/company/${editData._id}/firms/${user.firm}`,
          formData
        );
        if (response) {
          dispatch(loadComanyListAction(response.data.data.companies));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          setShowNote(false);
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    } else {
      try {
        const response = await api.post(`clients/company`, formData);
        if (response) {
          dispatch(loadComanyListAction(response.data.data.companies));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          setShowNote(false);
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    getCountryCode();
  }, []);

  return (
    <Dialog
      open={open || showCompany}
      onClose={() => setShowNote(false)}
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          paddingY: 6,
          bgcolor: 'lightgrey',
          borderBottom: '1px solid grey',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h1">
            {showCompany ? 'Edit Company' : 'Add Company'}
          </Typography>
          <CloseIcon onClick={() => setShowNote(false)} />
        </Box>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{ padding: 6, my: 6, borderBottom: '1px solid grey' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4" mt={3}>
                Name*
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                id="companyName"
                name="companyName"
                variant="outlined"
                label="Company"
                fullWidth
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Email*</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                label="xyz@gmail.com"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Website</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                id="website"
                name="website"
                variant="outlined"
                fullWidth
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Phone</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Autocomplete
                value={formik.values?.countryCode}
                id="countryCode"
                name="countryCode"
                options={countryCodes}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.label || ''}
                // isOptionEqualToValue={(option, value) =>
                //   option.value === value.value
                // }
                onChange={(event, newValue) => {
                  formik.setFieldValue('countryCode', newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Country Code " />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6}>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 10 }}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Fax Number</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                id="faxNumber"
                name="faxNumber"
                variant="outlined"
                fullWidth
                value={formik.values.faxNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.faxNumber && Boolean(formik.errors.faxNumber)
                }
                helperText={formik.touched.faxNumber && formik.errors.faxNumber}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Address</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                id="address"
                name="address"
                variant="outlined"
                fullWidth
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Address2</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                id="address2"
                name="address2"
                variant="outlined"
                fullWidth
                value={formik.values.address2}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Country</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <CountryAutocomplete formik={formik} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">State,City,Zip Code</Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={4}>
              <StateAutocomplete
                formik={formik}
                countryId={formik.values.country?.countryId}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <CityAutocomplete
                formik={formik}
                stateId={formik.values.state?.stateId}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <TextField
                id="zipcode"
                name="zipcode"
                label="Zip code"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 6 }}
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                helperText={formik.touched.zipcode && formik.errors.zipcode}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Private Notes</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                id="note"
                name="note"
                variant="outlined"
                fullWidth
                value={formik.values.note}
                onChange={formik.handleChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'center',
                md: 'end',
              },
              pr: 10,
            }}
          >
            <Button
              sx={{
                width: 150,
                height: 50,
                borderRadius: '30px',
                border: '2px solid grey',
                mr: 2,
                mb: {
                  xs: 4,
                },
                color: 'grey',
              }}
              type="submit"
            >
              Save & Close
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCompany;
