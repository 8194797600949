import { GET_ALL_FIRMDOCUMENT, ADD_FIRMDOCUMENT } from './firmDocumentTypes';

const INITIAL_STATE = {
  firmDocumentList: [],
  addFirmDocument: {},
};

const firmDocumentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FIRMDOCUMENT:
      return {
        ...state,
        addFirmDocument: action.payload,
      };
    case GET_ALL_FIRMDOCUMENT:
      return {
        ...state,
        firmDocumentList: action.payload,
      };

    default:
      return state;
  }
};

export default firmDocumentReducer;
