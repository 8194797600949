import { GET_ALL_CASEDOCUMENT, ADD_CASEDOCUMENT } from './caseDocumentTypes';

export const loadCaseDocumentListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CASEDOCUMENT,
    payload: data,
  });
};

export const loadCaseAddDocumentAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_CASEDOCUMENT,
    payload: data,
  });
};
