import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { removeContactAction } from '../../redux/contacts/contactsActions';

const Staff = ({ formik }) => {
  const [firmMembers, setFirmMembers] = useState([]);
  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();

  let options =
    firmMembers.length > 0 &&
    firmMembers.map((val) => {
      let obj = {
        label: `${val.firstName} ${val.lastName}`,
        value: val._id,
      };
      return obj;
    });

  const getFirmMembers = async (firmID) => {
    try {
      const response = await api.get(`attornies/firmMembers/${firmID}`);
      if (response) {
        setFirmMembers(response.data.data.users);
        dispatch(removeContactAction());
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getFirmMembers(user.firm);
  }, [user.firm]);
  return (
    <>
      <Box sx={{ mt: 7 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography sx={{ mt: 3 }} variant="h4">
              Lead Attorney*
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Autocomplete
              value={formik.values?.leadAttornies}
              id="leadAttornies"
              name="leadAttornies"
              options={options}
              getOptionLabel={(option) => option.label || null}
              onChange={(event, newValue) => {
                formik.setFieldValue('leadAttornies', newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lead Attornies"
                  error={
                    formik.touched.leadAttornies &&
                    Boolean(formik.errors.leadAttornies)
                  }
                  helperText={
                    formik.touched.leadAttornies && formik.errors.leadAttornies
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }} container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography sx={{ mt: 3 }} variant="h4">
              Originating Attorney*
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Autocomplete
              value={formik.values?.originatingAttornies}
              id="originatingAttornies"
              name="originatingAttornies"
              options={options}
              getOptionLabel={(option) => option.label || null}
              onChange={(event, newValue) => {
                formik.setFieldValue('originatingAttornies', newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Originating Attornies"
                  error={
                    formik.touched.originatingAttornies &&
                    Boolean(formik.errors.originatingAttornies)
                  }
                  helperText={
                    formik.touched.originatingAttornies &&
                    formik.errors.originatingAttornies
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: 3 }} container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography sx={{ mt: 3 }} variant="h4">
              Firm members
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Autocomplete
              value={formik.values?.firmMembers}
              id="staffs"
              name="Firm members"
              multiple
              options={options || []}
              getOptionLabel={(option) => option.label || null}
              onChange={(event, newValue) => {
                formik.setFieldValue('firmMembers', newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField {...params} label="Firm members" />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Staff;
