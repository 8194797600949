import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
const options = [
  { label: "Low", value: 0 },
  { label: "Medium", value: 1 },
  { label: "High", value: 2 },
];

const option1 = [
  { label: "Co-Counsel", value: 0 },
  { label: "Expert", value: 1 },
  { label: "Judge", value: 2 },
  { label: "Unassigned", value: 3 },
];

function AddMoreInfo() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="h4" mt={3}>
          Birth Date
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          type="date"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5} md={5}></Grid>

      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="h4">Company</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={option1}
          sx={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="Company" />}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={6}>
        <Typography variant="h4" color="green" mt={3}>
          Add new company
        </Typography>
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="h4">Job</Typography>
      </Grid>
      <Grid item xs={12} sm={9} md={9}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Job Title"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="h4">Driver license,State</Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={4}>
        <TextField
          id="outlined-basic"
          label="license"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          sx={{ width: "100%" }}
          renderInput={(params) => <TextField {...params} label="State" />}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}></Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="h4">Website</Typography>
      </Grid>
      <Grid item xs={12} sm={9} md={9}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Website"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="h4">Fax Number</Typography>
      </Grid>
      <Grid item xs={12} sm={9} md={9}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Fax"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Typography variant="h4">Note</Typography>
      </Grid>
      <Grid item xs={12} sm={9} md={9}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Note"
          fullWidth
        />
        <Typography mt={2}>These are never visible to this contact.</Typography>
      </Grid>
    </Grid>
  );
}

export default AddMoreInfo;
