import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import AddOneDocument from './AddOneDocument';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';

import AddMutliDocuments from './AddMutliDocuments';

import { loadCaseListAction } from '../../redux/cases/caseActions';
import api from '../../utility/api';

const AddDocument = ({
  open,
  multi,
  setShowMultiUploadDialog,
  setUploadDialog,
  editMode,
  setEditMode,
  caseDetails,
  type,
}) => {
  const [value, setValue] = useState(0);

  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCases = async (firmID) => {
    try {
      const response = await api.get(`/cases/firms/${firmID}`);
      if (response) {
        dispatch(loadCaseListAction(response.data.data.case));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getCases(user.firm);
  }, [user.firm]);

  return (
    <Dialog
      open={open || multi}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      sx={{ padding: 4 }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          borderBottom: '1px dotted #d3c3c4!important',
          bgcolor: '#f5f5f5',
          py: 5,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h2">
                {multi ? 'Upload Multi Document' : 'Upload One Document'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h2">{multi ? '' : 'Sharing'}</Typography>
              <Typography
                onClick={() => {
                  setShowMultiUploadDialog();
                  setUploadDialog();
                }}
              >
                <CloseIcon />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {open && (
          <AddOneDocument
            open={open}
            caseDetails={caseDetails}
            setUploadDialog={() => setUploadDialog()}
            type={type}
          />
        )}
        {multi && (
          <AddMutliDocuments
            multi={multi}
            caseDetails={caseDetails}
            setShowMultiUploadDialog={() => setShowMultiUploadDialog()}
            type={type}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddDocument;
