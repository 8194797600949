import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountTabsWrapper from './AccountTabsWrapper';
import PersonalInfo from './PersonalInfo/index';
import { Fonts } from '../../utility/theme';
import ChangePassword from './ChangePassword';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabs = [
  { id: 1, name: 'Personal Info' },
  {
    id: 2,
    name: 'Change Password',
  },
  //   {
  //     id: 3,
  //     icon: <IoMdInformationCircleOutline />,
  //     name: <IntlMessages id="common.information" />,
  //   },
  //   {
  //     id: 4,
  //     icon: <IoShareSocialOutline />,
  //     name: <IntlMessages id="common.social" />,
  //   },
  //   {
  //     id: 5,
  //     icon: <NotificationsNoneIcon />,
  //     name: <IntlMessages id="healthCare.notification" />,
  //   },
];

const Account = () => {
  const [value, setValue] = React.useState(0);

  const onTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        component="h2"
        variant="h2"
        sx={{
          fontSize: 16,
          color: 'text.primary',
          fontWeight: Fonts.SEMI_BOLD,
          mb: {
            xs: 2,
            lg: 4,
          },
        }}
      >
        My Account
      </Box>

      <AccountTabsWrapper>
        <Tabs
          className="account-tabs"
          value={value}
          onChange={onTabsChange}
          aria-label="basic tabs example"
          orientation="vertical"
        >
          {tabs.map((tab, index) => (
            <Tab
              className="account-tab"
              label={tab.name}
              icon={tab.icon}
              key={index}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Box className="account-tabs-content">
          {value === 0 && <PersonalInfo />}
          {value === 1 && <ChangePassword />}
          {/* {value === 2 && <Information />}
            {value === 3 && <Social social={accountData.member} />}
            {value === 4 && <Notification />}  */}
        </Box>
      </AccountTabsWrapper>
    </>
  );
};

export default Account;
