import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  getIn,
  useFormik,
} from 'formik';
import * as yup from 'yup';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import { loadFirmFolderListAction } from '../../redux/firmFolder/firmFolderAction';
import api from '../../utility/api';
import { loadFirmMemberListAction } from '../../redux/FirmMembers.js/firmMemberAction';
import { Close } from '@mui/icons-material';

const validationSchema = yup.object().shape({
  userDetails: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup
        .string()
        .email('Valid email required.')
        .required('Email is required'),
      userType: yup.string().required('User type is required'),
    })
  ),
});
const InviteFirmMembers = ({
  open,
  setCreateModalOpen,
  showEdit,
  editData,
}) => {
  const user = useSelector((state) => state?.auth?.user?.user);

  const dispatch = useDispatch();

  const initialValues = {
    userDetails: [
      {
        firstName: showEdit ? editData?.firstName : '',
        lastName: showEdit ? editData?.lastName : '',
        middleName: showEdit ? editData?.middleName : '',
        email: showEdit ? editData?.email : '',
        userType: showEdit ? editData?.userType : '',
        active: showEdit ? editData?.active : true,
      },
    ],
    firmId: user?.firm,
  };

  return (
    <Dialog open={open || showEdit} maxWidth="lg">
      <DialogTitle sx={{ p: 6, bgcolor: 'lightgrey' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h2">
            {showEdit ? 'Edit FirmMember' : 'Bulk Add Users'}
          </Typography>
          <CloseIcon onClick={setCreateModalOpen} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: 10 }}>
        <Grid
          container
          sx={{
            pt: 6,
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
            },
          }}
          spacing={6}
        >
          <Grid item md={5}>
            <Typography variant="h3">Name</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="h3">Email</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="h3">User Type</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="h3">Grant Access Now?</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6} sx={{ pt: { xs: 5 } }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              if (showEdit) {
                try {
                  let formdata = {
                    firmId: values?.firmId,
                    ...values.userDetails[0],
                  };

                  console.log({ formdata });
                  const response = await api.patch(
                    `/attornies/${editData?._id}/firms/${user.firm}?userId=${user._id}`,
                    formdata
                  );

                  dispatch(
                    loadFirmMemberListAction(response.data.data.allUsers)
                  );
                  dispatch(
                    showSnackbarAction(response.data.message, 'success')
                  );
                  setCreateModalOpen(false);
                } catch (error) {
                  dispatch(
                    showSnackbarAction(error.response.data.message, 'error')
                  );
                }
              } else {
                try {
                  const response = await api.post(
                    `/attornies/firmMembers`,
                    values
                  );

                  console.log({ values });
                  dispatch(
                    loadFirmMemberListAction(response.data.data.allUsers)
                  );
                  dispatch(
                    showSnackbarAction(response.data.message, 'success')
                  );
                  setCreateModalOpen(false);
                } catch (error) {
                  dispatch(
                    showSnackbarAction(error.response.data.message, 'error')
                  );
                }
              }
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
            }) => (
              <Form noValidate autoComplete="off">
                <FieldArray name="userDetails">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.userDetails.length > 0 &&
                        values.userDetails.map((item, index) => {
                          const firstName = `userDetails[${index}].firstName`;
                          const touchedFirstName = getIn(touched, firstName);
                          const errorFirstName = getIn(errors, firstName);

                          const lastName = `userDetails[${index}].lastName`;
                          const touchedLastName = getIn(touched, lastName);
                          const errorLastName = getIn(errors, lastName);

                          const middleName = `userDetails.[${index}].middleName`;

                          const email = `userDetails[${index}].email`;
                          const touchedEmail = getIn(touched, email);
                          const errorEmail = getIn(errors, email);

                          const userType = `userDetails[${index}].userType`;
                          const touchedUserType = getIn(touched, userType);
                          const errorUserType = getIn(errors, userType);

                          const access = `userDetails.[${index}].active`;

                          return (
                            <Grid
                              container
                              spacing={4}
                              sx={{ mb: 4 }}
                              key={index}
                            >
                              <Grid
                                container
                                item
                                xs={12}
                                sm={6}
                                md={5}
                                spacing={2}
                              >
                                <Grid item xs={12} sm={6} md={5}>
                                  <Typography
                                    variant="h4"
                                    mb={4}
                                    sx={{ display: { md: 'none' } }}
                                  >
                                    Name
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="First name*"
                                    name={firstName}
                                    id={firstName}
                                    value={item.firstName}
                                    onChange={handleChange}
                                    helperText={
                                      touchedFirstName && errorFirstName
                                        ? errorFirstName
                                        : ''
                                    }
                                    error={Boolean(
                                      touchedFirstName && errorFirstName
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                  <TextField
                                    fullWidth
                                    id={middleName}
                                    name={middleName}
                                    label="M"
                                    variant="outlined"
                                    value={item.middleName}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                  <TextField
                                    fullWidth
                                    id={lastName}
                                    name={lastName}
                                    label="Last Name*"
                                    variant="outlined"
                                    value={item.lastName}
                                    onChange={handleChange}
                                    helperText={
                                      touchedLastName && errorLastName
                                        ? errorLastName
                                        : ''
                                    }
                                    error={Boolean(
                                      touchedLastName && errorLastName
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography
                                  variant="h4"
                                  mb={4}
                                  sx={{ display: { md: 'none' } }}
                                >
                                  Email
                                </Typography>
                                <TextField
                                  fullWidth
                                  id={email}
                                  name={email}
                                  label="Email*"
                                  variant="outlined"
                                  value={item.email}
                                  onChange={handleChange}
                                  helperText={
                                    touchedEmail && errorEmail ? errorEmail : ''
                                  }
                                  error={Boolean(touchedEmail && errorEmail)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={2}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    User Type
                                  </InputLabel>
                                  <Select
                                    labelId="userType"
                                    id={userType}
                                    name={userType}
                                    label="User Type"
                                    value={item.userType}
                                    onChange={handleChange}
                                    helperText={
                                      touchedUserType && errorUserType
                                        ? errorUserType
                                        : ''
                                    }
                                    error={Boolean(
                                      touchedUserType && errorUserType
                                    )}
                                  >
                                    <MenuItem value="attorney">
                                      Attorney
                                    </MenuItem>
                                    <MenuItem value="parallegal">
                                      Parallegal
                                    </MenuItem>
                                    <MenuItem value="staff">Staff</MenuItem>
                                  </Select>
                                </FormControl>
                                <Box>
                                  {touchedUserType && errorUserType ? (
                                    <FormHelperText
                                      sx={{
                                        color: '#bf3333',
                                        marginLeft: '16px !important',
                                      }}
                                    >
                                      {touchedUserType && errorUserType}
                                    </FormHelperText>
                                  ) : null}
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    pt: 1,
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={2}>
                                <Typography
                                  variant="h4"
                                  mb={4}
                                  sx={{ display: { md: 'none' } }}
                                >
                                  Grant Access Now?
                                </Typography>
                                <Box sx={{ ml: 4 }}>
                                  <FormControl>
                                    {item.active && (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            id={access}
                                            name={access}
                                            defaultChecked
                                            onChange={handleChange}
                                            value={item.active}
                                          />
                                        }
                                      />
                                    )}
                                    {!item.active && (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            id={access}
                                            name={access}
                                            onChange={handleChange}
                                            value={item.active}
                                          />
                                        }
                                      />
                                    )}
                                  </FormControl>
                                  {index > 0 && (
                                    <Close
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    />
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          );
                        })}
                      {showEdit ? null : (
                        <Button
                          type="button"
                          className="secondary"
                          onClick={() =>
                            push({
                              firstName: '',
                              lastName: '',
                              middleName: '',
                              email: '',
                              userType: '',
                              active: true,
                            })
                          }
                          sx={{ mt: 4 }}
                        >
                          Add more rows
                        </Button>
                      )}
                    </div>
                  )}
                </FieldArray>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    borderTop: '1px solid grey',
                    pt: 4,
                  }}
                >
                  <Button
                    sx={{
                      width: 180,
                      height: 50,
                      borderRadius: '30px',
                      color: 'white',
                      bgcolor: 'primary.main',
                      ':hover': {
                        color: 'white',
                        bgcolor: 'primary.main',
                      },
                    }}
                    type="submit"
                  >
                    {showEdit ? 'Update User' : 'Create User'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InviteFirmMembers;
