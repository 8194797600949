import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import persistReducer from "./rootReducer";
import { persistStore } from "redux-persist";

const initialState = {};
const middleware = [thunk];

export const store = createStore(
  persistReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);

const StoreAndPersistStore = { store, persistStore };

export default StoreAndPersistStore;
