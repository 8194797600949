import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import theme from '../../utility/theme';
import DocumentsTable from './DocumentsTable';
import { useNavigate, useLocation } from 'react-router-dom';
import TemplatesTable from './TemplatesTable';
import FirmTable from './FirmTable';
import ESignatureTable from './ESignatureTable';
import IntakeFormTable from './IntakeFormTable';
import FirmDocumentsTable from './FirmDocumentsTable';
const drawerActiveListBackgroundColor = 'rgba(255,255,255, 0.08)';
const DocumentsHome = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let columns = useMemo(
    () => [
      {
        accessorKey: 'case',
        header: 'Case',
      },
      {
        accessorKey: 'namee',
        header: 'Name',
      },
      {
        accessorKey: 'title', //access nested data with dot notation
        header: 'Document Name',
      },
      {
        accessorKey: 'assignedDate', //normal accessorKey
        header: 'Assigned date',
      },
      {
        accessorKey: 'sharedWith',
        header: 'Shared with',
      },
      {
        accessorKey: 'lastUpdated',
        header: 'Last updated',
      },
    ],
    []
  );

  let eSignColumn = useMemo(
    () => [
      {
        accessorKey: 'case',
        header: 'Case',
      },
      {
        accessorKey: 'namee',
        header: 'Name',
      },
      {
        accessorKey: 'title', //access nested data with dot notation
        header: 'Document Name',
      },
      {
        accessorKey: 'assignedDate', //normal accessorKey
        header: 'Assigned date',
      },
      {
        accessorKey: 'sharedWith',
        header: 'Shared with',
      },
      {
        accessorKey: 'lastupdated',
        header: 'Last updated',
      },
    ],
    []
  );

  let intakeColumn = useMemo(
    () => [
      {
        accessorKey: 'case',
        header: 'Case',
      },
      {
        accessorKey: 'namee',
        header: 'Name',
      },
      {
        accessorKey: 'title', //access nested data with dot notation
        header: 'Document Name',
      },
      {
        accessorKey: 'assignedDate', //normal accessorKey
        header: 'Assigned date',
      },
      {
        accessorKey: 'sharedWith',
        header: 'Shared with',
      },
      {
        accessorKey: 'lastupdated',
        header: 'Last updated',
      },
    ],
    []
  );

  let templatesColumns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'TemplateTitle',
      },
      {
        accessorKey: 'mergeItems',
        header: 'Merge Items',
      },
      {
        accessorKey: 'lastUpdated', //normal accessorKey
        header: 'Last Updated',
      },
    ],
    []
  );

  let data = [
    {
      case: 'Criminal case',
      namee: 'wasim reza',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
  ];

  let eSignData = [
    {
      case: 'Criminal case',
      namee: 'wasim reza',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
  ];

  let intakeData = [
    {
      case: 'Criminal case',
      namee: 'wasim reza',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
    {
      case: 'Criminal case',
      namee: 'wasim',
      title: 'mycase5.pdf',
      assignedDate: '20 apr 2023',
      sharedWith: 'Kahndurao',
      lastupdated: '20 apr 2023',
    },
  ];

  let templateData = [
    {
      title: 'mycase5.pdf',
      mergeItems: ['General'],
      sharedWith: 'Kahndurao',
      lastUpdated: '20 apr 2023',
    },
  ];

  function renderPageContent(render) {
    switch (render) {
      case '/documents/case':
        return <DocumentsTable columns={columns} rows={data} />;
      // case '/documents/templates':
      //   return (
      //     <TemplatesTable columns={templatesColumns} rows={templateData} />
      //   );
      case '/documents/firm':
        return <FirmTable />;
      case '/documents/firm/doc':
        return <FirmDocumentsTable />;
      // case '/documents/eSign':
      //   return <ESignatureTable columns={eSignColumn} rows={eSignData} />;
      // case '/documents/intake':
      //   return <IntakeFormTable columns={intakeColumn} rows={intakeData} />;

      default:
        return <div>Page Not Found</div>;
    }
  }

  return (
    <>
      <Box sx={{ bgcolor: '#eeeeee', marginLeft: 0, marginRight: 0, p: 4 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          textAlign="center"
          spacing={{ xs: 4, sm: 6 }}
          sx={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
        >
          <Typography
            sx={{
              ...(location.pathname === '/documents/case' && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: 'success.main',
                fontWeight: 'medium',
              }),
              '&:hover': {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
            onClick={() => {
              navigate('/documents/case');
            }}
          >
            Case Documents
          </Typography>
          <Typography
            onClick={() => {
              navigate('/documents/firm');
            }}
            sx={{
              ...((location.pathname === '/documents/firm' ||
                location.pathname === '/documents/firm/doc') && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: 'success.main',
                fontWeight: 'medium',
              }),
              '&:hover': {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
          >
            Firm Documents
          </Typography>
          {/* <Typography
            sx={{
              ...(location.pathname === '/documents/eSign' && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: 'success.main',
                fontWeight: 'medium',
              }),
              '&:hover': {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
            onClick={() => {
              navigate('/documents/eSign');
            }}
          >
            eSignature Documents
          </Typography>
          <Typography
            sx={{
              ...(location.pathname === '/documents/intake' && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: 'success.main',
                fontWeight: 'medium',
              }),
              '&:hover': {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
            onClick={() => {
              navigate('/documents/intake');
            }}
          >
            Intake Forms
          </Typography>
          <Typography
            sx={{
              ...(location.pathname === '/documents/templates' && {
                backgroundColor: drawerActiveListBackgroundColor,
                color: 'success.main',
                fontWeight: 'medium',
              }),
              '&:hover': {
                bgcolor: drawerActiveListBackgroundColor,
              },
            }}
            variant="h4"
            component="h4"
            onClick={() => {
              navigate('/documents/templates');
            }}
          >
            Templates
          </Typography> */}
        </Stack>
      </Box>

      {renderPageContent(location.pathname)}
    </>
  );
};

export default DocumentsHome;
