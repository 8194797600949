import Container from '@mui/material/Container';
import SignupPassword from '../../components/signup/SignupPasswordSet';

function SignupPasswordPage() {
  return (
    <Container sx={{ mt: 30 }} maxWidth="xl">
      <SignupPassword />
    </Container>
  );
}

export default SignupPasswordPage;
