import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import MaterialReactTable from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Stack } from '@mui/system';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';

import Loader from '../../loader/Loader';

import { UploadFile, Close, ContentCopy, Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import api from '../../../utility/api';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import { loadCaseTaskListAction } from '../../../redux/caseTask/caseTaskAction';
import AddCaseTask from './AddCaseTask';
import TaskDetails from './TaskDetails';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CaseTaskTable = ({ caseInformation }) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openTask, setOpenTask] = useState(false);

  const user = useSelector((state) => state.auth.user.user);
  const taskList = useSelector((state) => state.caseTask?.caseTaskList);

  const dispatch = useDispatch();

  const getAllTask = async (firmID) => {
    try {
      const response = await api.get(
        `/tasks/firms/${firmID}?caseId=${caseInformation?._id}&&type=case`
      );

      if (response) {
        dispatch(loadCaseTaskListAction(response.data.data.tasks));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const filterEditData = async (id) => {
    try {
      const response = await api.get(`tasks/firms/${user.firm}/tasks/${id}`);
      // console.log("dddddddd", response.data.data);
      setEditData(response.data.data.task);
    } catch (error) {
      //   dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  let columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'priority',
        header: 'Priority',
      },
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      //   Cell: ({ renderedCellValue, row }) => {
      //     return row.original.status == 'open' ? (
      //       <Typography
      //         sx={{ fontSize: '12px', fontWeight: 'bold', color: '#21766D' }}
      //         variant="p"
      //       >
      //         Pending
      //       </Typography>
      //     ) : (
      //       <Typography sx={{ fontSize: '12px', color: '#21766D' }} variant="p">
      //         Completed
      //       </Typography>
      //     );
      //   },
      // },
      {
        header: 'Status',
        accessorFn: (originalRow) =>
          originalRow.status === 'open' ? 'false' : 'true', //must be strings
        id: 'status',
        filterVariant: 'checkbox',
        Cell: ({ renderedCellValue, row }) => {
          return row.original.status == 'open' ? (
            <Typography
              sx={{ fontSize: '12px', fontWeight: 'bold', color: '#21766D' }}
              variant="p"
            >
              Pending
            </Typography>
          ) : (
            <Typography sx={{ fontSize: '12px', color: '#21766D' }} variant="p">
              Completed
            </Typography>
          );
        },
        // cell.getValue() === 'true' ? 'Pending' : 'Completed',
      },
      {
        accessorFn: (row) => new Date(row.dueDate),
        header: 'Due date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) =>
          cell?.getValue() ? cell.getValue()?.toLocaleDateString() : '',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: 'Filter Mode: Less Than',
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  let tableData = [];

  const deleteTask = async (taskID) => {
    try {
      const response = await api.delete(
        `/tasks/firms/${user.firm}/tasks/${taskID}?caseId=${caseInformation?._id}&&type=case`
      );
      console.log({ response });
      if (response) {
        dispatch(loadCaseTaskListAction(response.data.data.tasks));
        dispatch(showSnackbarAction(response.data.message, 'success'));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getEditData = async (ID) => {
    let editData = taskList?.find((d) => d._id === ID);

    setEditData(editData);
  };

  useEffect(() => {
    getAllTask(user.firm);
  }, [user.firm]);

  return (
    <>
      <Box sx={{ marginTop: 4 }}>
        {openTask && (
          <TaskDetails
            open={openTask}
            onClose={() => setOpenTask(false)}
            caseDetails={caseInformation}
            task={editData}
          />
        )}
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columns}
          data={taskList || []}
          editingMode="modal" //default
          enableColumnOrdering
          // enableEditing
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              getEditData(row.original?._id);
              setOpenTask(true);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          positionActionsColumn="last"
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                }, 1000);
                filterEditData(row.original._id);

                setShowEdit(!showEdit);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography
                onClick={() => {
                  getEditData(row.original._id);
                  setShowEdit(true);
                }}
                ml={2}
              >
                Edit
              </Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteTask(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={2}>Delete</Typography>
            </MenuItem>,
          ]}
          renderTopToolbarCustomActions={() => (
            <Button
              onClick={() => setShowAddTask(!showAddTask)}
              variant="contained"
            >
              Add Task
            </Button>
          )}
        />
      </Box>

      {showAddTask && (
        <AddCaseTask
          showAddTask={showAddTask}
          onClose={() => setShowAddTask(false)}
          showEdit={false}
          editData={{}}
          caseDetails={caseInformation}
          type={'case'}
        />
      )}

      {showEdit && (
        <AddCaseTask
          showAddTask={showEdit}
          onClose={() => setShowEdit(false)}
          showEdit={showEdit}
          editData={editData}
          caseDetails={caseInformation}
          type={'case'}
        />
      )}
      {/* {showAddTask && (
        <Task
          showAddTask={showAddTask}
          setShowAddTask={() => setShowAddTask(!showAddTask)}
        />
      )} */}
      {/* {isLoading ? (
        <Loader />
      ) : (
        showEdit && (
          <Task
            showEdit={showEdit}
            setShowEdit={() => setShowEdit(!showEdit)}
            editData={editData}
          />
        )
      )} */}
    </>
  );
};

export default CaseTaskTable;
