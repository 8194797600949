import { GET_ALL_CASEBILLDOCUMENT, ADD_CASEBILLDOCUMENT } from './caseBillType';

export const loadCaseBillDocumentListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CASEBILLDOCUMENT,
    payload: data,
  });
};

export const loadCaseBillAddDocumentAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_CASEBILLDOCUMENT,
    payload: data,
  });
};
