import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import api from "../../utility/api";
import { loadStateListAction } from "../../redux/list/listAction";
import { showSnackbarAction } from "../../redux/alert/alertAction";

export default function StateAutocomplete({ formik, countryId }) {
  const stateList = useSelector((state) => state.list.stateList);
  const dispatch = useDispatch();

  const loadStateList = async (countryId) => {
    const url = `locations/states?countryId=${countryId}`;
    try {
      const response = await api.get(url);
      dispatch(loadStateListAction(response.data.data.states));
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  useEffect(() => {
    loadStateList(countryId);
  }, [countryId]);

  return (
    <>
      <Autocomplete
        options={stateList}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="state"
        value={formik.values.state}
        onChange={(event, newValue) => {
          if (formik.values.state?.stateId !== newValue?.stateId) {
            formik.setFieldValue("city", null);
          }
          formik.setFieldValue("state", newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="State"
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        )}
      />
    </>
  );
}
