import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Divider,
  Switch,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CountryAutocomplete from '../reusables/CountryAutocomplete';
import StateAutocomplete from '../reusables/StateAutocomplete';
import CityAutocomplete from '../reusables/CityAutocomplete';

import {
  loadCityListAction,
  loadStateListAction,
  removeCityListAction,
  removeStateListAction,
} from '../../redux/list/listAction';
import { timezones } from '../../utility/commonUtil';

import { countryList } from '../../utility/countryList';

import theme from '../../utility/theme';
import SwitchComp from '../../utility/switch/Switch';

import { showSnackbarAction } from '../../redux/alert/alertAction';

import api from '../../utility/api';
import { loadleadListAction } from '../../redux/lead/leadActions';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const zipCodeRegax = /^[1-9][0-9]{5}$/;

export default function AddLead({
  open,
  onClose,
  showEdit,
  setShowEdit,
  editData,
}) {
  const [showRefral, setShowRefral] = useState(false);
  const [moreInfo, setShowMoreInfo] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [practiceAreaList, setPracticeAreaList] = useState([]);
  const [firmMemberList, setFirmMemberList] = useState('');
  const [editCountryCodes, setEditCountryCodes] = useState({});
  const [initialCountry, setInitialCountry] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);

  const validationSchema = yup.object().shape({
    firstName: yup.string('Enter First Name').trim().required('Required'),
    lastName: yup.string('Enter Last Name').trim().required('Required'),
    zipcode: yup
      .string('Enter zipcode')
      .matches(zipCodeRegax, 'Enter valid zipcod'),

    email: yup.mixed().test('required', 'Required', (form) => {
      if (formik.values?.mobile > 0 || formik.values?.mobile != '') {
        return true;
      }

      if (formik.values?.email > 0 || formik.values?.email != '') {
        return true;
      }
      return false;
    }),

    // email: yup.string('Enter your email').when('mobile', {
    //   is: (value) => {
    //     if (value === null || value === 'undefined') {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
    //   then: yup
    //     .string('Enter your email')
    //     .email('Provide a valid email.')
    //     .required('Required'),
    // }),

    mobile: yup
      .string('Enter Mobile')
      .matches(phoneRegExp, 'Enter valid number')
      .min(10)
      .max(10),
    workMobile: yup
      .string('Enter Mobile')
      .matches(phoneRegExp, 'Enter valid number')
      .min(10)
      .max(10),
    potentialValueOfCase: yup
      .number()
      .test(
        'maxDigits',
        'Must be greater than or equal to one',
        (number) => String(number) >= 1
      ),

    potentialValueOfCase: yup
      .mixed()
      .test(
        'required',
        'Potential case value must be greater than 0',
        (form) => {
          console.log(formik.values.potentialValueOfCase);
          if (formik.values.potentialValueOfCase <= 0) {
            return false;
          }

          return true;
        }
      ),
  });

  const user = useSelector((state) => state.auth.user.user);

  const dispatch = useDispatch();

  const getPracticeArea = async () => {
    try {
      const response = await api.get(`/cases/practicearea`);
      setPracticeAreaList(response.data.data.allPracticeArea);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  const getFirmMembers = async (firmID) => {
    try {
      const response = await api.get(`/attornies/firmMembers/${firmID}`);
      setFirmMemberList(response.data.data.users);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  const editCountryCode = countryCodes?.find(
    (item) => item.value === editData?.workCountryCode
  );

  const initialValues = {
    firstName: showEdit ? editData?.firstName : '',
    lastName: showEdit ? editData?.lastName : '',
    middleName: showEdit ? editData?.middleName : '',
    email: showEdit ? editData?.user?.email : '',
    mobile: showEdit && editData?.user?.mobile ? editData?.user?.mobile : '',
    countryCode:
      showEdit && countryCodes.length > 0 && editData?.user?.countryCode
        ? countryCodes.find((c) => c.value === editData?.user?.countryCode)
        : null,
    dateAdded: showEdit ? editData?.dateAdded : '',
    workMobile: showEdit && editData?.workMobile ? editData?.workMobile : '',
    workMobileCountryCode:
      showEdit && countryCodes.length > 0 && editData?.workCountryCode
        ? countryCodes.find((c) => c.value === editData?.workCountryCode)
        : null,
    address: showEdit ? editData?.address : '',
    address2: showEdit ? editData?.address2 : '',
    referredBy: '',
    assignTo: showEdit ? editData?.assignTo : '',
    practiceArea: showEdit
      ? {
          name: editData?.practiceArea?.name,
          _id: editData?.practiceArea?._id,
        }
      : null,
    birthDate: showEdit ? editData?.user?.dateOfBirth : '',
    clientStatus:
      showEdit && editData?.clientStatus ? editData?.clientStatus : '',
    timezone:
      showEdit && editData?.timezone
        ? {
            label: editData?.timezone,
            value: editData?.timezone,
          }
        : null,
    zipcode: showEdit ? editData?.zipcode : '',
    conflictCheck: showEdit ? editData?.conflictCheck : false,
    office: showEdit && editData?.office ? editData?.office : '',
    conflictCheckNote: showEdit ? editData?.note : '',
    referralSource: showEdit ? editData?.referralSource : '',
    referralSourceInput: '',
    address2: showEdit ? editData?.address2 : '',
    country: initialCountry || null,
    state: initialState || null,
    city: initialCity || null,
    potentialValueOfCase: showEdit ? editData?.potentialValueOfCase : '',
    leadDetails: showEdit ? editData?.leadDetails : '',
    caseDescription: showEdit ? editData?.caseDescription : '',
    attorneyId: '',
    firmId: '',
  };

  const getCountryCode = async () => {
    try {
      const response = await api.get(`/locations/countryCodes`);
      setCountryCodes(response.data.data.countries);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  const loadCountryStateCity = async () => {
    const country = countryList.find((c) => c.value === editData?.user.country);
    setInitialCountry(country);
    loadStateList(country?.countryId);
  };

  const loadStateList = async (countryId) => {
    try {
      const response = await api.get(`locations/states?countryId=${countryId}`);

      const state = response.data.data.states?.find(
        (s) => s.value === editData?.user?.state
      );

      setInitialState(state);
      dispatch(loadStateListAction(response.data.data.states));
      loadCityList(state?.stateId);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  const loadCityList = async (stateId) => {
    try {
      const response = await api.get(`locations/cities?stateId=${stateId}`);
      const city = response.data.data.cities.find(
        (c) => c.value === editData?.user.city
      );
      setInitialCity(city);
      dispatch(loadCityListAction(response.data.data.cities));
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    loadCountryStateCity();
    return () => {
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
    };
  }, []);

  const onSubmit = async (values, actions) => {
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      email: values.email || '',
      mobile: values.mobile || '',
      countryCode: values.countryCode?.value,
      workMobile: values.workMobile,
      workMobileCountryCode: values.workMobileCountryCode?.value,
      dateAdded: values.dateAdded,
      practiceArea: values.practiceArea?._id,
      conflictCheck: true,
      office: values.office,
      assignTo: values.assignTo?._id,
      clientStatus: values.clientStatus,
      note: values.conflictCheckNote,
      referralSource: values.referralSource,
      potentialValueOfCase: values.potentialValueOfCase,
      leadDetails: values.leadDetails,
      caseDescription: values.caseDescription,
      attorneyId: user._id,
      firmId: user.firm,
      address: values.address || '',
      address2: values.address2 || '',
      country: values.country?.label,
      state: values.state?.label,
      city: values.city?.label,
      timezone: values.timezone?.label,
      zipcode: values?.zipcode,
      birthday: values.birthday,
    };
    console.log({ formData });
    if (formData.dateAdded === '') {
      delete formData.dateAdded;
    }

    try {
      let response;
      if (showEdit) {
        response = await api.patch(
          `/clients/leads/firms/${user.firm}/leads/${editData._id}`,
          formData
        );
      } else {
        if (formData.mobile === '') {
          delete formData.mobile;
        }
        if (formData.email === '') {
          delete formData.email;
        }
        if (formData.workMobile === '') {
          delete formData.workMobile;
        }
        response = await api.post(`/clients/leads`, formData);
      }

      dispatch(loadleadListAction(response.data.data.leads));
      dispatch(showSnackbarAction(response.data.message, 'success'));
      onClose();
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const clientChangeHandler = async () => {
    try {
      let formData = {
        convertToClient: true,
        firmId: user.firm,
        leadId: editData?._id,
      };
      let response = await api.patch(
        `/clients/leads/firms/${user.firm}/leads/${editData._id}`,
        formData
      );

      dispatch(loadleadListAction(response.data.data.leads));
      dispatch(showSnackbarAction(response.data.message, 'success'));
      onClose();
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    getCountryCode();
  }, []);

  useEffect(() => {
    getPracticeArea();
    getFirmMembers(user.firm);
  }, [user.firm]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { height: '100%' } }}
      open={open || showEdit}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          bgcolor: '#f5f5f5',
        }}
      >
        <DialogTitle sx={{ fontSize: theme.typography.h3 }}>
          {showEdit ? 'Edit Lead' : ' Add Lead'}
        </DialogTitle>
        <DialogTitle>
          <Close onClick={() => onClose()} />
        </DialogTitle>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {showEdit && (
            <Button
              onClick={clientChangeHandler}
              sx={{ float: 'right', mb: 2, color: '#21766D' }}
              variant="text"
            >
              Convert to client
            </Button>
          )}
          <Typography
            variant="h2"
            sx={{ color: theme.palette.primary.main, mt: 2, mb: 2 }}
          >
            Potential New Client
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Lead Name*
              </Typography>
            </Grid>
            <Grid item xs={12} md={3.5}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name*"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                id="middleName"
                name="middleName"
                label="Middle Name"
                variant="outlined"
                value={formik.values.middleName}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Last Name*"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Email
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Mobile
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                autoComplete="off"
                id="countryCode"
                name="countryCode"
                value={formik.values?.countryCode}
                options={countryCodes}
                getOptionLabel={(option) => option.label || ''}
                // inputValue={editCountryCode}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('countryCode', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country Code "
                    error={
                      formik.touched.countryCode &&
                      Boolean(formik.errors.countryCode)
                    }
                    helperText={
                      formik.touched.countryCode && formik.errors.countryCode
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                fullWidth
                id="mobile"
                name="mobile"
                label="Mobile"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Button
                onClick={() => setShowMoreInfo(!moreInfo)}
                sx={{ ml: -2 }}
                variant="text"
              >
                Add more information
                {moreInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            </Grid>
          </Grid>
          {moreInfo && (
            <>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 4 }} variant="h4">
                    Work Mobile
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    autoComplete="off"
                    id="workMobileCountryCode"
                    name="workMobileCountryCode"
                    value={formik.values?.workMobileCountryCode}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('workMobileCountryCode', newValue);
                    }}
                    options={countryCodes}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="+91 " />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    fullWidth
                    id="workMobile"
                    name="workMobile"
                    label="Work Mobile"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    value={formik.values.workMobile}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.workMobile &&
                      Boolean(formik.errors.workMobile)
                    }
                    helperText={
                      formik.touched.workMobile && formik.errors.workMobile
                    }
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    variant="outlined"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Address2
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    id="address2"
                    name="address2"
                    label="Address 2"
                    variant="outlined"
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 4 }} variant="h4">
                    Country, State, City
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <CountryAutocomplete formik={formik} />
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <StateAutocomplete
                    formik={formik}
                    countryId={formik.values.country?.countryId}
                  />
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <CityAutocomplete
                    formik={formik}
                    stateId={formik.values.state?.stateId}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 4 }} variant="h4">
                    Timezone, Zipcode
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    value={formik.values?.timezone}
                    id="timezone"
                    name="timezone"
                    options={timezones}
                    disableCloseOnSelect
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('timezone', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Timezone" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    id="zipcode"
                    name="zipcode"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 6 }}
                    value={formik.values.zipcode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.zipcode && Boolean(formik.errors.zipcode)
                    }
                    helperText={formik.touched.zipcode && formik.errors.zipcode}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Birthday
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="birthday"
                      name="birthday"
                      onChange={(value) =>
                        formik.setFieldValue('birthday', value)
                      }
                      value={dayjs(formik.values.birthday)}
                      slotProps={{
                        textField: {
                          error: false,
                        },
                      }}
                      renderInput={() => {
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={formik.values.birthDate}
                        />;
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 4 }} variant="h4">
                    Refral Source
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  {!showRefral ? (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Refral source
                      </InputLabel>
                      <Select
                        labelId="referalSource"
                        id="referralSource"
                        label="Referral Source"
                        name="referralSource"
                        value={formik.values?.referralSource}
                        onChange={formik.handleChange}
                        options={['Advertisment', 'Facebook', 'Insta', 'Other']}
                      >
                        <MenuItem value="Advertisment">Advertisment</MenuItem>
                        <MenuItem value="Facebook">Facebook</MenuItem>
                        <MenuItem value="Insta">Facebook</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      fullWidth
                      id="referralSourceInput"
                      name="referralSourceInput"
                      label="Refral Source"
                      variant="outlined"
                      value={formik.values.referralSourceInput}
                      onChange={formik.handleChange}
                    />
                  )}
                </Grid>
                {/* <Grid sx={{ mt: 2, ml: 2 }} item xs={12} md={4}>
                  <Button
                    onClick={() => setShowRefral(!showRefral)}
                    variant="text"
                  >
                    {showRefral ? 'cancel' : 'Add new Refral Source'}
                  </Button>
                </Grid> */}
              </Grid>
              <Grid sx={{ mt: 3 }} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ mt: 3 }} variant="h4">
                    Lead Details
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    id="leadDetails"
                    name="leadDetails"
                    label="Lead Details"
                    variant="outlined"
                    value={formik.values.leadDetails}
                    onChange={formik.handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Divider sx={{ mt: 5, mb: 5 }} />
          <Typography
            variant="h2"
            sx={{ color: theme.palette.primary.main, mt: 4, mb: 4 }}
          >
            Potential New Case
          </Typography>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Date Added
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="dateAdded"
                  name="dateAdded"
                  onChange={(value) => formik.setFieldValue('dateAdded', value)}
                  value={dayjs(formik.values.dateAdded)}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Status
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="clientStatus"
                  id="clientStatus"
                  label="Client Status"
                  name="clientStatus"
                  options={['New', 'Contacted']}
                  value={formik.values.clientStatus}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Contacted">Contacted</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Practice Area
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                value={formik.values?.practiceArea}
                id="practiceArea"
                name="practiceArea"
                options={practiceAreaList}
                // inputValue={formik.values?.practiceArea}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.name || ''}
                onChange={(event, newValue) => {
                  formik.setFieldValue('practiceArea', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Practice Area"
                    error={
                      formik.touched.practiceArea &&
                      Boolean(formik.errors.practiceArea)
                    }
                    helperText={
                      formik.touched.practiceArea && formik.errors.practiceArea
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Potential value of Case*
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="potentialValueOfCase"
                name="potentialValueOfCase"
                variant="outlined"
                type="number"
                // inputProps={{ min: 1 }}
                value={formik.values.potentialValueOfCase}
                onChange={formik.handleChange}
                error={
                  formik.touched.potentialValueOfCase &&
                  Boolean(formik.errors.potentialValueOfCase)
                }
                helperText={
                  formik.touched.potentialValueOfCase &&
                  formik.errors.potentialValueOfCase
                }
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Assign to
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                value={formik.values?.assignTo}
                id="assignTo"
                name="assignTo"
                options={firmMemberList}
                // inputValue={formik.values?.practiceArea}
                getOptionLabel={(option) => option.firstName || ''}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('assignTo', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.assignTo && Boolean(formik.errors.assignTo)
                    }
                    helperText={
                      formik.touched.assignTo && formik.errors.assignTo
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Office
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Office</InputLabel>
                <Select
                  labelId="office"
                  id="office"
                  label="office"
                  name="office"
                  options={['Primary']}
                  value={formik.values.office}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="primary">Primary</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Potential Case Description
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                id="caseDescription"
                name="caseDescription"
                label="Description"
                variant="outlined"
                value={formik.values.caseDescription}
                onChange={formik.handleChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 4 }} variant="h4">
                Conflict Check
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box sx={{ ml: 4 }}>
                <FormControl>
                  {formik.values.conflictCheck === true && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="conflictCheck"
                          defaultChecked
                          onChange={formik.handleChange}
                          value={formik.values.conflictCheck}
                        />
                      }
                    />
                  )}
                  {formik.values.conflictCheck === false && (
                    <FormControlLabel
                      control={
                        <Switch
                          name="conflictCheck"
                          onChange={formik.handleChange}
                          value={formik.values.conflictCheck}
                        />
                      }
                    />
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 3 }} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ mt: 3 }} variant="h4">
                Conflict check note
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                id="conflictCheckNote"
                name="conflictCheckNote"
                variant="outlined"
                value={formik.values.conflictCheckNote}
                onChange={formik.handleChange}
                error={
                  formik.touched.conflictCheckNote &&
                  Boolean(formik.errors.conflictCheckNote)
                }
                helperText={
                  formik.touched.conflictCheckNote &&
                  formik.errors.conflictCheckNote
                }
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 80 }}
            sx={{ justifyContent: 'center', mt: 15, mb: 4 }}
          >
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={onClose}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button fullWidth size="large" variant="contained" type="submit">
              Save Lead
            </Button>
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
}
