import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { alpha, Box, Button, TextField, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AppGridContainer from '../../../utility/AppGridContainer/AppGridContainer';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { Fonts } from '../../../utility/theme';
import api from '../../../utility/api';
import { useDispatch } from 'react-redux';
import { showSnackbarAction } from '../../../redux/alert/alertAction';
import { useEffect, useState } from 'react';

const AvatarViewWrapper = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    cursor: 'pointer',
    '& .edit-icon': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      width: 26,
      height: 26,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.4s ease',
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    '&.dropzone': {
      outline: 0,
      '&:hover .edit-icon, &:focus .edit-icon': {
        display: 'flex',
      },
    },
  };
});

const validationSchema = yup.object({
  firstName: yup.string('Required').required('Required'),
  lastName: yup.string('Required').required('Required'),
  email: yup.string('Required').email().required('Required'),
  firmName: yup.string('Required').required('Required'),
});

const PersonalInfoForm = ({ values, setFieldValue }) => {
  const user = useSelector((state) => state.auth.user.user);
  const dispatch = useDispatch();
  const [attorneyData, setAttorneyData] = useState({});
  const getSingleAttorney = async () => {
    try {
      const response = await api.get(
        `/attornies/${user._id}/firms/${user.firm}`
      );
      setAttorneyData(response.data.data.user);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const initialValues = {
    firstName: attorneyData.firstName || '',
    lastName: attorneyData.lastName || '',
    email: attorneyData.email || '',
    firmName: attorneyData.firm?.name || '',
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      firmName: values.firmName,
    };
    try {
      const response = await api.patch(
        `/attornies/${user._id}/firms/${user.firm}?userId=${user._id}`,
        formData
      );
      if (response) {
        setAttorneyData(response.data.data.singleAttorney);
        dispatch(showSnackbarAction(response.data.message, 'success'));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    getSingleAttorney();
  }, [user.firm]);

  return (
    <>
      <Typography
        component="h3"
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 3, lg: 4 },
        }}
      >
        Personal Info
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 5, lg: 6 },
        }}
      >
        <Avatar
          sx={{
            width: { xs: 50, lg: 64 },
            height: { xs: 50, lg: 64 },
            cursor: 'pointer',
          }}
          // src={values.photoURL}
        />
        <Box
          sx={{
            ml: 4,
          }}
        >
          <Typography
            sx={{
              fontWeight: Fonts.MEDIUM,
            }}
          >
            {user.firstName}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {user.email}
          </Typography>
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <AppGridContainer spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="First Name"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Last Name"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />{' '}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />{' '}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Firm Name"
              id="firmName"
              name="firmName"
              value={formik.values.firmName}
              onChange={formik.handleChange}
              error={formik.touched.firmName && Boolean(formik.errors.firmName)}
              helperText={formik.touched.firmName && formik.errors.firmName}
            />{' '}
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  position: 'relative',
                  minWidth: 100,
                }}
                color="primary"
                variant="contained"
                type="submit"
              >
                Save changes
              </Button>
            </Box>
          </Grid>
        </AppGridContainer>
      </form>
    </>
  );
};

export default PersonalInfoForm;
PersonalInfoForm.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};
