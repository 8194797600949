import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Autocomplete,
  Box,
  Button,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  lighten,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';

import { showSnackbarAction } from '../../../redux/alert/alertAction';
import { loadCaseListAction } from '../../../redux/cases/caseActions';
import { loadNotesListAction } from '../../../redux/Notes/NotesActions';

import api from '../../../utility/api';
import styled from '@emotion/styled';
import { loadCaseNoteListAction } from '../../../redux/caseNote/caseNoteAction';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const validationSchema = yup.object().shape({
  note: yup.string('Enter your note').required('Required'),
});

const AddCaseNote = ({ open, onClose, showEdit, editData, caseDetails }) => {
  const [caseOrClients, setCaseOrClients] = useState([]);
  const user = useSelector((state) => state.auth.user.user);

  const initialValues = {
    case: caseDetails?.caseName,
    date: showEdit ? dayjs(editData.date) : '',
    subject: showEdit ? editData.subject : '',
    note: showEdit ? editData.note : '',
  };

  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      caseId: caseDetails?._id,
      date: values.date,
      subject: values.subject,
      note: values.note,
      attorneyId: user._id,
      firmId: user.firm,
    };

    if (showEdit) {
      try {
        const response = await api.patch(
          `/notes/firms/${user.firm}/notes/${editData._id}?caseId=${caseDetails?._id}&&type=case`,
          formData
        );
        if (response) {
          console.log({ response });
          dispatch(loadCaseNoteListAction(response.data.data.notes));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          onClose();
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    } else {
      try {
        const response = await api.post(
          `/notes?caseId=${caseDetails?._id}&&type=case`,
          formData
        );
        if (response) {
          dispatch(loadCaseNoteListAction(response.data.data.notes));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          formik.resetForm({});
          onClose();
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <Dialog open={open || showEdit} onClose={() => onClose()} maxWidth="lg">
      <DialogTitle
        sx={{
          paddingY: 6,
          bgcolor: 'lightgrey',
          borderBottom: '1px solid grey',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h1">
            {showEdit ? 'Edit Note' : 'Add Note'}
          </Typography>
          <CloseIcon onClick={() => onClose()} />
        </Box>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ padding: 6, borderBottom: '1px solid grey' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4" mt={3}>
                Case
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Typography sx={{ color: '#11CA5B' }} variant="h4" mt={3}>
                {caseDetails?.caseName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Date</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="date"
                  name="date"
                  onChange={(value) => formik.setFieldValue('date', value)}
                  value={dayjs(formik.values.date)}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Subject</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                fullWidth
                id="subject"
                name="subject"
                variant="outlined"
                value={formik.values.subject}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Note*</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                fullWidth
                id="note"
                name="note"
                variant="outlined"
                value={formik.values.note}
                onChange={formik.handleChange}
                error={formik.touched.note && Boolean(formik.errors.note)}
                helperText={formik.touched.note && formik.errors.note}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'center',
                md: 'end',
              },
              pr: 10,
            }}
          >
            <Button
              sx={{
                width: 140,
                height: 50,
                borderRadius: '30px',
                border: '2px solid red',
                mr: 2,
                mb: {
                  xs: 4,
                },
                color: 'grey',
              }}
              onClick={() => onClose()}
            >
              <Typography variant="h5" mr={1} color="red">
                Discard
              </Typography>
            </Button>

            <Button
              type="submit"
              sx={{
                width: 150,
                height: 50,
                borderRadius: '30px',
                color: 'white',
                bgcolor: 'primary.main',
                ':hover': {
                  color: 'white',
                  bgcolor: 'primary.main',
                },
              }}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCaseNote;
