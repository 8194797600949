import { GET_ALL_CASEBILLDOCUMENT, ADD_CASEBILLDOCUMENT } from './caseBillType';

const INITIAL_STATE = {
  caseBillDocumentList: [],
  addCaseBillDocument: {},
};

const caseBillDocumentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CASEBILLDOCUMENT:
      return {
        ...state,
        addCaseBillDocument: action.payload,
      };
    case GET_ALL_CASEBILLDOCUMENT:
      return {
        ...state,
        caseBillDocumentList: action.payload,
      };

    default:
      return state;
  }
};

export default caseBillDocumentReducer;
