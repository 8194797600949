import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import MaterialReactTable from 'material-react-table';
import { Box, Button, MenuItem, Typography } from '@mui/material';

import AddLead from './AddLead';
import UpdateLead from './UpdateLead';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { loadleadListAction } from '../../redux/lead/leadActions';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import Loader from '../loader/Loader';

const LeadTable = (props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [editID, setEditID] = useState(null);
  const [editData, setEditData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(() => props.rows);
  const user = useSelector((state) => state.auth.user.user);
  const leadList = useSelector((state) => state.lead.leadList);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = () => {
    setAnchorEl(true);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const getEditData = async (ID) => {
    let editData = leadList?.find((contact) => contact._id === ID);

    setEditData(editData);
  };

  const getLeads = async (firmID) => {
    try {
      const response = await api.get(`/clients/leads/firm/${firmID}`);
      dispatch(loadleadListAction(response.data.data.leads));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const deleteLead = async (leadID) => {
    try {
      const response = await api.delete(
        `/clients/leads/firms/${user.firm}/leads/${leadID}`
      );
      if (response) {
        dispatch(showSnackbarAction('Lead Deleted Successfully', 'success'));
        dispatch(loadleadListAction(response.data.data.allLeads));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const filterEditData = async (id) => {
    try {
      const response = await api.get(
        `/clients/leads/firms/${user.firm}/leads/${id}`
      );

      setEditData(response.data.data.lead);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
    // setEditData(editlead);
  };

  useEffect(() => {
    getLeads(user.firm);
  }, [user.firm]);

  let leadsColumn = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },

      {
        accessorKey: 'user.email',
        header: 'Email',
      },
      {
        accessorKey: 'user.mobile',
        header: 'Mobile',
      },
      {
        accessorKey: 'clientStatus',
        header: 'Status',
      },
      {
        accessorKey: 'potentialValueOfCase',
        header: 'Potential Value',
      },
    ],
    []
  );

  return (
    <>
      <Box>
        <Typography variant="h2" mb={8}>
          Lead Detail
        </Typography>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={leadsColumn}
          data={leadList}
          editingMode="modal" //default
          enableColumnOrdering
          // enableEditing
          enableRowActions
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              cursor: 'pointer',
            },
          })}
          positionActionsColumn="last"
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              key="edit"
              onClick={() => {
                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                }, 1000);
                getEditData(row.original._id);
                setShowEdit(!showEdit);
                closeMenu();
              }}
            >
              <EditIcon />
              <Typography ml={2}>Edit</Typography>
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                deleteLead(row.original._id);
                closeMenu();
              }}
            >
              <DeleteIcon />
              <Typography ml={2}>Delete</Typography>
            </MenuItem>,
          ]}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
              getEditData(row.original._id);
              setShowEdit(!showEdit);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
          renderTopToolbarCustomActions={() => (
            <>
              <Button variant="contained" onClick={handleClick}>
                Add Lead
              </Button>
            </>
          )}
        />
        {anchorEl && <AddLead open={anchorEl} onClose={() => handleClose()} />}
        {isLoading ? (
          <Loader />
        ) : (
          showEdit && (
            <AddLead
              showEdit={showEdit}
              onClose={() => setShowEdit()}
              editData={editData}
            />
          )
        )}
      </Box>
    </>
  );
};

export default LeadTable;
