import { ADD_CASENOTE, GET_ALL_CASENOTE } from './caseNoteTypes';

export const loadCaseNoteListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_CASENOTE,
    payload: data,
  });
};

export const loadCaseAddNoteAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_CASENOTE,
    payload: data,
  });
};
