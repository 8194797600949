import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';

import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  darken,
  lighten,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { loadTaskListAction } from '../../redux/task/taskActions';
import dayjs from 'dayjs';
import { loadCaseListAction } from '../../redux/cases/caseActions';
import Reminder from '../task/Reminder';
import { AccountCircle, People } from '@mui/icons-material';
import { loadCaseTaskListAction } from '../../redux/caseTask/caseTaskAction';
import styled from '@emotion/styled';

const taskValidationSchema = yup.object().shape({
  name: yup.string('Enter task').required('Required'),
  description: yup.string('Enter desc').required('Required'),
  priority: yup.string('Enter priority').required('Required'),
  dueDate: yup.date().min(new Date()).required(),
  caseOrLead: yup.object().shape({
    value: yup.string().required('Required'),
    label: yup.string('Required'),
  }),
});
const Task = ({ showAddTask, showEdit, onClose, editData, caseDetails }) => {
  let [addRows, setAddRows] = useState([]);
  let [checked, setChecked] = useState([1]);
  let [staffs, setStaffs] = useState([1]);
  let [caseList, setCaseList] = useState([]);
  let [sharing, setSharing] = useState([]);

  const user = useSelector((state) => state.auth.user.user);

  const dispatch = useDispatch();

  const initialValues = {
    caseOrLead: null,
    name: showEdit ? editData?.name : '',
    firmId: '',
    createdBy: '',
    priority: showEdit ? editData?.priority : '',
    dueDate: showEdit ? dayjs(editData.dueDate) : '',
    description: showEdit ? editData?.description : '',
    staffIds: [],
  };

  const getAllCases = async (firmId) => {
    try {
      const response = await api.get(`/cases/firms/${firmId}`);
      setCaseList([
        ...response.data.data.sortedCases,
        ...response.data.data.sortedLeads,
      ]);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getCaseDetails = async (id) => {
    try {
      if (!id) {
        setSharing([]);
      }
      const response = await api.get(`/cases/${id}`);

      if (response) {
        setSharing(response.data.data.case);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      name: values.name,
      firmId: user.firm,
      createdBy: user._id,
      priority: values.priority,
      dueDate: values.dueDate,
      description: values.description,
      staffIds: staffs.slice(1),
      clientIds: checked.slice(1),
    };

    if (values.caseOrLead.type === 'Cases') {
      formData.caseId = values.caseOrLead.value;
    }

    if (values.caseOrLead.type === 'Leads') {
      formData.leadId = values.caseOrLead.value;
    }

    if (formData['dueDate'] === '') {
      delete formData['dueDate'];
    }

    if (showEdit) {
      try {
        const response = await api.patch(
          `/tasks/firms/${user.firm}/tasks/${editData._id}`,
          formData
        );
        if (response) {
          dispatch(loadCaseTaskListAction(response.data.data.tasks));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          handleClose();
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    } else {
      try {
        const response = await api.post(`/tasks`, formData);
        if (response) {
          setStaffs([1]);
          setChecked([1]);
          dispatch(loadTaskListAction(response.data.data.tasks));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          handleClose();
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    // if (value?.value === 'all') {
    //   let arr = [1, ...clients];
    //   if (checked.length === clients.length + 1) {
    //     console.log('runn');
    //     setChecked([1]);
    //   } else {
    //     setChecked(arr);
    //   }
    // } else {

    // }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggle2 = (value) => () => {
    const currentIndex = staffs.indexOf(value);
    const newChecked = [...staffs];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    formik.setFieldValue('staffIds', newChecked);

    setStaffs(newChecked);
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: taskValidationSchema,
    enableReinitialize: true,
  });

  const handleClose = () => {
    formik.resetForm({});
    onClose();
  };

  useEffect(() => {
    const results = caseDetails?.clients?.filter(({ _id: id1 }) =>
      editData?.clients?.some(({ _id: id2 }) => {
        return id2 === id1;
      })
    );

    if (results) {
      setChecked([1, ...results]);
    }
    let staffs = [];
    if (caseDetails) {
      staffs = [
        ...caseDetails?.originatingAttornies,
        ...caseDetails?.leadAttornies,
        ...caseDetails?.firmMembers,
      ];
    }

    const results2 = staffs?.filter(({ _id: id1 }) =>
      editData?.staffs?.some(({ _id: id2 }) => {
        return id2 === id1;
      })
    );

    if (results2) {
      setStaffs([1, ...results2]);
    }

    getAllCases(user.firm);
  }, [user.firm]);
  console.log({ sharing });
  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  return (
    <>
      <Dialog
        open={showAddTask || showEdit}
        onClose={handleClose}
        maxWidth="xl"
      >
        <DialogTitle
          sx={{ borderBottom: '1px dotted grey', bgcolor: '#f5f5f5' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h2">
              {showEdit ? 'Edit Task ' : 'Add Task'}
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ borderBottom: '1px dotted grey' }}>
            <Grid container spacing={4} sx={{ py: 8 }}>
              <Grid
                item
                xs={12}
                sm={9}
                container
                spacing={6}
                sx={{
                  borderRight: '1px dotted #d3c3c4!important',
                  p: 4,
                }}
              >
                <Grid item xs={12} md={2}>
                  <Typography variant="h4"> Case or Lead*</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Stack>
                    <Autocomplete
                      autoComplete="off"
                      id="caseOrLead"
                      name="caseOrLead"
                      value={formik.values?.caseOrLead}
                      options={caseList || []}
                      groupBy={(option) => option.type}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(event, newValue) => {
                        formik.setFieldValue('caseOrLead', newValue);
                        if (newValue?.value) {
                          if (newValue.type === 'Cases') {
                            getCaseDetails(newValue.value);
                          } else {
                            setSharing([]);
                          }
                        } else {
                        }
                      }}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader>{params.group}</GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.caseOrLead &&
                            Boolean(formik.errors.caseOrLead)
                          }
                          helperText={
                            formik.touched.caseOrLead &&
                            formik.errors.caseOrLead
                          }
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="h4"> Task Name*</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="h4"> Due Date*</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="dueDate"
                      name="dueDate"
                      onChange={(value) =>
                        formik.setFieldValue('dueDate', value)
                      }
                      value={dayjs(formik.values.dueDate)}
                      error={
                        formik.touched.dueDate && Boolean(formik.errors.dueDate)
                      }
                      helperText={
                        formik.touched.dueDate && formik.errors.dueDate
                      }
                      slotProps={{
                        textField: {
                          error: false,
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {formik.touched.dueDate && formik.errors.dueDate ? (
                    <FormHelperText
                      sx={{
                        color: '#bf3333',
                        marginLeft: '16px !important',
                      }}
                    >
                      {formik.touched.dueDate && formik.errors.dueDate}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="h4">Priority*</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">
                      Priority
                    </InputLabel>

                    <Select
                      labelId="priotity"
                      id="priority"
                      name="priority"
                      label="Priority"
                      value={formik.values.priority}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.priority &&
                        Boolean(formik.errors.priority)
                      }
                      helperText={
                        formik.touched.priority && formik.errors.priority
                      }
                    >
                      <MenuItem value="low">Low</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                    </Select>
                  </FormControl>
                  <Box>
                    {formik.touched.priority && formik.errors.priority ? (
                      <FormHelperText
                        sx={{
                          color: '#bf3333',
                          marginLeft: '16px !important',
                        }}
                      >
                        {formik.touched.priority && formik.errors.priority}
                      </FormHelperText>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="h4">Description*</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Stack>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      variant="outlined"
                      multiline
                      rows={4}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} spacing={8} sx={{ p: 2 }}>
                <>
                  <Typography variant="h4">Contacts</Typography>
                  {sharing?.clients?.length > 0 && (
                    <>
                      <List
                        dense
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                          bgcolor: 'background.paper',
                        }}
                      >
                        {sharing?.clients?.map((value) => {
                          const labelId = `checkbox-list-secondary-label-${value._id}`;
                          return (
                            <>
                              <ListItem
                                key={value._id}
                                secondaryAction={
                                  <Checkbox
                                    edge="end"
                                    onChange={handleToggle(value)}
                                    checked={checked.indexOf(value) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemIcon>
                                  <AccountCircle />
                                </ListItemIcon>
                                <ListItemButton>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      fontSize: '17px',
                                      p: 1,
                                    }}
                                    id={labelId}
                                    primary={`${value.firstName} ${value.lastName}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                              <Box
                                sx={{
                                  borderBottom: '1px dotted #d3c3c4!important',
                                }}
                              ></Box>
                            </>
                          );
                        })}
                      </List>
                    </>
                  )}
                  <Typography sx={{ mt: 4 }} variant="h4">
                    Staffs
                  </Typography>
                  {sharing?.leadAttornies?.length > 0 && (
                    <>
                      <List
                        dense
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                          bgcolor: 'background.paper',
                        }}
                      >
                        <Box
                          sx={{
                            borderBottom: '1px dotted #d3c3c4!important',
                          }}
                        ></Box>
                        {[
                          ...sharing?.originatingAttornies,
                          ...sharing?.leadAttornies,
                          ...sharing?.firmMembers,
                        ]?.map((value) => {
                          const labelId = `checkbox-list-secondary-label-${value._id}`;
                          return (
                            <>
                              <ListItem
                                key={value._id}
                                secondaryAction={
                                  <Checkbox
                                    edge="end"
                                    onChange={handleToggle2(value)}
                                    checked={staffs.indexOf(value) !== -1}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemIcon>
                                  <AccountCircle />
                                </ListItemIcon>
                                <ListItemButton>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      fontSize: '17px',
                                      p: 1,
                                    }}
                                    id={labelId}
                                    primary={`${value.firstName} ${value.lastName}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                              <Box
                                sx={{
                                  borderBottom: '1px dotted #d3c3c4!important',
                                }}
                              ></Box>
                            </>
                          );
                        })}
                      </List>
                    </>
                  )}
                </>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 8 }}>
            <Box>
              <Button sx={{ mr: 3 }} variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                {showEdit ? 'Update Task' : 'Save Task'}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Task;
