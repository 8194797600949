import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import TaskSharpIcon from '@mui/icons-material/TaskSharp';
import CasesSharpIcon from '@mui/icons-material/CasesSharp';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';

const drawerMenuOptions = [
  { id: 1, text: 'HOME', icon: <HomeIcon />, path: '/' },
  { id: 2, text: 'TASKS', icon: <TaskSharpIcon />, path: '/task' },
  { id: 3, text: 'CASES', icon: <CasesSharpIcon />, path: '/cases' },
  { id: 4, text: 'DOCUMENTS', icon: <ArticleIcon />, path: '/documents' },
  { id: 6, text: 'BILLING', icon: <AttachMoneySharpIcon />, path: '/billing' },
  { id: 6, text: 'LEAD', icon: <ArticleIcon />, path: '/lead' },
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="left"
        transitionDuration={400}
        PaperProps={{
          sx: {
            width: '60%',
            // opacity: "0.5",
            paddingTop: 1,
            paddingLeft: 2,
          },
        }}
      >
        <List>
          {drawerMenuOptions.map((item) => {
            return (
              <ListItemButton
                key={item.id}
                onClick={() => {
                  navigate(item.path);
                  setOpenDrawer(false);
                }}
              >
                <ListItemIcon sx={{ marginRight: 0 }}>{item.icon}</ListItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>
    </>
  );
};

export default DrawerComp;
