import Container from '@mui/material/Container';
import Home from '../../components/home/Home';
import CasesTable from '../../components/case/CasesTable';
import { Box } from '@mui/system';

function CasesPage() {
  return (
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 20, p: 4 }}>
        <CasesTable />
      </Box>
    </Container>
  );
}

export default CasesPage;
