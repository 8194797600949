import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .matches(
      passwordRegex,
      'Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required'
    )
    .required('Required'),

  confirmPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [resetPass, setResetPass] = useState(false);
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { secret } = useParams();
  const dispatch = useDispatch();

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    try {
      const response = await api.post(
        `auth/resetPassword/step2?verifyToken=${secret}`,
        formData
      );
      if (response) {
        setMessage(response.data.message);
        setResetPass(true);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <>
      {!resetPass && (
        <Paper sx={{ p: 10, marginTop: 30 }}>
          <Typography sx={{ marginBottom: 4 }} variant="h3">
            Reset password
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
              <TextField
                id="password"
                name="password"
                label="Password*"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password*"
                type={showConfirmPassword ? 'text' : 'password'}
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
              <Button variant="contained" type="submit">
                Reset
              </Button>
            </Stack>
          </form>
        </Paper>
      )}
      {resetPass && (
        <Paper sx={{ height: 200, width: 580, padding: 10, mt: 30 }}>
          <Typography variant="h3" mb={8}>
            {message}
          </Typography>
          <Button
            variant="contained"
            sx={{ width: '100%' }}
            onClick={() => navigate('/signin')}
          >
            Login
          </Button>
        </Paper>
      )}
    </>
  );
};

export default ResetPassword;
