import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Autocomplete,
  Box,
  Button,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  lighten,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import { loadCaseListAction } from '../../redux/cases/caseActions';
import { loadNotesListAction } from '../../redux/Notes/NotesActions';

import api from '../../utility/api';
import styled from '@emotion/styled';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const validationSchema = yup.object().shape({
  case: yup
    .object()
    .shape({
      label: yup.string('Enter Case').required('Required'),
    })
    .typeError('Choose a case'),
  note: yup.string('Enter your note').trim().required('Required'),
});

const AddNote = ({ open, setShowNote, showEdit, editData }) => {
  const [caseOrClients, setCaseOrClients] = useState([]);
  const user = useSelector((state) => state.auth.user.user);

  const getAllCases = async (firmId) => {
    try {
      const response = await api.get(`/cases/firms/${firmId}?clients=true`);

      setCaseOrClients([
        ...response.data.data.sortedClients,
        ...response.data.data.sortedCases,
      ]);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  console.log({ editData });
  const initialValues = {
    case: showEdit
      ? {
          label:
            editData && editData?.contact?.type === 'client'
              ? editData?.contact.firstName
              : editData?.case?.caseName,
          value: editData?.case?._id,
        }
      : null,
    date: showEdit ? dayjs(editData.date) : '',
    subject: showEdit ? editData.subject : '',
    note: showEdit ? editData.note : '',
  };

  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      date: values.date,
      subject: values.subject,
      note: values.note,
      attorneyId: user._id,
      firmId: user.firm,
    };

    if (values.case?.type === 'Clients') {
      formData.contactId = values.case?.value;
    }
    if (values.case?.type === 'Cases') {
      formData.caseId = values.case?.value;
    }

    if (showEdit) {
      try {
        const response = await api.patch(
          `/notes/firms/${user.firm}/notes/${editData._id}`,
          formData
        );
        if (response) {
          dispatch(loadNotesListAction(response.data.data.notes));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          setShowNote(!showEdit);
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    } else {
      try {
        const response = await api.post(`/notes`, formData);
        if (response) {
          dispatch(loadNotesListAction(response.data.data.notes));
          dispatch(showSnackbarAction(response.data.message, 'success'));
          setShowNote(!open);
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    getAllCases(user.firm);
  }, [user.firm]);

  return (
    <Dialog
      open={open || showEdit}
      onClose={() => setShowNote(false)}
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          paddingY: 4,
          bgcolor: 'lightgrey',
          borderBottom: '1px solid grey',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h1">
            {showEdit ? 'Edit Note' : 'Add Note'}
          </Typography>
          <CloseIcon onClick={() => setShowNote(false)} />
        </Box>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{ padding: 2, my: 2, borderBottom: '1px solid grey' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4" mt={1}>
                Case/Contact*
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              {!showEdit ? (
                <Autocomplete
                  autoComplete="off"
                  id="case"
                  name="case"
                  options={caseOrClients || []}
                  value={formik.values?.case}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option?.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('case', newValue);
                  }}
                  renderGroup={(params) => {
                    return (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={formik.touched.case && Boolean(formik.errors.case)}
                      helperText={formik.touched.case && formik.errors.case}
                    />
                  )}
                />
              ) : (
                <Typography
                  sx={{ color: '#11CA5B' }}
                  variant="h4"
                  mt={1}
                  variant="h4"
                >
                  {editData?.contact?.type === 'client'
                    ? `${editData.contact.firstName} ${editData.contact.lastName}`
                    : editData?.case?.caseName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Date</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="date"
                  name="date"
                  onChange={(value) => formik.setFieldValue('date', value)}
                  value={dayjs(formik.values.date)}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Subject</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                fullWidth
                id="subject"
                name="subject"
                variant="outlined"
                value={formik.values.subject}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography variant="h4">Note*</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                fullWidth
                id="note"
                name="note"
                variant="outlined"
                value={formik.values.note}
                onChange={formik.handleChange}
                error={formik.touched.note && Boolean(formik.errors.note)}
                helperText={formik.touched.note && formik.errors.note}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'center',
                md: 'end',
              },
              pr: 10,
            }}
          >
            <Button
              sx={{
                width: 140,
                height: 50,
                borderRadius: '30px',
                border: '2px solid red',
                mr: 2,
                mb: {
                  xs: 4,
                },
                color: 'grey',
              }}
              onClick={() => setShowNote(false)}
            >
              <Typography variant="h5" mr={1} color="red">
                Discard
              </Typography>
            </Button>

            <Button
              type="submit"
              sx={{
                width: 150,
                height: 50,
                borderRadius: '30px',
                color: 'white',
                bgcolor: 'primary.main',
                ':hover': {
                  color: 'white',
                  bgcolor: 'primary.main',
                },
              }}
            >
              {editData ? 'Update' : 'Save'}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddNote;
